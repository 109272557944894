import { axios } from "../../../../lib/axios";
import { AxiosResponse } from "../../../../shared/models";
import { API_ROUTES } from "../../../../utils/routes/apiRoutes";
import {
  ChnageStatusTimesheet,
  GetTimesheetResponse,
  TimesheetPayload,
} from "../interface";
import { GetTimesheetByIdResponse } from "../interface/getTimesheetById";

export const getTimesheet = async (
  workId: any,
  type: string
): Promise<GetTimesheetResponse> => {
  return axios.get(
    `${API_ROUTES.TIMESHEETS.GET_TIMESHEET}?workId=${workId}&type=${type}`
  );
};

export const getDetailTimesheet = async (
  type: string,
  workId: any
): Promise<GetTimesheetResponse> => {
  return axios.get(
    `${API_ROUTES.TIMESHEETS.GET_TIMESHEET_DETAIL}?type=${type}&workId=${workId}`
  );
};
export const getFilteredDetailTimesheet = async (
  type: string,
  workId: any,
  statusFilter: string
): Promise<{ data: any[] }> => {
  return axios.get(
    `${API_ROUTES.TIMESHEETS.GET_TIMESHEET_DETAIL}?type=${type}&workId=${workId}&statusFilter=${statusFilter}`
  );
};
export const changeTimesheetStatus = async (id: any, status: any,reason:any): Promise<ChnageStatusTimesheet> => {
  return axios.put(`${API_ROUTES.TIMESHEETS.CHANGE_STATUS}${id}`, { status, reason  });
};
export const changeTimesheetStatusRejected = async (id: any, status: any,reason:any): Promise<ChnageStatusTimesheet> => {
  return axios.put(`${API_ROUTES.TIMESHEETS.CHANGE_STATUS}${id}`, { status: status });
};

export const timesheetSendforApproval = (timesheetId: string,) => {
  return axios.put(`${API_ROUTES.TIMESHEETS.SEND_FOR_APPROVAL}/${timesheetId}`,)
}

export const addTimesheet = async (
  payload: TimesheetPayload
): Promise<TimesheetPayload> => {
  return axios.post(API_ROUTES.TIMESHEETS.ADD_TIMESHEET, payload);
};
export const updateTimesheet = async (
  id: any,
  payload: TimesheetPayload
): Promise<TimesheetPayload> => {
  return axios.put(`${API_ROUTES.TIMESHEETS.EDIT_TIMESHEET}/${id}`, payload);
};
export const getTimesheetById = async (
  id: any
): Promise<AxiosResponse<GetTimesheetByIdResponse>> => {
  return axios.get(`${API_ROUTES.TIMESHEETS.GET_TIMESHEET_BY_ID}/${id}`);
};
