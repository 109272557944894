import React, { useEffect, useState } from "react";
import Header from "../../../header/routes";
import exportt from "../../../../assets/images/exportt.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "../../../common/timesheets/styles/styles/style.module.css";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import moment from "moment";
import { getExpense, getExpenseDetail } from "../api";
import AddExpense from "../addExpense";
import { COUNT_TYPE } from "../../../../utils/enums/types";
import { WorkCountResponse } from "../../Contracts/interface";
import { getWorkDetailWithCount } from "../../Contracts/api";
import UpdateExpense from "../updateExpense";

const StatusExpenseTable = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const passedData = location.state;
  const [loader, setLoader] = useState<boolean>(true);
  const [workDetails, setWorkDetails] = useState<WorkCountResponse>();
  const [expenseData, setExpenseData] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [setExpemseId, setShowExpenseId] = useState<string>("");

  useEffect(() => {
    getWorkDetials();
  }, []);

  const getWorkDetials = async () => {
    const response: any = await getWorkDetailWithCount(id, COUNT_TYPE.REPORTS);
    setWorkDetails(response?.data);
  };

  //   useEffect(() => {
  //     const getTimesheetdata = async () => {
  //       const response = await getExpense(id, "current");
  //       setExpenseData(response?.data);
  //       setLoader(false);
  //     };
  //     getTimesheetdata();
  //   }, []);
  useEffect(() => {
    const getTimesheetdata = async () => {
      const response = await getExpenseDetail("expense", id);
      setExpenseData(response?.data);

      console.log(response.data, "the expense data")
      setLoader(false);
    };
    getTimesheetdata();
  }, []);

  const addExpense = () => {
    navigate(APP_ROUTES.PROFESSIONAL_ADD_EXPENSE, {

      state: {
        travelKeys: passedData?.travelKeys,
        expenseKeys: passedData?.expenseKeys,
        workId: passedData?.workId,
        title: "Add New Expense Details",
      },
    });
  };

  const handleEditExpense = (expenseId: string) => {
    navigate(`${APP_ROUTES.PROFESSIONAL_EDIT_EXPENSE}/${expenseId}`, {
      state: {
        travelKeys: passedData?.travelKeys,
        expenseKeys: passedData?.expenseKeys,
        workId: passedData?.workId,
        expenseId: expenseId,
        expenseData: expenseData,
        title: "Edit Expense Details",
      },
    });
  };

  return (
    <>
      <Header />
      {showModal && <AddExpense onClose={() => setShowModal(false)} workId={id} />}
      {showEditModal && <UpdateExpense onClose={() => setShowEditModal(false)} workId={id as string} expenseId={setExpemseId as string} />}

      <div className={styles.TimeShettTable}>
        <div className="container">
          <div className={styles.yimesheetBrd}>
            <div className="container">
              <div className={styles.TimeShettTableOuter}>
                <div className={styles.TimeShettTableHead}>
                  <h3>Expense Details</h3>
                  <div className="d-flex align-items-center">
                    <button className="border text-14 d-flex align-items-center rounded-3 font-inter fw-semibold">
                      <i className="me-2">
                        <img src={exportt} alt="" />
                      </i>
                      Export Data
                    </button>
                      {expenseData?.length===0 &&(
                        <button onClick={addExpense} className="border text-14 d-flex align-items-center rounded-3 font-inter fw-semibold">
                        Add New Expense
                      </button>
                      )}
                    
                  </div>
                </div>

                {loader ? (
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th>WEEK START DATE</th>
                        <th>WEEK END DATE</th>
                        <th>TOTAL</th>
                        <th>CREATED DATE</th>
                        <th>CREATED BY</th>
                        <th>APPROVED BY</th>
                        <th>APPROVED DATE</th>
                        <th>STATUS</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {expenseData?.length > 0 ? (
                        expenseData.map((data: any, idx: number) => (
                          <tr key={idx}>
                            <td>{moment(data.startDate).format("MMM DD, YYYY")}</td>
                            <td>{moment(data.endDate).format("MMM DD, YYYY")}</td>
                            <td>{data?.total}</td>
                            <td>{moment(data.createdAt).format("MMM DD, YYYY")}</td>
                            <td>{data?.user?.name}</td>
                            <td>{data?.approvedBy?.name}</td>
                            <td>{moment(data.approvedDate).format("MMM DD, YYYY")}</td>
                            <td>
                              {data?.status === "draft" ? <span>Not Filled</span> : <span className={styles[data.status]}>{data.status.charAt(0).toUpperCase() + data.status.slice(1)}</span>}
                            </td>
                            <td>
                              {[ "pending", "draft", "rejected"].includes(data?.status) || data?.isDue ? (
                                <span className="cursor-pointer" onClick={() => handleEditExpense(data?._id)}>Edit</span>
                              ) : (
                                <span className="cursor-pointer" onClick={() => handleEditExpense(data?._id)}>View Details</span>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={9} align="center" className="w-100 text-14">
                            No Expense Data Available!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusExpenseTable;
