import React, { FC, useEffect, useRef, useState } from "react";
import thdots from "../../../assets/images/thdots.png";
import speaker from "../../../assets/images/speaker.svg";
import topTip from "./styles/style.module.css";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import { ToltipProps } from "./interface";
import { getHelpTextByFilter } from "./api";

const Toltip: FC<ToltipProps> = ({ slug, labelId, tooltipClass }) => {
  const [toolTipText, setToolTipText] = useState<string>("");
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(true); // Track tooltip visibility
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchTooltipData = async () => {
      if (slug && labelId) {
        const response = await getHelpTextByFilter(slug, labelId);
        if (response.data) {
          setToolTipText(response.data.text);
        } else {
          console.error("Tooltip API response data is undefined.");
        }
      }
    };
    fetchTooltipData();
  }, [slug, labelId]);

  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
  };

  const playTextToSpeech = (text: string) => {
    if (text) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.rate = 1; // Adjust rate of speech (1 is normal)
      utterance.pitch = 1; // Adjust pitch of speech (1 is normal)
      window.speechSynthesis.speak(utterance);
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      setIsTooltipOpen(false); // Close tooltip if clicked outside
    }
  };

  useEffect(() => {
    // Attach event listener to handle clicks outside
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isTooltipOpen]);


  if (!browserSupportsSpeechRecognition) {
    return <p>Browser does not support speech recognition.</p>;
  }

  return (
    <div className={`cuatomeTop ${tooltipClass} ${topTip.cuatomeToptopoltip}`}>
      <div className={`MobOTP ${topTip.OuterToptip}`}>
        <div className={topTip.Toltip}>
          <div className={`row align-items-center g-0 ${topTip.topTiphead}`}>
            <div className="col-9">
              <h5 className="m-0 text-capitalize clamp clamp1">What Opportunity Do You Want To Post?</h5>
            </div>
            <div className="col-3 text-end">
              <button
                type="button" // Prevents form submission
                className="ml-auto"
                onClick={() => playTextToSpeech(toolTipText)}
              >
                <i><img src={speaker} alt="" width={30} height={30} /></i>
              </button>
            </div>
          </div>
          <div className={topTip.whtTol}>
            <p className="">{toolTipText}</p>
          </div>
          <div className={topTip.Listen}>
            {/* Additional Speech Recognition Controls */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toltip;
