import React, { useState, useEffect } from "react";
import { ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import upload from "../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import { uploadFile } from "../../../../common/api";
import { updateUserProfile } from "../../../home/api";
import { CertificateProps } from "../../interface";
import { toast } from "react-toastify";
import Certifications from "../../EditProfile/components/Certifications";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  refresh: () => void;
  docFile: any;
  editData: CertificateProps | null;
  index: number | null;
}

const EditCertificateModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  title,
  refresh,
  docFile,
  editData,
  index,
}) => {
  const [name, setName] = useState<string>("");
  const [file, setFile] = useState<any>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    if (editData) {
      setName(editData.name || "");
      setStartDate(editData.startDate || "");
      setEndDate(editData.endDate || "");
      setFile(editData.filename || "");
    } else {
      setName("");
      setStartDate("");
      setEndDate("");
      setFile("");
    }
  }, [editData]);
  const removeFile = () => {
    setFile(null);
    // toast.info("File removed!");
  };

  const validate = () => {
    let formErrors = { name: "", startDate: "", endDate: "" };
    let isValid = true;
  
    if (!name) {
      formErrors.name = "Certificate name is required";
      isValid = false;
    }
  
    if (!startDate) {
      formErrors.startDate = "Issue Date is required";
      isValid = false;
    }
  
    if (!endDate) {
      formErrors.endDate = "Expiration Date is required";
      isValid = false;
    }
  
    if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
      formErrors.endDate = "Expiration Date must be greater than Issue Date";
      isValid = false;
    }
  
    setErrors(formErrors);
    return isValid;
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((img) => {
        formData.append("file", img);
      });
      try {
        toast.info("File is uploading......")
        const response = await uploadFile(formData);
        const newFileUrl = response.data.files;
        setFile(newFileUrl);
        toast.success("File uploaded successfully!");
      } catch (err) {
        toast.error("Error uploading file");
      }
    }
  };

  const handleCertificateUpload = async () => {
    if (!validate()) return;
    
    setIsSubmitting(true);
    const newCertificate = {
      filename: file,
      name: name,
      startDate: startDate,
      endDate: endDate,
    };
  
    try {
      let updateCertificate: any = {};
      let successMessage = "";
      
      if (index !== null && index !== undefined && index >= 0) {
        let certifiacteData = docFile?.map((data: any, ind: any) => {
          if (index === ind) {
            return {
              filename: newCertificate.filename,
              name: newCertificate.name,
              startDate: newCertificate.startDate,
              endDate: newCertificate.endDate
            };
          } else {
            return {
              filename: data.filename,
              name: data.name,
              startDate: data.startDate,
              endDate: data.endDate
            };
          }
        });
        updateCertificate = {
          certifications: [...certifiacteData]
        };
        successMessage = "Updated successfully!";
      } else {
        let existingData: any = [];
        if (docFile?.length) {
          existingData = docFile?.map((data: any) => ({
            filename: data.filename,
            name: data.name,
            startDate: data.startDate,
            endDate: data.endDate
          }));
        }
        updateCertificate = {
          certifications: [...existingData, newCertificate]
        };
        successMessage = "Added successfully!";
      }
  
      await updateUserProfile(updateCertificate);
      refresh();
      setIsSubmitting(false);
      toast.success(successMessage);
    } catch (err) {
      console.log("Error while updating user information", err);
      toast.error("Error While Saving ");
      setIsSubmitting(false);
    }
  };
  

  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg" backdrop='static'>
    <Modal.Header closeButton>
      <Modal.Title>Edit Certifications</Modal.Title>
    </Modal.Header>
    <ModalBody>
      <div className="addModalPro">
        <form>
          <div className="row">
            <div className="col-12">
              <div className="formGroup">
                <label>Name</label>
                <div>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    placeholder="Add or choose Certificate"
                  />
                  {errors.name && <div className="text-danger">{errors.name}</div>}
                </div>
            
              </div>
            </div>
            <div className="col-lg-6 col-12">
  <div className="formGroup">
    <label>Issue Date</label>
    <div>
      <input
        type="Date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        className="form-control"
        placeholder="02/25/2024"
      />
      {errors.startDate && <div className="text-danger">{errors.startDate}</div>}
    </div>
  </div>
</div>

<div className="col-lg-6 col-12">
  <div className="formGroup">
    <label>Expiration Date</label>
    <div>
      <input
        type="Date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        className="form-control"
        placeholder="02/25/2024"
      />
      {errors.endDate && <div className="text-danger">{errors.endDate}</div>}
    </div>
  </div>
</div>


            <div className="col-12">
              <div className="formGroup">
                <label>Upload Image</label>
                <div
                  className={styles.uploadimagefile}
                >
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                    onChange={({ target }) =>
                      handleFileUpload(target.files)
                    }
                  />
                  <div className={styles.uploadimage}>
                    <i>
                      <img src={upload} alt="" />
                    </i>
                    <p className="mb-0 text-14 text-blue">
                      Upload jpeg/png file
                    </p>
                  </div>
                </div>
                {file && (
                        <li className="mt-3">
                          <div className={styles.uploadImages}>
                            <img src={file} alt="" className="img-fluid" />
                            <i
                              className="fas fa-times"
                              onClick={removeFile}>

                            </i>
                          </div>
                        </li>
                      )}
              </div>
            </div>

          </div>
        </form>
      </div>
    </ModalBody>
    <ModalFooter className="btn-hover-effect mb-2 justify-content-end">
      <Button
        type="button"
        variant="secondary"
        onClick={closeModal}
        className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto">
        <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
          Back
        </span>
        <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
          <img
            src={blackarrow}
            alt=""
            width={15}
            height={15}
            style={{ transform: "rotate(-180deg)" }}
          />
        </i>
      </Button>
      <Button
        type="button"
        variant="secondary"
        onClick={handleCertificateUpload}
        className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
      >
      <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            {isSubmitting ? (
              <Spinner animation="border" size="sm" className="me-2" />
            ) : (
              "Save"
            )}
          </span>
        <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
          <img src={whitearrow} alt="" width={15} height={15} />
        </i>
      </Button>
    </ModalFooter>
  </Modal>
  );
};

export default EditCertificateModal;
