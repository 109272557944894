import React, { useEffect, useState } from "react";
import styles from "../../../src/components/Feeds/styles/style.module.css";
import dummy from "../../assets/images/dummy.jpeg";
import tip from "../../../src/assets/images/info_icon.svg";
import top_skill from "../../assets/images/top_skill.svg";
import shareprofile from "../../assets/images/share_profile.svg";
import location from "../../assets/images/icon_location.svg";
import star from "../../assets/images/star.svg";
import flag from "../../assets/images/flag.svg";
import aeroplane from "../../assets/images/airoplane.svg";
import Toltip from "../common/toltip/page";
import Slider from "react-slick";
import storage from "../../utils/storage";
import { ProfReqData } from "../common/customerrequest/interface";
import { useNavigate } from "react-router-dom";
import { getMyProfessionalRequestPast } from "../common/customerrequest/api";


const MyProfessionals = () => {
  const userId = storage.getAuthUserId();
  if (!userId) {
    console.error("User ID is null. Cannot fetch professional requests.");
    return null; // Fix: Return null instead of undefined
  }
  const [profData, setProfData] = useState<ProfReqData[]>([]);
  const [requestedConnections, setRequestedConnections] = useState<Set<string>>(
    new Set()
  );
  const navigate = useNavigate();

  const _fetchProf = async () => {
    const res = await getMyProfessionalRequestPast(userId, "selected", "open");
    setProfData(res?.data)
  };

  useEffect(() => {
    _fetchProf();
  }, []);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3.4,
    centerPadding: "20px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
      <div className={`${styles.AllContracta}`}>
        <div className="row align-items-center justify-content-between mx-0 g-0">
          <div className="col-lg-5 col-6">
            <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="form-check-label text-20 fw-bold  font-inter mb-0">
                  My Professionals
                </h4>
                <i>
                  <img className="customeTolImg" src={tip} alt="" />
                </i>
                {profData?.length > 0 ? '' : <span className="badge rounded-3 bg-lightblue text-black text-16 fw-semibold px-3 py-2 ms-2">0</span>}
                <Toltip />
              </div>
            </div>
          </div>
        </div>
      </div>
      {profData?.length > 0 && profData ? (
        <>
          <div className="recommendedjobs">
            <div className="container px-0">
              <Slider {...settings}>
                {profData?.length > 0
                  ? profData?.map((data, ind) =>
                    data?.professionals?.map((prof, ind) => (
                      <>
                        <div className="col mt-3">
                          <div className="border rounded-3 mb-3 overflow-hidden">
                            <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue pt-3 mx-0">
                              <div className="row align-items-start justify-content-center gx-2">
                                <div className="col-3 profile_icon">
                                  <figure className="profileCircle">
                                    <img
                                      src={dummy}
                                      alt=""
                                      className="img-fluid rounded-circle border-2 border-blue"
                                    />
                                    <figcaption>
                                      <p className="text-8 mt-2 text-center rounded-pill pb-0 bg-lightblue text-blue">
                                        20 Connections
                                      </p>
                                    </figcaption>
                                  </figure>
                                </div>
                                <div className="col-9 ps-2">
                                  <div className="d-flex align-items-start justify-content-between">
                                    <div className="col-10">
                                      <div className="">
                                        <h4 className="mb-0 text-16 fw-semibold d-flex align-items-center">
                                          Justin C
                                          <span
                                            className={`text-9 text-white fw-normal ms-2 ${styles.boosted}`}
                                          >
                                            boosted
                                          </span>
                                        </h4>
                                        <p className="text-10 my-0 clamp clamp1 ">
                                          Robot Programmer
                                        </p>
                                        <p className="text-10 my-0 d-flex align-items-center">
                                          Reviews : 20
                                          <span className="px-2">|</span>
                                          4.5
                                          <i className="ms-2">
                                            <img src={star} alt="" />
                                          </i>
                                        </p>
                                        <p className="text-10 my-0  d-flex align-items-center">
                                          <i className="me-2">
                                            <img
                                              src={location}
                                              alt=""
                                              width={10}
                                              height={10}
                                            />
                                          </i>{" "}
                                          City, State, Street, Zip:264855
                                        </p>
                                        <p className="text-10 my-0 d-flex align-items-center">
                                          <i className="me-2">
                                            <img src={flag} alt="" width={10} height={10} />
                                          </i>{" "}
                                          Country
                                        </p>
                                        <p className="mb-0 bg-lightgreen text-blue rounded-pill text-center text-9 d-inline-block px-2 py-0">
                                          Available
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-2 text-end">
                                      <i className="bg-white rounded-circle w-auto px-1 py-2 mb-0 text-center d-flex align-items-center justify-content-center">
                                        <img src={shareprofile} alt="" />
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="row align-items-center gx-2 my-2 pt-2"
                                style={{ borderTop: "1px solid #3375D7 " }}
                              >
                                <div className="col-xl-12 col-12 px-0">
                                  <ul className="d-flex mb-0 align-items-center justify-content-center ps-0 gap=2">
                                    <li className="col-6">
                                      <p className="w-100 mb-0 bg-blue text-white rounded-pill text-center text-11 text-center px-2 py-1">
                                        Endorsements : 30
                                      </p>
                                    </li>
                                    <li className="col-6">
                                      <p className="w-100 mb-0 bg-lightgreen rounded-pill text-center text-11 d-inline-flex align-items-center px-2 py-1">
                                        <i className="me-1">
                                          <img src={aeroplane} alt="" />
                                        </i>
                                        Willing totravel : Yes
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="p-2">
                              <div className="row align-items-center">
                                <div className="col-5">
                                  <div
                                    className={`row align-items-start mb-3 g-0 ${styles.PenigSkilRate}`}
                                  >
                                    <div className="col-2 px-0 text-center">
                                      <i>
                                        <img
                                          src={top_skill}
                                          alt=""
                                          className="img-fluid"
                                          width="35px"
                                          height="35px"
                                        />
                                      </i>
                                    </div>
                                    <div className="col-10">
                                      <h5 className="mb-0 text-12 fw-semibold text-uppercase">
                                        Top Skill
                                      </h5>
                                      <p className="text-12 mb-0">Autocad</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div
                                    className={`row align-items-start mb-3 g-0 ${styles.PenigSkilRate}`}
                                  >
                                    <div className="col-2 px-0 text-center">
                                      <i>
                                        <img
                                          src={top_skill}
                                          alt=""
                                          className="img-fluid"
                                          width="35px"
                                          height="35px"
                                        />
                                      </i>
                                    </div>
                                    <div className="col-10">
                                      <h5 className="mb-0 text-12 fw-semibold text-uppercase">
                                        Second Skill
                                      </h5>
                                      <p className="text-12 mb-0">Programming</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={styles.PenigSkilRate}>
                                <h5>Hourly Rates</h5>
                                <ul>
                                  <li>
                                    <span>Base</span>
                                    <label>$12</label>
                                  </li>
                                  <li>
                                    <span>Overtime</span>
                                    <label>$7</label>
                                  </li>
                                  <li>
                                    <span>Sun/Holidays</span>
                                    <label>$25</label>
                                  </li>
                                </ul>
                              </div>

                              <div className="mt-3">
                                <div className="row align-items-center g-2">
                                  <div className="col">
                                    <button
                                      type="button"
                                      className="btn btn-primary w-100 text-10 px-1 text-center "
                                    >
                                      View Profile
                                    </button>
                                  </div>
                                  <div className="col">
                                    <button
                                      type="button"
                                      className="btn btn-white w-100 text-10 px-1 border text-center "
                                    >
                                      Connect
                                    </button>
                                  </div>
                                  <div className="col-12">
                                    <button
                                      type="button"
                                      className="btn btn-white w-100 text-10 px-1 border text-center "
                                    >
                                      Request This Professional
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  )
                  : ""}
              </Slider>
            </div>
          </div>
        </>
      ) : ''}
    </div>
  );
};

export default MyProfessionals;

