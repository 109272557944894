import React, { useEffect, useRef, useState } from "react";
import info from "../../../assets/images/info_icon.svg";
import styles from "../styles/style.module.css";
import logo from "../../../assets/images/logo.png";
import Header from "../../header/routes";
import { getSAAgrement } from "../api";
import { Aggrement } from "../interface";
import { useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { addImagesToPdf } from "../../../common/api";
const SSAAggrement = () => {
  const { id } = useParams();
  const [SSA, setSSA] = useState<any>();
  const [download,setDownloading]=useState<Boolean>(false)
  const contentRef=useRef<any>(null)

  const SSADataList = async () => {
    try {
      const response: any = await getSAAgrement(id);
      if (response && response.data) {
        setSSA(response.data);
        console.log(SSA?.times?.startDate, "SSA DATA");
        
      } else {
        console.error("No data found.");
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };
 

  useEffect(() => {
    SSADataList();
  }, []);

  return (
    <>
      <Header />
      <div className={styles.aggremtn}>
        <div className="container">
          <div className="row align-items-center justify-content-between w-100 mb-4 no-gutters">
            <div className="col-lg-6 col-12">
              <h3 className="fw-semibold text-24">
                Supplier Service Agreement <img src={info} alt="" />
              </h3>
            </div>

            <div className="col-lg-6 col-12">
              <div className={styles.greBtn}>
              <button className="cmn-tbn" onClick={()=>{addImagesToPdf([contentRef],setDownloading,"SSA")}}>
                  {download?"Downloading...":"Download"}
                </button>
                <button className="cmn-tbn" onClick={() => window.print()}>Print SSA</button>
              </div>
            </div>     
          </div>

          <div id="agreement-content" className={styles.agrementUper}>
            <div className={styles.AgreBrd}>
              <div className={styles.AgreBrdHead}>
                <img src={logo} alt="" />
              </div>
              <div ref={contentRef}>
              <div className={styles.AgreBrdname}>
                <h5 className="text-28">Supplier Service Aggrement</h5>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className={styles.leftAgremnt}>
                    <span className={styles.maindeHd}> Details</span>
                    <ul>
                      <li> 
                        <span>Contract Number: </span>{" "}
                        <label>{SSA?.work?.workNumber}</label>
                      </li>
                      <li>
                        <span>Account Manager: </span> <label>{"Jacob Sanchez"}</label>
                      </li>
                      <li>
                        <span>Account Manager Phone: </span>{" "}
                        <label>{"+1 (586) 884-1116"}</label>
                      </li>
                      <li>
                        <span>Account Manager Email: </span>{" "}
                        <label>{"jacobs@automateamerica.com"}</label>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.leftAgremnt}>
                    <span className={styles.maindeHd}> JobSite Locations</span>
                    <ul>
                      <li>
                        <span>State: </span> <label>{SSA?.work?.state}</label>
                      </li>
                      <li>
                        <span>City: </span> <label>{SSA?.work?.city}</label>
                      </li>
                      <li>
                        <span>Street Address: </span>{" "}
                        <label>{SSA?.work?.address}</label>
                      </li>
                      <li>
                        <span>Location Notes: </span><label>-</label>
                      </li>
                    </ul>
                  </div>

                  <div className={styles.leftAgremnt}>
                    <span className={styles.maindeHd}>
                      {" "}
                      Business Contact Info (OnSite){" "}
                    </span>
                    <ul>
                      <li>
                        <span>Business Name: </span> <label></label>
                      </li>
                      <li>
                        <span>Group Name: </span> <label></label>
                      </li>
                      <li>
                        <span>Contact Name: </span> <label></label>
                      </li>
                      <li>
                        <span>Contact Number: </span> <label></label>
                      </li>
                      <li>
                        <span>Contact Email: </span> <label></label>
                      </li>
                    </ul>
                  </div>

                  <div className={styles.leftAgremnt}>
                    <span className={styles.maindeHd}>
                      {" "}
                      Professional Contact Info
                    </span>
                    <ul>
                      <li>
                        <span>Professional Name: </span>{" "}
                        <label>{SSA?.professional?.name}</label>
                      </li>
                      <li>
                        <span>Professional Phone: </span>{" "}
                        <label>{SSA?.professional?.phoneNumber}</label>
                      </li>
                      <li>
                        <span>Professional Email: </span>{" "}
                        <label>{SSA?.professional?.email}</label>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.leftAgremnt}>
                    <span className={styles.maindeHd}>
                      {" "}
                      Service Partner Contact Info
                    </span>
                    <ul>
                      <li>
                        <span>Service Partner Company: </span> <label></label>
                      </li>
                      <li>
                        <span>Service Partner Customer: </span> <label></label>
                      </li>
                      <li>
                        <span>SP Customer Phone: </span> <label></label>
                      </li>
                      <li>
                        <span>SP Customer Email: </span> <label></label>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.leftAgremnt}>
                    <span className={styles.maindeHd}>
                      {" "}
                      Safety Equipment Required{" "}
                    </span>
                    <p>
                      Standard PPE ( Safety glasses, Shoes, ear protection ){" "}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className={styles.leftAgremnt}>
                    <span className={styles.maindeHd}> Application Date</span>
                    <ul>
                      <li>
                        <span>Application Start Date: </span>{" "}
                        <label>
                          {moment(SSA?.times?.startDate).format("MMM DD, YYYY")}
                        </label>
                      </li>
                      <li>
                        <span>Application End Date: </span>{" "}
                        <label>
                          {moment(SSA?.times?.endDate).format("MMM DD, YYYY")}
                        </label>
                      </li>
                      <li>
                        <span>Planned Days/week: </span>{" "}
                        <label>{SSA?.work?.times?.plannedDaysPerWeek}</label>
                      </li>
                      <li>
                        <span>Planned Hours/Day: </span>{" "}
                        <label>{SSA?.work?.times?.plannedHoursPerDay}</label>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.leftAgremnt}>
                    <span className={styles.maindeHd}>
                      {" "}
                      Bill Rates To Professional Or Service Partner
                    </span>
                    <ul>
                      <li>
                        <span>Payment Term: </span>{" "}
                        <label>{SSA?.hourlyPayment?.paymentTerm}</label>
                      </li>
                      <li>
                        <span>Base Rate: </span>{" "}
                        <label>${SSA?.work?.rates?.baseRate}</label>
                      </li>
                      <li>
                        <span>OT Rate: </span>{" "}
                        <label>${SSA?.work?.rates?.overTimeRate}</label>
                      </li>
                      <li>
                        <span>DT Rate: </span>{" "}
                        <label>${SSA?.work?.rates?.doubleTimeRate}</label>
                      </li>
                      <li>
                        <span>Travel Time Rate: </span> <label>{SSA?.hourlyPayment?.travelTimeRate}</label>
                      </li>
                      <li>
                        <span>Base Rate Hours: </span> <label>{SSA?.hourlyPayment?.baseRate}</label>
                      </li>
                      <li>
                        <span>OT Hours: </span> <label>{SSA?.hourlyPayment?.overTimeRate}</label>
                      </li>
                      <li>
                        <span>DT Hours: </span> <label>{SSA?.hourlyPayment?.doubleTimeRate}</label>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.leftAgremnt}>
                    <span className={styles.maindeHd}>
                      {" "}
                      Expenses Paid To Professional
                    </span>
                    <ul>
                      <li>
                        <span>Payment Terms: </span>{" "}
                        <label>{SSA?.expensePayment?.paymentTerm}</label>
                      </li>
                      <li>
                        <span>Expense Percentage: </span> <label> {SSA?.expensePayment?.automateAmericaInterest}</label>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.leftAgremnt}>
                    <span className={styles.maindeHd}>
                      {" "}
                      Max Expenses Paid By Customer{" "}
                    </span>
                    <ul>
                      <li><span>Daily Per Diem: </span> <label>{SSA?.work?.expense?.dailyPerDiem}</label></li>
                      <li><span>Travel Day Per Diem: </span> <label>{SSA?.work?.expense?.travelDayPerDiem}</label></li>
                      {/* <li><span>Travel Trips Allowed(per month): </span> <label>{MSA?.work?.expense?.travelDayPerDiem}</label></li>
                                            <li><span>Travel Hours Per Round Trip: </span> <label>{MSA?.work?.expense?.travelDayPerDiem}</label></li> */}
                      <li><span>Hotel Allowance / Day: </span> <label>{SSA?.work?.expense?.hotelAllowance}</label></li>
                      <li><span>Rental Car Allowance/Day: </span> <label>{SSA?.work?.expense?.rentalCarAllowance}</label></li>
                      <li><span>Fuel: </span> <label>{SSA?.work?.expense?.fuel}</label></li>
                      <li><span>Airfare Allowance: </span> <label>{SSA?.work?.expense?.airFareAllowance}</label></li>
                      <li><span>Mileage Rate/Mile: </span> <label>{SSA?.work?.expense?.mileage}</label></li>
                    </ul>
                  </div>
                  <div className={styles.leftAgremnt}>
                    <span className={styles.maindeHd}>
                      {" "}
                      Expenses paid by Automate America{" "}
                    </span>
                    <ul>
                      <li>
                        <span>Hotel: </span> <label></label>
                      </li>
                      <li>
                        <span>Airfare: </span> <label></label>
                      </li>
                      <li>
                        <span>Rental Car: </span> <label></label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </div>
            </div>
            {SSA?.htmlText ?
              <div dangerouslySetInnerHTML={{
                __html: SSA?.htmlText
              }} /> : null
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default SSAAggrement;
