import React, { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import tip from "../../../../assets/images/tip.png";
import penbl from "../../../../assets/images/penbl.png";
import selfrating from "../../../../assets/images/self_rating.svg";
import thumb from "../../../../assets/images/profile_picture.png";
import SecondSkillModal from "../modals/SecondSkillModal";
import { TechSkills } from "../../interface";

interface ModalProps {
  secondSkill: TechSkills[];
  refresh: () => void;
  // about:string 
}

const SecondSkill: React.FC<ModalProps> = ({ secondSkill = [], refresh }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<string | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);
  const [skillName, setSkillName] = useState("");
  const [about, setAbout] = useState<any>("");
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [img, setImg] = useState<any>("");
  const [rating,setRating]=useState<any>()

  const topSkillData = () => {
    secondSkill?.map((data, ind) => {
      if (data?.type === "second") {
        setSkillName(data?.occupationDetail?.name);
        setAbout(data?.description);
        setManufacturer(data?.manufacturerDetail?.name);
        setModel(data?.manufacturerModelDetail?.name);
        setImg(data?.image);
        setRating(data?.rating)
      }
    });
  };

  useEffect(() => {
    topSkillData();
  }, []);

  const openModal = (data?: string, index?: number) => {
    setEditData(data || null); // Set data to edit or null for new entry
    setIsModalOpen(true);
    console.log("index---", index);
    if (index !== null && index !== undefined && index >= 0) {
      console.log("index-if", index);
      setIndexNumber(index);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); // Clear data when closing modal
  };

  return (
    <div className={styles.AboutEdit}>
      <div className=" mt-3">
        <div className="row align-items-center">
          <div className="col-10">
            <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="text-20 fw-bold  font-inter mb-0">
                  Second Skill*
                </h4>
                <i>
                  <img className="customeTolImg" src={tip} alt="" />
                </i>
              </div>
            </div>
          </div>
          <div className="col-2 text-end">
            <i>
              <img
                src={penbl}
                className="cursor-pointer ms-auto"
                alt=""
                onClick={() => openModal()}
              />
            </i>
          </div>
        </div>
        <div className={styles.personalinfo} style={{ marginTop: "20px" }}>
          <span className="d-block text-14">Skill Name*</span>
          <label>{skillName}</label>
        </div>
        <div className="newheadingstyle mt-3">
          <h4 className="text-16 fw-semibold mb-2">About</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: about ? about : "",
            }}
          />
        <h5 className="text-14">
                Self Rating :{" "}
                 <p>{rating}</p>
              </h5>
          <ul className={styles.personalinfo} style={{ marginBottom: "0" }}>
            <li className="mb-3">
              <span className="d-block text-14">Manufacturer</span>
              <label>{manufacturer}</label>
            </li>
            <li className="mb-3">
              <span className="d-block text-14">Model/Version/Controller</span>
              <label>{model}</label>
            </li>
            <li className="">
              <span className="d-block text-14">Image</span>
              <img
                src={`${img}`}
                alt=""
                className="rouned-2"
                width={100}
                height={100}
              />
            </li>
          </ul>
        </div>
      </div>
      <SecondSkillModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Edit Second Skill"
        secondSkill={secondSkill}
        refresh={refresh}
        about={about}
      />
    </div>
  );
};

export default SecondSkill;
