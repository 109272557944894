import React, { useEffect, useState } from "react";
import styles from "../../css/styles/style.module.css";
import share from "../../../../assets/images/share.svg";
import cross from "../../../../assets/images/reject.svg";
import hire from "../../../../assets/images/hire.svg";
import rdinfo from "../../../../assets/images/rdinfo.png";
import loc from "../../../../assets/images/icon_location_filled.svg";
import profile from "../../../../assets/images/profile_picture.png";
import Slider from "react-slick";
import storage from "../../../../utils/storage";
import { getProfessionalRequest } from "../api";
import { ProfReqData } from "../interface";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { toast } from "react-toastify";
import { changeWorkStatus } from "../../Contracts/OpenContracts/api";
import { WORK_APPLY_TYPE } from "../../../../utils/enums/types";
import Confirm from "../../Contracts/OpenContracts/modals/Confirm";
import InterviewScheduleModal from "../../modals/ScheduleInterviewModal/InterviewScheduleModal";
import ViewInterViewQuestions from "../../Contracts/contractDetails/viewInterviewQuestions";

interface Props {
  setData: (e: boolean) => void;
}

const OpenedProf: React.FC<Props> = ({ setData }) => {
  const navigate = useNavigate();
  const userId = storage.getAuthUserId();
  const [profData, setProfData] = useState<ProfReqData[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [
    selectedContractForInterviewQuestion,
    setselectedContractForInterviewQuestion,
  ] = useState<ProfReqData | null>(null);
  const [modal1, setModal1] = useState<boolean>(false);
  const [modal2, setModal2] = useState<boolean>(false);
  const [interviewScheduled, setInterviewScheduled] = useState<boolean>();
  const [professionalId, setProfessionalId] = useState<string>("");
  const [professionalName, setProfessionalName] = useState<string>("");
  const [workId, setWorkId] = useState<string>("");
  const [occupation, setOccupation] = useState<string>("");
  const [loading, setLoading] = useState(true);

  const openModal1 = () => {
    setModal2(false);
    setTimeout(() => {
      setModal1(true);
    }, 0);
  };

  const openModal2 = () => {
    setModal1(false);
    setTimeout(() => {
      setModal2(true);
    }, 0);
  };

  const closeModal1 = () => {
    setModal1(false);
    setModal2(false);
  };

  const closeModal2 = () => {
    setModal2(false);
    setModal1(false);
  };

  const _fetchProf = async () => {
    const res = await getProfessionalRequest(userId, "applied");
    setProfData(res?.data);
    setData(res?.data?.length > 0 ? true : false);
  };

  useEffect(() => {
    _fetchProf().then(() => setLoading(false));
  }, []);

  const handleStatusChange = (id: string, status: string, workId: any) => {
    setSelectedId(id);
    setSelectedStatus(status);
    setWorkId(workId);
    setConfirmModal(true);
  };

  const handleClose = () => {
    setConfirmModal(false);
  };

  const changeStatus = () => {
    toast.promise(changeWorkStatus(selectedId, selectedStatus, workId), {
      pending: {
        render() {
          return "Changing Status";
        },
      },
      success: {
        render({}) {
          _fetchProf();
          handleClose();
          return "Status Changed Successfully";
        },
      },
      error: {
        render(error) {
          return "Status Change Failed";
        },
      },
    });
  };

  const handleCallback = (data: boolean) => {
    setInterviewScheduled(data);
    setModal1(false);
    closeModal1();
  };

  const handleShowInterviewQus = (
    value: any,
    profId: any,
    profName: any,
    workId: any,
    occu: any
  ) => {
    openModal1();
    setselectedContractForInterviewQuestion(value);
    setProfessionalId(profId);
    setProfessionalName(profName);
    setWorkId(workId);
    setOccupation(occu);
  };

  var Jobslide = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div
        className={`tab-pane fade show active`}
        id="nav-open"
        role="tabpanel"
        aria-labelledby="nav-open-tab"
      >
        <Confirm
          action="Select"
          handleClose={handleClose}
          showModal={confirmModal}
          id={selectedId}
          status={selectedStatus}
          workStatus={selectedId}
          changeStatus={changeStatus}
        />
        {profData?.length > 0 ? (
          <Slider {...Jobslide}>
            {profData?.length > 0
              ? profData?.map((data, ind) =>
                  data?.professionals?.map((item, ind) => (
                    <>
                      <div key={ind} className="col">
                        <div
                          className={`border bg-white rounded-3 ${styles.ContractTabs}`}
                        >
                          <div
                            className={`px-3 py-2 d-flex align-items-center justify-content-between flex-row ${styles.ContarctHead}`}
                          >
                            <div className="col-6">
                              <div className={styles.leftContract}>
                                <h5 className="fw-semibold text-14">
                                  ID: #{data?.workNumber}
                                </h5>
                              </div>
                            </div>
                            <div className="col-6 text-end">
                              <div
                                className={`d-flex justify-content-end ${styles.leftContract}`}
                              >
                                <button className="py-2 bg-blue text-white rounded-pill d-flex align-items-center border-blue text-12 fw-medium">
                                  <i className="me-lg-2 me-1">
                                    <img src={share} alt="" />
                                  </i>
                                  Share Profile
                                </button>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className={styles.outerNameDetail}>
                              <div className="row align-items-center pb-3 border-bottom">
                                <div className="col-md-12">
                                  <div className={styles.outerNDetail}>
                                    <div className="row align-items-center">
                                      <div className="col-12">
                                        <div className="mb-3">
                                          <h4 className="fw-semibold text-18">
                                            {" "}
                                            Professional Details
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 col-12 pe-0">
                                  <div className={styles.leftName}>
                                    <div className={styles.PrileImg}>
                                      <span className="text-center w-100">
                                        <i>
                                          <img
                                            src={
                                              item?.userData?.profilePicture ||
                                              profile
                                            }
                                            alt=""
                                            style={{
                                              width: "100px",
                                              minHeight: "60px",
                                            }}
                                            className=" img-fluid rounded-circle"
                                          />
                                        </i>
                                        <h4 className="mt-2 mb-0 fw-semibold px-1 py-2 text-10 text-capitalize bg-lightblue text-blue rounded-pill">
                                          view profile
                                        </h4>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 col-6">
                                  <div className={styles.leftName}>
                                    <div className={styles.PrileImg}>
                                      <div className="">
                                        <h5 className="clamp clamp1">
                                          {item?.userData?.name}
                                        </h5>
                                        <h6 className="text-14 clamp clamp1">
                                          {item?.primaryOccupation?.name}
                                        </h6>
                                        <p className="clamp clamp1">
                                          <i>
                                            <img src={loc} alt="" />
                                          </i>{" "}
                                          {item?.userData?.address || "N/A"}
                                        </p>
                                        <h6>Reviews: 20</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6">
                                  <div className={`ps-3 ${styles.rightName}`}>
                                    <p className="text-12">
                                      Start Date:{" "}
                                      {moment(data?.times?.startDate).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </p>
                                    <p className="text-12">
                                      Rate Per Hour : ${data?.rates?.baseRate}
                                      (net 15)
                                    </p>
                                    <p className="text-12">
                                      Expense Terms :{" "}
                                      {data?.hourlyWorkPayment?.paymentTerm}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <ul className="row align-items-center justify-content-between mx-3 g-2">
                                <li className="col-lg-3 col-3">
                                  <button
                                    className="rounded-2 p-2 text-black fw-semibold text-14 d-flex align-items-center w-100 justify-content-center"
                                    style={{ background: "#d6d6d6" }}
                                    onClick={() =>
                                      handleStatusChange(
                                        item?._id,
                                        WORK_APPLY_TYPE.REJECTED,
                                        data?.workId
                                      )
                                    }
                                  >
                                    <i className="me-lg-2 me-1">
                                      <img
                                        src={cross}
                                        alt=""
                                        width={15}
                                        height={15}
                                      />
                                    </i>
                                    Reject
                                  </button>
                                </li>
                                {/* <li className="col-lg-3 col-4">
                                  <button className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center">
                                    {" "}
                                    <i className="me-lg-2 me-1">
                                      <img
                                        src={hire}
                                        alt=""
                                        width={15}
                                        height={15}
                                      />
                                    </i>
                                    Shortlist
                                  </button>
                                </li> */}
                                <li className="col-lg-3 col-4">
                                  <button
                                    className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                    onClick={() =>
                                      handleStatusChange(
                                        item?._id,
                                        WORK_APPLY_TYPE.SELECTED,
                                        data?.workId
                                      )
                                    }
                                  >
                                    <i className="me-lg-2 me-1">
                                      <img
                                        src={hire}
                                        alt=""
                                        width={15}
                                        height={15}
                                      />
                                    </i>
                                    Hire{" "}
                                  </button>
                                </li>
                                <li className="col-lg-6 col-5">
                                  <button
                                    className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                    type="button"
                                    onClick={
                                      () =>
                                        handleShowInterviewQus(
                                          data,
                                          item?.userData?._id,
                                          item?.userData?.name,
                                          data?.workId,
                                          item?.appliedWork?.name,
                                        )
                                      // openModal(contract)
                                    }
                                  >
                                    {" "}
                                    Request for Interview
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div className={styles.Infor}>
                              <p className="text-14 mb-0 d-flex align-items-center">
                                <i className="me-lg-2 me-1">
                                  <img src={rdinfo} alt="" />
                                </i>{" "}
                                After selecting the applicant, You can schedule
                                an interview following the sending of a video
                                call request.
                              </p>
                            </div>
                            <div className={`mb-3 ${styles.Infor}`}>
                              <button
                                onClick={() =>
                                  navigate(
                                    `${APP_ROUTES.VIEW_APPLICATION}/${data?.workId}/${item?._id}`
                                  )
                                }
                                className="border-blue text-14 fw-semibold p-2 border-2 text-blue rounded-3 w-100"
                              >
                                View Application
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                )
              : ""}
          </Slider>
        ) : (
          ""
        )}
      </div>
      <InterviewScheduleModal
        isOpen={modal2}
        onClose={closeModal2}
        professional_id={professionalId}
        work_id={workId}
        occupation_name={occupation}
        professional_name={professionalName}
        interviewScheduled={handleCallback}
      />
      {!loading && workId && selectedContractForInterviewQuestion && (
        <ViewInterViewQuestions
          userId={professionalId}
          workId={workId}
          openModal2={openModal2}
          closeModal1={closeModal1}
          modal1={modal1}
        />
      )}
    </>
  );
};

export default OpenedProf;
