import React, { useState, useRef, useEffect } from "react";
import styles from "../styles/style.module.css";
import dnlod from "../../../../assets/images/dnlod.png";
import pnedit from "../../../../assets/images/pnedit.png";
import dlt from "../../../../assets/images/dlt.png";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpensePayload } from "../interface";
import { WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import { addexpense, addexpenseEdit, getexpenseById, sentForAppovalExpense } from "../api";
import { uploadFileImg } from "../../../Posts/api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
interface Expenses {
  dailyPerDiem: number[];
  travelDayPerDiem: number[];
  hotelAllowance: number[];
  rentalCarAllowance: number[];
  fuel: number[];
  airFareAllowance: number[];
  mileage: number[];
  luggage: number[];
}
interface Receipt {
  dailyReceipt?: String;
  travelReceipt?: String;
  hotelReceipt?: String;
  carReceipt?: String;
  fuelReceipt?: String;
  airFareReceipt?: String;
  mileageReceipt?: String;
  luggageReceipt?: String;
}
interface ReceiptData {
  [key: string]: File[];
}

const EditExpenseProf = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const passedData = location.state;
  console.log(passedData, "the passed data")
  const [expenseData, setExpenseData] = useState<any[]>([]);
  const isEditing = expenseData.length > 0;
  const [expenses, setExpenses] = useState<Expenses>({
    dailyPerDiem: Array(7).fill(""),
    travelDayPerDiem: Array(7).fill(""),
    hotelAllowance: Array(7).fill(""),
    rentalCarAllowance: Array(7).fill(""),
    fuel: Array(7).fill(""),
    airFareAllowance: Array(7).fill(""),
    mileage: Array(7).fill(""),
    luggage: Array(7).fill(''),
  });
  const [uploadedReceipts, setUploadedReceipts] = useState<ReceiptData>({});
  const { theExpenseID } = useParams()
  const [reason,setReason]=useState<any>('')
  console.log(theExpenseID, "the expense new ifd")

  useEffect(() => {
    if (passedData?.expenseId !== undefined) {
      getexpenseById(passedData?.expenseId).then((res) => {
        setReason(res.data)
        const expenseDetails = res?.data?.expenseDetails;
        if (expenseDetails) {
          // Transform the API response into the `expenses` state structure
          const transformedExpenses = {
            dailyPerDiem: expenseDetails.map((day: any) => day.dailyPerDiem || 0),
            travelDayPerDiem: expenseDetails.map((day: any) => day.travelDayPerDiem || 0),
            hotelAllowance: expenseDetails.map((day: any) => day.hotelAllowance || 0),
            rentalCarAllowance: expenseDetails.map((day: any) => day.rentalCarAllowance || 0),
            fuel: expenseDetails.map((day: any) => day.fuel || 0),
            airFareAllowance: expenseDetails.map((day: any) => day.airFareAllowance || 0),
            mileage: expenseDetails.map((day: any) => day.mileage || 0),
            luggage: expenseDetails.map((day: any) => day.luggage || 0),
          };
          console.log("Transformed Expenses:", transformedExpenses); // Debugging
          setExpenses(transformedExpenses);
          console.log(expenses, "the expenses")
        }
      });
    }
  }, [passedData?.expenseId]);


  const handleChange = (
    category: keyof Expenses,
    index: number,
    value: string
  ) => {
    // Allow an empty string (so the user can delete 0 and type a new number)
    let numericValue = value.replace(/^0+/, ""); // Remove leading zeros

    // If the input is empty, allow it (so user can type freely)
    numericValue = numericValue === "" ? "" : numericValue;

    setExpenses((prev) => {
      const updatedCategory = [...prev[category]];
      updatedCategory[index] = numericValue === "" ? 0 : Number(numericValue);
      return { ...prev, [category]: updatedCategory };
    });
  };


  const calculateDailyTotal = (dayIndex: number) => {
    // debugger
    return Object.keys(expenses).reduce((total, category) => {
      const categoryKey = category as keyof Expenses;
      total += expenses[categoryKey][dayIndex] || 0;
      return total;
    }, 0);
  };

  const calculateWeeklyTotal = () => {
    return Object.keys(expenses).reduce((total, category) => {
      total += expenses[category as keyof typeof expenses].reduce((sum, val) => sum + (val || 0), 0);
      return total;
    }, 0);
  };

  const handleReceiptUpload = async (
    category: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((img) => {
        formData.append("files", img);
      });

      try {
        const response = await uploadFileImg(formData);
        console.log("Response from AWS upload:", response);
        const uploadedFiles = response.data?.files || [];
        if (uploadedFiles.length > 0) {
          const receiptCategoryMap: { [key: string]: keyof Receipt } = {
            dailyPerDiem: "dailyReceipt",
            travelDayPerDiem: "travelReceipt",
            hotelAllowance: "hotelReceipt",
            mileage: "mileageReceipt",
            rentalCarAllowance: "carReceipt",
            fuel: "fuelReceipt",
            airFareAllowance: "airFareReceipt",
            luggage: "luggageReceipt",
          };
          const receiptKey = receiptCategoryMap[category];
          console.log("Receipt key:", receiptKey);
          if (!receiptKey) {
            console.error(`No receipt key found for category: ${category}`);
            return;
          }
          setUploadedReceipts((prev) => {
            return {
              ...prev,
              [receiptKey]: uploadedFiles[0],
            };
          });
        } else {
          console.error("No files uploaded or file URL is missing.");
        }
      } catch (err) {
        console.log("Error uploading files:", err);
      }
    }
  };

  const renderReceiptRequired = (category: string) => {
    const receiptMapping: Record<string, string> = {
      dailyPerDiem: "dailyReceipt",
      travelDayPerDiem: "travelReceipt",
      hotelAllowance: "hotelReceipt",
      rentalCarAllowance: "carReceipt",
      fuel: "fuelReceipt",
      airFareAllowance: "airFareReceipt",
      mileage: "mileageReceipt",
      luggage: "luggageReceipt",
    };
    const receiptKey = receiptMapping[category];
    const receiptRequired = passedData?.expenseKeys?.[receiptKey] === true;
    return (
      <td>{receiptRequired ? "Receipt Required" : "Receipt Not Required"}</td>
    );
  };

  const handleDeleteImage = (category: string, index: number) => {
    const updatedReceipts = [...(uploadedReceipts[category] || [])];
    updatedReceipts.splice(index, 1);
    setUploadedReceipts((prev) => ({
      ...prev,
      [category]: updatedReceipts,
    }));
  };

  const handleDownloadImage = (file: File | undefined) => {
    if (file) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    }
  };

  const handleSaveExpense = async () => {
    const expenseDetails = Array.from({ length: 7 }, (_, dayIndex) => {
      return {
        workedDate: new Date().toISOString(),
        dailyPerDiem: expenses.dailyPerDiem[dayIndex] ?? 0,
        travelDayPerDiem: expenses.travelDayPerDiem[dayIndex] ?? 0,
        hotelAllowance: expenses.hotelAllowance[dayIndex] ?? 0,
        mileage: expenses.mileage[dayIndex] ?? 0,
        rentalCarAllowance: expenses.rentalCarAllowance[dayIndex] ?? 0,
        fuel: expenses.fuel[dayIndex] ?? 0,
        airFareAllowance: expenses.airFareAllowance[dayIndex] ?? 0,
        luggage: expenses.luggage[dayIndex] ?? 0,
        dailyTotal: calculateDailyTotal(dayIndex),
        dailyCoveredTotal: calculateDailyTotal(dayIndex),
        dailyReceipt: uploadedReceipts.dailyReceipt || "",
        travelReceipt: uploadedReceipts.travelReceipt || "",
        hotelReceipt: uploadedReceipts.hotelReceipt || "",
        mileageReceipt: uploadedReceipts.mileageReceipt || "",
        carReceipt: uploadedReceipts.carReceipt || "",
        fuelReceipt: uploadedReceipts.fuelReceipt || "",
        airFareReceipt: uploadedReceipts.airFareReceipt || "",
        luggageReceipt: uploadedReceipts.luggageReceipt || "",
        dailyCustomerSup: passedData?.expenseKeys?.dailyCustomerSup,
        travelCustomerSup: passedData?.expenseKeys?.travelCustomerSup,
        hotelCustomerSup: passedData?.expenseKeys?.hotelCustomerSup,
        carCustomerSup: passedData?.expenseKeys?.carCustomerSup,
        fuelCustomerSup: passedData?.expenseKeys?.fuelCustomerSup,
        airFareCustSup: passedData?.expenseKeys?.airFareCustSup,
        mileageCustomerSup: passedData?.expenseKeys?.mileageCustomerSup,
        luggageCustomerSup: passedData?.expenseKeys?.luggageCustomerSup,
      };
    });

    const payload = {
      workId: passedData?.workId,
      total: calculateWeeklyTotal(),
      status: WORK_STATUS_TYPE.DRAFT,
      expenseDetails: expenseDetails,
    };

    try {
      const expenseId = passedData?.expenseId; // Get expense ID from `passedData`
      if (!expenseId) {
        toast.error("Expense ID is missing!");
        return;
      }

      const res = await addexpenseEdit(expenseId, payload);
      toast.success("Expense data saved successfully!");
    } catch (error: any) {
      toast.error(error.response?.data?.message || "An error occurred!");
    }
  };

  const handleSendExpense = async () => {
    const expenseDetails = Array.from({ length: 7 }, (_, dayIndex) => {
      return {
        workedDate: new Date().toISOString(),
        dailyPerDiem: expenses.dailyPerDiem[dayIndex] ?? 0,
        travelDayPerDiem: expenses.travelDayPerDiem[dayIndex] ?? 0,
        hotelAllowance: expenses.hotelAllowance[dayIndex] ?? 0,
        mileage: expenses.mileage[dayIndex] ?? 0,
        rentalCarAllowance: expenses.rentalCarAllowance[dayIndex] ?? 0,
        fuel: expenses.fuel[dayIndex] ?? 0,
        airFareAllowance: expenses.airFareAllowance[dayIndex] ?? 0,
        luggage: expenses.luggage[dayIndex] ?? 0,
        dailyTotal: calculateDailyTotal(dayIndex),
        dailyCoveredTotal: calculateDailyTotal(dayIndex),
        dailyReceipt: uploadedReceipts.dailyReceipt || "",
        travelReceipt: uploadedReceipts.travelReceipt || "",
        hotelReceipt: uploadedReceipts.hotelReceipt || "",
        mileageReceipt: uploadedReceipts.mileageReceipt || "",
        carReceipt: uploadedReceipts.carReceipt || "",
        fuelReceipt: uploadedReceipts.fuelReceipt || "",
        airFareReceipt: uploadedReceipts.airFareReceipt || "",
        luggageReceipt: uploadedReceipts.luggageReceipt || "",
        dailyCustomerSup: passedData?.expenseKeys?.dailyCustomerSup,
        travelCustomerSup: passedData?.expenseKeys?.travelCustomerSup,
        hotelCustomerSup: passedData?.expenseKeys?.hotelCustomerSup,
        carCustomerSup: passedData?.expenseKeys?.carCustomerSup,
        fuelCustomerSup: passedData?.expenseKeys?.fuelCustomerSup,
        airFareCustSup: passedData?.expenseKeys?.airFareCustSup,
        mileageCustomerSup: passedData?.expenseKeys?.mileageCustomerSup,
        luggageCustomerSup: passedData?.expenseKeys?.luggageCustomerSup,
      };
    });
  
    const payload = {
      workId: passedData?.workId,
      total: calculateWeeklyTotal(),
      status: WORK_STATUS_TYPE.PENDING, // Change status to PENDING
      expenseDetails: expenseDetails,
    };
  
    try {
      const expenseId = passedData?.expenseId; // Get expense ID from `passedData`
      if (!expenseId) {
        toast.error("Expense ID is missing!");
        return;
      }
  
      const res = await sentForAppovalExpense(expenseId, payload);
      toast.success("Expense sent for approval successfully!");
    } catch (error: any) {
      toast.error(error.response?.data?.message || "An error occurred!");
    }
  };




  console.log(passedData, "pass");
  console.log(uploadedReceipts, "receipts url");
  console.log(expenseData, "api expense");

  return (
    <div className={styles.pageOuter}>
      <div className="container">
        <h4 className="fw-bold text-26 mb-4">{passedData?.title}</h4>
        <div className={`rounded-4 ${styles.baseInfo} ${styles.noMarg}`}>
          <div className={`table-responsive ${styles.tableexpense}`}>
            <table className="table">
              <thead className="bg-light align-middle">
                <tr>
                  <th>Expense</th>
                  <th>Allowance......</th>
                  {[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ].map((day) => (
                    <th key={day}>{day}</th>
                  ))}
                  <th>Weekly Total</th>
                  <th>Receipt</th>
                </tr>
              </thead>
              <tbody>
                {(Object.keys(expenses) as Array<keyof Expenses>)
                  .filter((category) => passedData?.expenseKeys?.[category])
                  .map((category) => {
                    return (
                      <tr key={category}>
                        <td>{category.replace(/([A-Z])/g, " $1")}</td>
                        <td>
                          <input
                            type="text"
                            disabled
                            value={passedData?.expenseKeys?.[category] || ""}
                          />
                        </td>
                        {Array.isArray(expenses[category])
                          ? expenses[category].map((value, index) => (
                            <td key={index}>
                              <input
                                type="number"
                                value={value === 0 ? "" : value} // Show empty string if value is 0
                                placeholder="0" // Set placeholder to "0"
                                onChange={(e) => handleChange(category, index, e.target.value)}
                              />
                            </td>
                          ))
                          : <td>Error: Not an array</td>}
                        <td>
                          {Array.isArray(expenses[category])
                            ? expenses[category].reduce((sum, val) => sum + (val || 0), 0).toFixed(2)
                            : "0.00" // Default value if it's not an array
                          }
                        </td>
                        {renderReceiptRequired(category)}
                      </tr>
                    );
                  })}
              </tbody>

              <tfoot>
                <tr>
                  <td>
                    <strong>Daily Total</strong>
                  </td>
                  <td></td>
                  {Array(7)
                    .fill(0)
                    .map((_, dayIndex) => (
                      <td key={dayIndex}>
                        <strong>
                          ${calculateDailyTotal(dayIndex).toFixed(2)}
                        </strong>
                      </td>
                    ))}
                  <td>
                    <strong>${calculateWeeklyTotal().toFixed(2)}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          {/* --- Upload Receipt Table --- */}
          <div className="row align-items-center mt-4 justify-content-end pt-4">
            <div className="col-6">
              <h3 className="text-18 fw-bold mb-0">Upload Receipt</h3>
              <p className="text-12 fw-normal mb-0">
                *Converts the pdf into Image
              </p>
            </div>
            <div className="col-6 text-end">
              <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold float-end">
                <i className="me-2" style={{ transform: "rotate(180deg)" }}>
                  <img src={dnlod} alt="" className="img-fluid" />
                </i>{" "}
                Upload Receipt
              </button>
            </div>
          </div>

          <div
            className={`table-responsive ${styles.uploadtimetable} ${styles.tabletimesheet}`}
          >
            <table className="table rounded-3 overflow-hidden" cellSpacing={10}>
              <thead className="align-middle bg-primary">
                <tr>
                  <th className="ps-3">Expense</th>
                  <th className="ps-3">Receipt Required</th>
                  <th className="ps-3">Upload Receipt</th>
                  <th className="ps-3 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {(Object.keys(expenses) as Array<keyof Expenses>)
                  .filter((category) => passedData?.expenseKeys?.[category])
                  .map((category, index) => {
                    const receiptMapping: Record<string, string> = {
                      dailyPerDiem: "dailyReceipt",
                      travelDayPerDiem: "travelReceipt",
                      hotelAllowance: "hotelReceipt",
                      rentalCarAllowance: "carReceipt",
                      fuel: "fuelReceipt",
                      airFareAllowance: "airFareReceipt",
                      mileage: "mileageReceipt",
                      luggage: "luggageReceipt",
                    };

                    const receiptKey = receiptMapping[category];
                    const receiptRequired =
                      passedData?.expenseKeys?.[receiptKey] === true;

                    return (
                      <tr key={category}>
                        <td className="ps-3">
                          {category.replace(/([A-Z])/g, " $1")}
                        </td>
                        <td className="ps-3">
                          {receiptRequired ? "Yes" : "No"}
                        </td>
                        <td className="ps-3 d-flex">
                          {receiptRequired && (
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleReceiptUpload(category, e)}
                            />
                          )}
                        </td>
                        <td className="ps-3 text-center">
                          <ul className="d-inline-block mx-auto text-center w-100 mb-0">
                            <li className="d-inline-block me-2">
                              <i
                                className="d-block p-2 rounded-circle bg-blue"
                                onClick={() =>
                                  handleDownloadImage(
                                    uploadedReceipts[category]?.[index]
                                  )
                                } // Pass index to download handler
                              >
                                <img
                                  src={dnlod}
                                  alt="download"
                                  height="20px"
                                  width="20px"
                                  style={{ filter: "brightness(20.5)" }}
                                />
                              </i>
                            </li>
                            <li className="d-inline-block me-2">
                              <i
                                className="d-block p-2 rounded-circle bg-blue"
                                onClick={() =>
                                  handleDeleteImage(category, index)
                                } // Pass index to delete handler
                              >
                                <img
                                  src={dlt}
                                  alt="delete"
                                  height="20px"
                                  width="20px"
                                  style={{
                                    filter: "brightness(20.5) invert(1)",
                                  }}
                                />
                              </i>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="mt-3">
  <label htmlFor="reason">Reason</label>
  <textarea
  value={reason.reason}
    id="reason"
    className="form-control"
    rows={3} // Set initial rows
    style={{ resize: "vertical" }} // Allow vertical resizing
    placeholder="Enter your reason..."
  />
</div>

           <div className="d-flex gap-2 mt-3">
            <button className="btn btn-primary" onClick={handleSaveExpense}>Save</button>
           <button className="btn btn-primary" onClick={handleSendExpense} >
              Send for Approval
            </button>
           </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditExpenseProf;