  import React, { useEffect, useState } from "react";
  import { Link } from "react-router-dom";
  import styles from "../EditProfile/styles/style.module.css";
  import Header from "../../header/routes";
  import { userProfileResponse } from "../../home/interface";
  import { getuserProfile, updateUserProfile } from "../../home/api";
  import { useFormik } from "formik";
  import { toast } from "react-toastify";
  import "react-phone-number-input/style.css";
  import { uploadFile } from "../../../common/api";
  import { PROFILE } from "../../../utils/profile";
  import Industries from "../EditProfile/components/Industries";
  import EducationCerti from "../EditProfile/components/EducationCerti";
  import PersonalFiles from "../EditProfile/components/PersonalFiles";
  import SocialMedia from "../EditProfile/components/SocialMedia";
  import Occupation from "../EditProfile/components/Occupation";
  import Experience from "../EditProfile/components/ProfessionalExperience";
  import Skills from "../EditProfile/components/Skills";
  import ShortBio from "../EditProfile/components/ShortBio";
  import { UseDispatch, useDispatch } from "react-redux";
  import {
    EducationDetails,
    IndustriesDetails,
    SkillsDetails,
    OccupationDetails,
    PersonalDetails,
    ProfessInfo,
    TechSkills,
    ProfExperience,
    CompanyAdmin,
    AccountReceival,
    MarketingContacts,
    HRContactsData,
    interviewQuestionsData,
    CertificateProps,
    AddNewBusinessProps,
  } from "../interface";
  import EmergencyContact from "../EditProfile/components/EmergencyContact";
  import ProfessionalInfo from "../EditProfile/components/ProfessionalInfo";
  import TopSkill from "../EditProfile/components/TopSkill";
  import SecondSkill from "../EditProfile/components/SecondSkill";
  import OtherSkills from "../EditProfile/components/OtherSkills";
  import ProfessionalExperience from "../EditProfile/components/ProfessionalExperience";
  import Certifications from "../EditProfile/components/Certifications";
  import FinancialInfo from "../EditProfile/components/FinancialInfo";
  import BankingInfo from "../EditProfile/components/BankingInfo";
  import Resume from "../EditProfile/components/Resume";
  import BackgroundCheck from "../EditProfile/components/BackgroundCheck";
  import PhotoId from "../EditProfile/components/PhotoId";
  import FormsW9 from "../EditProfile/components/FormsW9";
  import Insurance from "../EditProfile/components/Insurance";
  import CompanyInfo from "../EditProfile/components/CompanyInfo";
  import BusinessClassification from "../EditProfile/components/BusinessClassification";
  import ProductOffered from "../EditProfile/components/ProductOffered";
  import ServiceOffered from "../EditProfile/components/ServiceOffered";
  import BusinessLocations from "../EditProfile/components/BusinessLocations";
  import BusinessGroup from "../EditProfile/components/BusinessGroup";
  import CompanyAdminContact from "../EditProfile/components/CompanyAdminContact";
  import AccReceivableCont from "../EditProfile/components/AccReceivableCont";
  import MarketingContact from "../EditProfile/components/MarketingContact";
  import HRContacts from "../EditProfile/components/HRContacts";
  import InterviewQuestion from "../EditProfile/components/InterviewQuestion";
  import PersonalInfoUser from "../EditProfile/components/PersonalInfo";
  import NewBusiness from "../EditProfile/components/NewBusiness";
  import { useLocation } from "react-router-dom";
  import storage from "../../../utils/storage";

  const Editprofile = () => {
    const [profileDetetails, setProfileDetails] = useState<userProfileResponse>();
    const [loading, setLoading] = useState<boolean>(true);
    const [education, setEducation] = useState<EducationDetails[]>([]);
    const [companyAdmin, setCompanyAdmin] = useState<CompanyAdmin[]>([]);
    const [AccountReceival, setAccountReceival] = useState<AccountReceival[]>([]);
    const [MarketingContactData, setMarketingContactData] = useState<
      MarketingContacts[]
    >([]);
    const [HrContactsData, setHrContactsData] = useState<HRContactsData[]>([]);
    const [certification, setCertification] = useState<CertificateProps[]>([]);
    const [interviewQues, setInterviewQues] = useState<interviewQuestionsData[]>(
      []
    );
    const [industries, setIndustries] = useState<IndustriesDetails[]>([]);
    const [skills, setSkills] = useState<SkillsDetails[]>([]);
    const [instaLink, setInstaLink] = useState("");
    const [fbLink, setFbLink] = useState("");
    const [linkedinLink, setLinkedinLink] = useState("");
    const [twiterLink, setTwiterLink] = useState("");
    const [occupation, setOccupation] = useState<OccupationDetails[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [personalInfo, setPersonalInfo] = useState<PersonalDetails>();
    const [secondaryOccupationDetails, setSecondaryOccupationDetails] = useState<
      OccupationDetails[]
    >([]);
    const [technicalSkills, setTechnicalSkills] = useState<TechSkills[]>([]);
    const [exp, setExp] = useState<ProfExperience[]>([]);
    const [addNewBusiness, setAddNewBusiness] = useState<AddNewBusinessProps>();
    const dispatch = useDispatch();
    const location = useLocation();

    const refresh = () => {
      setLoading(true);
      getuserProfile().then((res: any) => {
        console.log("Profile Data ==> ", res?.data);
        storage.setUserProfileImg(res?.data?.profilePicture);
        const nestedCertications = res?.data?.certifications;
        if (
          Array.isArray(nestedCertications) &&
          Array.isArray(nestedCertications[0])
        ) {
          const CompanyArray = nestedCertications[0];
          setCertification(CompanyArray);
        }
        const nestedAdministrationContacts =
          res?.data?.companyAdministrationContacts;
        if (
          Array.isArray(nestedAdministrationContacts) &&
          Array.isArray(nestedAdministrationContacts[0])
        ) {
          const CompanyArray = nestedAdministrationContacts[0];
          setCompanyAdmin(CompanyArray);
        }
        const nestedPayableContacts = res?.data?.payableContacts;
        if (
          Array.isArray(nestedPayableContacts) &&
          Array.isArray(nestedPayableContacts[0])
        ) {
          const CompanyArray = nestedPayableContacts[0];
          setAccountReceival(CompanyArray);
        }
        const nestedMarketingData = res?.data?.marketingContacts;
        if (
          Array.isArray(nestedMarketingData) &&
          Array.isArray(nestedMarketingData[0])
        ) {
          const CompanyArray = nestedMarketingData[0];
          setMarketingContactData(CompanyArray);
        }
        const nestedHrContactData = res?.data?.humanResourceContacts;
        if (
          Array.isArray(nestedHrContactData) &&
          Array.isArray(nestedHrContactData[0])
        ) {
          const CompanyArray = nestedHrContactData[0];
          setHrContactsData(CompanyArray);
        }

        const nestedInterviewQuestions = res?.data?.interviewQuestions;
        if (
          Array.isArray(nestedInterviewQuestions) &&
          Array.isArray(nestedInterviewQuestions[0])
        ) {
          const firstArray = nestedInterviewQuestions[0];
          setInterviewQues(firstArray);
        }
        setEducation(res?.data?.educations);
        setIndustries(res?.data?.industries);
        setSkills(res?.data?.skillsDetails);
        setInstaLink(res?.data?.instagramLink);
        setFbLink(res?.data?.facebookLink);
        setLinkedinLink(res?.data?.linkedinLink);
        setTwiterLink(res?.data?.twitterLink);
        setProfileDetails(res);
        setOccupation(res?.data?.occupationDetails);
        setSecondaryOccupationDetails(res?.data?.secondaryOccupation);
        setTechnicalSkills(res?.data?.technicalSkills);
        setExp(res?.data?.workExperiences);
        setLoading(false);
        const data: PersonalDetails = res.data;
        console.log(res.data, "t............................................ ")
        setPersonalInfo(data);
        setAddNewBusiness(res?.data?.businessDetail);
      });
    };

    useEffect(() => {
      refresh();
    }, []);
    console.log("tech", technicalSkills);
    const hideHeaderPaths = ["/newBusiness/businessGroup", "/home"];
    const showHeader = !hideHeaderPaths.includes(location.pathname);

    return (
      <>
        {" "}
        <div>
          {!loading ? (
            <div className={styles.RightditPro}>
              <h3 className="fw-semibold">Profile</h3>
              <PersonalInfoUser
                refresh={refresh}
                firstName={personalInfo?.firstName}
                lastName={personalInfo?.lastName}
                email={personalInfo?.email}
                phoneNumber={personalInfo?.phoneNumber}
                profilePicture={personalInfo?.profilePicture}
                _id={personalInfo?._id}
                countoryDetails={personalInfo?.countoryDetails}
                address={personalInfo?.address}
                workStatus={personalInfo?.isAvailable}
                languages={personalInfo?.languages}
                occupationDetails={personalInfo?.occupationDetails}
                isAvailable
              />
              <div className="px-3">
                <ShortBio bio={profileDetetails?.data?.bio} refresh={refresh} />
                <EmergencyContact
                  contact={profileDetetails?.data?.emergencyContact}
                  refresh={refresh}
                // name={profileDetetails?.data?.emergencyContact?.name}
                // contact={profileDetetails?.data?.emergencyContact?.phoneNumber}
                // rel={profileDetetails?.data?.emergencyContact?.relationship}
                />
                <ProfessionalInfo
                  primaryOccu={profileDetetails?.data?.occupationDetails}
                  secondaryOccu={profileDetetails?.data?.secondaryOccupation}
                  refresh={refresh}
                />
                <TopSkill topSkill={technicalSkills} refresh={refresh} />
                <SecondSkill secondSkill={technicalSkills} refresh={refresh} />
                {/* <OtherSkills otherSkill={technicalSkills} refresh={refresh} /> */}
                {/* <Skills skillDetails={skills} refresh={refresh} /> */}
                <ProfessionalExperience profData={exp} refresh={refresh} />
                <EducationCerti education={education} refresh={refresh} />
                <Certifications refresh={refresh} docFile={certification} />
                <FinancialInfo
                  rates={profileDetetails?.data?.rates}
                  refresh={refresh}
                  editData={undefined}
                />
                <BankingInfo
                  refresh={refresh}
                  bankDetails={profileDetetails?.data?.bankDetails}
                />
                <Resume
                  refresh={refresh}
                  docFile={profileDetetails?.data?.resumeDoc}
                />
                <BackgroundCheck
                  refresh={refresh}
                  docFile={profileDetetails?.data?.backgroundCheckDoc}
                />
                <PhotoId
                  refresh={refresh}
                  docFile={profileDetetails?.data?.photoIdDoc}
                />
                {/* <FormsW9 /> */}
              <Insurance refresh={refresh} doc={profileDetetails?.data?.insuranceDoc} />
              {/* <ProfessionalCompletency /> */}
              {/* <Industries
            industryDetails={industries}
            refresh={refresh}
          />
          <Occupation occupationData={occupation} /> */}
              {/* <EthicsValue />
          <CommitComunity /> */}
              {/* <PersonalFiles /> */}
              <SocialMedia
                instaLink={instaLink}
                fbLink={fbLink}
                linkedinLink={linkedinLink}
                twiterLink={twiterLink}
                refresh={refresh}
              />
              <CompanyInfo
                refresh={refresh}
                companyContact={
                  profileDetetails?.data?.companyContact
                    ? {
                      name: String(profileDetetails.data.companyContact.name),
                      city: String(profileDetetails.data.companyContact.city),
                      phoneNumber: String(profileDetetails.data.companyContact.phoneNumber),
                      email: String(profileDetetails.data.companyContact.email),
                    }
                    : undefined
                }
              />
              {/* <BusinessClassification /> */}
              <ProductOffered />
              <ServiceOffered />
              {profileDetetails && (
                <NewBusiness
                  business={addNewBusiness}
                  ProfileDetails={profileDetetails}
                />
              )}
              <BusinessGroup />
              {/* <BusinessLocations /> */}
              <CompanyAdminContact
                companyAdmin={companyAdmin}
                refresh={refresh}
              />
              <AccReceivableCont
                refresh={refresh}
                AccountReceival={AccountReceival}
              />

              <MarketingContact
                refresh={refresh}
                MarketingContacts={MarketingContactData}
              />

              <HRContacts refresh={refresh} HrData={HrContactsData} />

              <InterviewQuestion
                refresh={refresh}
                interviewQues={interviewQues}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="px-3">
              <div className={`text-center ${styles.spinner}`}>
                <div className="spinner-border text-blue" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Editprofile;
