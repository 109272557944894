import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, Modal, ModalFooter, Spinner } from "react-bootstrap";
import penbl from "../../../../assets/images/penbl.png";
import upload from "../../../../assets/images/upload.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import thumb from "../../../../assets/images/profile_picture.png";
import styles from "../styles/style.module.css";
import { IOption } from "../../../../Interfaces";
import { updateUserProfile } from "../../../home/api";
import { getMasterTypes } from "../../../../feature/auth/signup/api";
import { MASTER_TYPE, SKILL_TYPE } from "../../../../utils/enums/masterTypes.enum";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TechSkills } from "../../interface";
import { uploadFileImg } from "../../../Posts/api";
import { getManufacturerModelList } from "../../../work/api";
import { toast } from "react-toastify";
import TextEditorCustomComp from "../../../contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/TextEditorCustomComp/TextEditorCustomComp";
import { UpdateProfileParams } from "../../../home/interface";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  refresh: () => void;
  secondSkill: TechSkills[];
  about: string
}

const SecondSkillModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  refresh,
  secondSkill,
  about
}) => {
  if (!isOpen) return null;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [occupation, setOccupation] = useState("");
  const [occupationId, setOccupationId] = useState("");
  const [occuData, setOccuData] = useState<IOption[]>([]);
  const [newAbout, setNewAbout] = useState<any>("");
  const [manufacturer, setManufacturer] = useState("");
  const [manufacturerId, setManufacturerId] = useState("");
  const [manufacturerData, setManufacturerData] = useState<IOption[]>([]);
  const [model, setModel] = useState("");
  const [modelId, setModelId] = useState("");
  const [modelData, setModelData] = useState<IOption[]>([]);
  const [img, setImg] = useState<any>("");
  const [type, setType] = useState<string>("");
  const [index, setIndex] = useState<number>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rating, setRating] = useState<string>("");
  const [ratingError, setRatingError] = useState<string>("");

  // Validation states
  const [errors, setErrors] = useState({
    occupation: "",
    newAbout: "",
    manufacturer: "",
    model: "",
  });

  const editorConfiguration = {
    toolbar: ["undo", "redo", "|", "bold", "italic"],
    height: "150px",
  };

  useEffect(() => {
    secondSkill?.map((data, ind) => {
      if (data?.type === "second") {
        setOccupation(data?.occupationDetail?.name || "");
        setOccupationId(data?.occupationDetail?._id || "");
        setNewAbout(data?.description || "");
        setManufacturer(data?.manufacturerDetail?.name || "");
        setManufacturerId(data?.manufacturerDetail?._id || "");
        setModel(data?.manufacturerModelDetail?.name || "");
        setModelId(data?.manufacturerModelDetail?._id || "");
        setImg(data?.image || "");
        setType(data?.type || "");
        setIndex(ind);
      }
    });
  }, [secondSkill]);

  const loadDropDowns = async () => {
    try {
      const occupationDataRes = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
      const manufacturerRes = await getMasterTypes(MASTER_TYPE.MANUFACTURERS);

      if (occupationDataRes?.data || manufacturerRes?.data) {
        setOccuData(
          occupationDataRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
        setManufacturerData(
          manufacturerRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to load data", error);
    }
  };

  useEffect(() => {
    loadDropDowns();
  }, []);

  const handleOccupationChange = (label: string, value: string) => {
    setOccupation(label);
    setOccupationId(value);
    setErrors((prev) => ({ ...prev, occupation: "" }));
  };

  const handleManufacturerChange = async (label: string, value: string) => {
    setManufacturer(label);
    setManufacturerId(value);
    const modelRes = await getManufacturerModelList(
      MASTER_TYPE.MANUFACTURERS_MODELS,
      value
    );
    if (modelRes?.data) {
      setModelData(
        modelRes?.data.map((x: any) => ({
          label: x.name,
          value: x._id,
        }))
      );
    }
    setErrors((prev) => ({ ...prev, manufacturer: "" }));
  };

  const handleModelChange = (label: string, value: string) => {
    setModel(label);
    setModelId(value);
    setErrors((prev) => ({ ...prev, model: "" }));
  };

  const handleSubmitCustomOccupation = async () => {
    // Implement the custom occupation logic here
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((img) => {
        formData.append("files", img);
      });
      try {
        const response = await uploadFileImg(formData);
        const newImageUrl = response.data.files[0];
        setImg(newImageUrl);
      } catch (err) { }
    }
  };

  const removeUploadedFiles = (): void => {
    setImg("");
  };

  // Validation before submission
  const validateForm = () => {
    const newErrors = {
      occupation: occupation ? "" : "Occupation is required",
      newAbout: newAbout ? "" : "About is required",
      manufacturer: manufacturer ? "" : "Manufacturer is required",
      model: model ? "" : "Model is required",
    };
    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const handleUpdateSkill = async () => {
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
    setRatingError(""); // Reset error

    // Convert rating to a number
    const ratingNum = Number(rating);
  
    // Validate rating
    if (!rating || isNaN(ratingNum) || ratingNum < 1 || ratingNum > 5) {
      setRatingError("Please enter a valid rating between 1 and 5.");
      return;
    }
    setIsSubmitting(true)
    const payload: UpdateProfileParams = {
      technicalSkills: [
        {
          description: newAbout,
          type: SKILL_TYPE.SECOND,
          image: img,
          manufacturerId: manufacturerId,
          manufacturerModelId: modelId,
          occupationId: occupationId,
          rating
        },
      ],
    };

    try {
      // let existingData: any = [];
      // if (secondSkill?.length && type === "second") {
      //   existingData = secondSkill?.map((data, ind) => {
      //     if (ind === index) {
      //       return payload;
      //     } else {
      //       return data;
      //     }
      //   });
      // } else {
      //   existingData = [...(secondSkill || []), payload];
      // }

      // const updatedSkills = {
      //   technicalSkills: existingData,
      // };
      const response = await updateUserProfile(payload);
      refresh();
      setIsSubmitting(false)
      toast.success(response?.message || "Updated Successfully");
      onClose();
    } catch (error: any) {
      toast.error(error?.data?.message || "An error occurred");
    }
  };

  const occuOptions = occuData.filter((x) =>
    x.label.toLowerCase().includes(filterValue.toLowerCase())
  );

  const manufacturerOptions = manufacturerData.filter((x) =>
    x.label.toLowerCase().includes(filterValue.toLowerCase())
  );

  const modelOptions = modelData.filter((x) =>
    x.label.toLowerCase().includes(filterValue.toLowerCase())
  );

  return (
    <>
      <Modal show={isOpen} onHide={onClose} centered size="lg" backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="addModalPro">
              <div className="row">
                <div className="col-12">
                  <div className="formGroup">
                    <label>Occupation</label>
                    <div style={{ position: "relative" }}>
                      <Dropdown style={{ width: "100%" }}>
                        <Dropdown.Toggle
                          variant="primary"
                          id="dropdown-basic"
                          style={{ width: "100%" }}
                        >
                          {occupation || "Please select an option"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            position: "absolute",
                            zIndex: 1050,
                            width: "100%",
                            maxHeight: "250px",
                            overflowY: "auto",
                            background: "white",
                            border: "1px solid #ccc",
                          }}
                        >
                          <div>
                            <FormControl
                              autoFocus
                              style={{ margin: "8px 12px", width: "auto" }}
                              placeholder="Search..."
                              onChange={(e) => setFilterValue(e.target.value)}
                              value={filterValue}
                            />
                          </div>
                          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                            {occuOptions.length === 0 ? (
                              <Dropdown.Item disabled>No options found</Dropdown.Item>
                            ) : (
                              occuOptions.map((option, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleOccupationChange(option.label, option.value)}
                                >
                                  {option.label}
                                </Dropdown.Item>
                              ))
                            )}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    {errors.occupation && (
                      <div className="error text-danger">{errors.occupation}</div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="formGroup">
                    <label>About</label>
                    <TextEditorCustomComp data={about}
                      setDesc={setNewAbout}
                      setIndex={setIndex}
                      index={index} />
                    {/* <CKEditor
                      editor={ClassicEditor}
                      data={newAbout}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        setNewAbout(data);
                        setErrors((prev) => ({ ...prev, newAbout: "" }));
                      }}
                      config={editorConfiguration}
                    /> */}
                    {errors.newAbout && (
                      <div className="error text-danger">{errors.newAbout}</div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="formGroup">
                    <label>Manufacturer</label>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          {manufacturer || "Please select an option"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div>
                            <FormControl
                              autoFocus
                              className="mx-3 my-2 w-auto"
                              placeholder="Search..."
                              onChange={(e) => setFilterValue(e.target.value)}
                              value={filterValue}
                            />
                          </div>
                          <div style={{ maxHeight: "300px" }}>
                            {manufacturerOptions.length === 0 ? (
                              <Dropdown.Item disabled>
                                No options found
                              </Dropdown.Item>
                            ) : (
                              manufacturerOptions.map((option, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() =>
                                    handleManufacturerChange(
                                      option.label,
                                      option.value
                                    )
                                  }
                                >
                                  {option.label}
                                </Dropdown.Item>
                              ))
                            )}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {errors.manufacturer && (
                      <div className="error text-danger">{errors.manufacturer}</div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="formGroup">
                    <label>Model / Version / Controller</label>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          {model || "Please select an option"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div>
                            <FormControl
                              autoFocus
                              className="mx-3 my-2 w-auto"
                              placeholder="Search..."
                              onChange={(e) => setFilterValue(e.target.value)}
                              value={filterValue}
                            />
                          </div>
                          <div style={{ maxHeight: "300px" }}>
                            {modelOptions.length === 0 ? (
                              <Dropdown.Item disabled>
                                No options found
                              </Dropdown.Item>
                            ) : (
                              modelOptions.map((option, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() =>
                                    handleModelChange(option.label, option.value)
                                  }
                                >
                                  {option.label}
                                </Dropdown.Item>
                              ))
                            )}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {errors.model && <div className="error text-danger const [isSubmitting, setIsSubmitting] = useState(false);">{errors.model}</div>}
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="formGroup">
                    <label>Self Rating (out of 5)</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Rating"
                      value={rating}
                      onChange={(e) => setRating(e.target.value)}
                      min="1"
                      max="5"
                    />
                    {ratingError && <span className="text-danger">{ratingError}</span>}
                  </div>
                </div>
                <div className="col-12">
                  <div className="formGroup">
                    <label>Upload Image</label>
                    <div
                      className={styles.uploadimagefile}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                        onChange={({ target }) =>
                          handleFileUpload(target.files)
                        }
                      />
                      <div className={styles.uploadimage}>
                        <i>
                          <img src={upload} alt="" />
                        </i>
                        <p className="mb-0 text-14 text-blue">
                          Upload jpeg/png file
                        </p>
                      </div>
                    </div>
                    {img && (
                      <li className="mt-3">
                        <div className={styles.uploadImages}>
                          <img src={img} alt="" className="img-fluid" />
                          <i
                            className="fas fa-times"
                            onClick={removeUploadedFiles} // Call the function to remove the image
                          ></i>
                        </div>
                      </li>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <ModalFooter className="btn-hover-effect mb-2 justify-content-end">
            <div className="btn-hover-effect">
              <h6 onClick={onClose} className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
              >
                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                  Back
                </span>
                <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                  <img src={blackarrow} alt="" width={15} height={15} style={{ transform: "rotate(-180deg)" }} />
                </i>
              </h6>
            </div>
            <div className="btn-hover-effect">
              <h6 onClick={handleUpdateSkill} className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto">
                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                  {isSubmitting ? (
                    <Spinner animation="border" size="sm" className="me-2" />
                  ) : (
                    "Save"
                  )}
                </span>
                <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                  <img src={whitearrow} alt="" width={15} height={15} />
                </i>
              </h6>
            </div>

          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default SecondSkillModal;
