import React, { useEffect, useState } from "react";
import styles from "../../signup/styles/sigup.module.css";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import left from "../../../../assets/images/left.png";
import emchat from "../../../../assets/images/emchat.png";
import mschat from "../../../../assets/images/mschat.png";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setOtp } from "../../../../redux/reducers/auth";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import { verifyOTPSchema } from "../../signup/validations";
import { RootState } from "../../../../redux/store";
import { verifyOTPResponse } from "../../signup/interface";
import { APIResponseError } from "../interface";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../../utils/messages";
import { forgotPassword, verifyOTP } from "../api";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";

const OtpVerification: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTimerActive, setIsTimerActive] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(30);
  const userDetail = useSelector((state: RootState) => state.userData);

  console.log(userDetail.verificationToken, "token");

  const verifyPhoneFormik = useFormik({
    initialValues: {
      verificationCode: "", // Initially empty for OTP input
    },
    validationSchema: verifyOTPSchema,
    onSubmit: async (values) => {
      if (userDetail?.verificationToken.verificationToken !== undefined) {
        const payload = {
          userName: userDetail.verificationToken.userName,
          verificationCode: values.verificationCode.toString(),
          type: "forgotpassword",
          verificationToken: userDetail.verificationToken.verificationToken,
        };
        try {
          const response = await verifyOTP(payload);
          const otpResponse = response as verifyOTPResponse;
          verifyPhoneFormik.resetForm();
          navigate(AUTH_ROUTES.RECOVER_PASSWORD);
          toast.success(MESSAGES.SENDOTP.SUCCESS);
        } catch (error) {
          const errorMesage = error as APIResponseError;
          toast.error(errorMesage?.data?.message);
        }
      } else {
        toast("Email is not registered");
      }
    },
  });
  const handleResendotp = () => {
    const payload = {
      userName: userDetail.verificationToken.userName,
    };
    toast.promise(forgotPassword(payload), {
      pending: {
        render() {
          return MESSAGES.LOGIN.VERIFY;
        },
      },
      success: {
        render() {
          setIsTimerActive(true);
          setTimer(30);
          // navigate(AUTH_ROUTES.RECOVER_PASSWORD);
          return MESSAGES.SENDOTP.SUCCESS;
        },
      },
      error: {
        render(error) {
          let errorMessage = error as APIResponseError;
          toast.error(errorMessage.data.message);
          return MESSAGES.LOGIN.INVALID;
        },
      },
    });
  };

  useEffect(() => {
    if (isTimerActive) {
      if (timer > 0) {
        const interval = setInterval(() => {
          setTimer((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(interval);
      } else {
        setIsTimerActive(false);
      }
    }
  }, [isTimerActive, timer]);
  return (
    <>
      <HeaderContent />
      <div className="bgbluegradient">
        <div className={styles.signuppage}>
          {/* <div className={styles.signupHead}>
				<div className={styles.leftSign}>
					<img src={logo} alt="" />
				</div>
				<div className={styles.rightSign}>
					<p className="mb-0">Already have an account? <a href="/">Sign in</a></p>
					<button>Need Help?</button>
				</div>
			</div> */}
          <div className={styles.signUPContainer}>
            <form onSubmit={verifyPhoneFormik.handleSubmit}>
              <h5>OTP Verification</h5>
              <span className={styles.Leftntm}>
                <a href="#">
                  <img src={left} alt="" onClick={() => navigate(-1)} />
                </a>
              </span>
              <p> An OTP will be sent to entered number for verification</p>
              <div className={styles.fromGroup}>
                <label>Enter OTP</label>
                <div className={styles.otpFields}>
                  <OtpInput inputType="number"
                    value={verifyPhoneFormik.values.verificationCode}
                    onChange={(otp) =>
                      verifyPhoneFormik.setFieldValue("verificationCode", otp)
                    }
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                  />
                  {verifyPhoneFormik.touched.verificationCode &&
                    verifyPhoneFormik.errors.verificationCode && (
                      <div className={styles.error}>
                        {verifyPhoneFormik.errors.verificationCode}
                      </div>
                    )}
                </div>
                <h6 className="flex-column">
                  Didn’t get the OTP?{" "}
                  <h5>
                    {isTimerActive ? (
                      <p className="mb-2">
                        Resend available in {timer} seconds
                      </p>
                    ) : (
                      <p
                        onClick={handleResendotp}
                        className="mb-2 text-blue d-block"
                        style={{ cursor: "pointer" }}
                      >
                        Resend OTP
                      </p>
                    )}
                    {/* <a href="#">
                    <img src={emchat} alt="Email Chat" />
                  </a>{" "}
                  <a href="#">
                    <img src={mschat} alt="Message Chat" />
                  </a> */}
                  </h5>
                </h6>
              </div>
              {/* <div className={styles.fromGroup}>
						<input type='submit' value="Next ›" />
					</div> */}
              <div className="col-lg-3 col-6 mx-auto">
                <div className="text-center my-3 btn-hover-effect border rounded-3">
                  {/* <input type="submit"/> */}
                  <button
                    type="submit"
                    className="btn arrowblue border-grey border-1 rounded-3 position-relative w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">
                      Next
                    </span>
                    <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default OtpVerification;
