import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from "react-bootstrap";
import "./calendar.module.style.css";
import moment from "moment";
import { addEvents, getEventLink, getVideoLink } from "./api";
import { toast } from "react-toastify";
import { MESSAGES } from "../../utils/messages";
import DatePicker from "react-datepicker";
import storage from "../../utils/storage";
import { EVENT_TYPE } from "../../utils/enums/types"; 
import AddUser from "./AddUser";
interface ModalProps {
  modal1: boolean;
  modal2: boolean;
  openModal2: () => void;
  closeModal1: () => void;
  closeModal2: () => void;
  addEvent: (event: { title: string; start: Date }) => void;
}
const AddEvent: React.FC<ModalProps> = ({
  openModal2,
  closeModal1,
  closeModal2,
  modal1,
  modal2,
  addEvent,
}) => {
  const userId = storage.getAuthUserId() || "";
  const [title, setTitle] = useState("");
  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState("");
  const [desc, setDesc] = useState("");
  const [formattedDateTime, setFormattedDateTime] = useState<string>("");
  const [videoLink, setVideoLink] = useState<string>('');
  const [eventId, setEventId] = useState<string>('');
  const [eventLink, setEventLink] = useState<string>('');

  const handleDateChange = (selectedDate: Date | null) => {
    setDate(selectedDate);
  };

  const handleAddEvents = async () => {
    if (title && date && time) {
      const combinedDateTime = moment(date)
        .set({
          hour: moment(time, "HH:mm").hour(),
          minute: moment(time, "HH:mm").minute(),
          second: 0,
          millisecond: 0,
        })
        .toDate();

      const formattedDate =
        moment(combinedDateTime).format("D MMM YYYY, h:mm a");
      setFormattedDateTime(formattedDate);

      const payload = {
        userId: userId,
        title: title,
        eventType: EVENT_TYPE.OTHER,
        eventDate: combinedDateTime.valueOf(),
        eventTime: combinedDateTime.valueOf(),
        additionalNotes: desc,
        videoLink: videoLink,
      };
      try {
        const res = await toast.promise(addEvents(payload), {
          pending: MESSAGES.CALENDAR.PENDING,
          success: MESSAGES.CALENDAR.SUCCESS,
          error: MESSAGES.CALENDAR.ERROR,
        });
        addEvent({ title, start: date });
        setEventId(res?.data?._id);
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Please fill in all fields (Title, Start Date, End Date)");
    }
  };

  const handleEventLink = async (date: any, time: any) => {
    if (eventId) {
      const payload = {
        eventId: eventId,
        eventDate: date.toString(),
        eventTime: time,
      }
      try {
        const res = await getEventLink(payload);
        setEventLink(res?.data?.invite_url);
        console.log(res?.data?.
          invite_url, 'link event');
      } catch (error) {
        console.log("Error occuring while generating event link");
      }
    }
  }

  const handleInvite = () => {
    if (eventId && date && time) {
      const combinedDateTime = moment(date)
        .set({
          hour: moment(time, "HH:mm").hour(),
          minute: moment(time, "HH:mm").minute(),
          second: 0,
          millisecond: 0,
        })
        .toDate();
      handleEventLink(combinedDateTime.valueOf(), time);
      openModal2();
    } else {
      alert("Please fill in all fields to generate an invite link.");
    }
  };

  const handlGenerateLink = async () => {
    const response = await getVideoLink();
    setVideoLink(response?.data?.url);
  }

  return (
    <>
      <Modal show={modal1} onHide={closeModal1} centered backdrop='static'>
        <Modal.Header closeButton>
          <ModalTitle className="">Add New Event</ModalTitle>
        </Modal.Header>
        <ModalBody>
          <div className="p-4">
            <div className="text-start mb-4">
              <p className="text-14 text-muted">
                A notification will be sent to the professional, and it will be
                <br /> added to both your and the professional's calendars.
              </p>
            </div>
            <div className="form-group mb-3">
              <label className="text-14">Add Title:</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label className="text-14">Add Date:</label>
              <DatePicker
                selected={date}
                onChange={handleDateChange}
                className="form-control"
                placeholderText="Start Date"
                minDate={new Date()}
                dateFormat="MM-dd-yyyy"
              />
            </div>
            <div className="form-group mb-3">
              <label className="text-14">Add Time:</label>
              <input
                type="time"
                className="form-control"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label className="text-14">Add Video Conferencing:</label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control w-75"
                  value={videoLink}
                  placeholder="Add generated link here"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <div className="input-group-append w-25">
                  <button
                    className="btn btn-primary form-control w-100 px-0 text-14"
                    type="button"
                    onClick={handlGenerateLink}
                  >
                    Generate Link
                  </button>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="text-14">Description:</label>
              <textarea
                rows={5}
                cols={5}
                className="form-control"
                placeholder="Description"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="btn-hover-effect g-3">
          <div className="col">
            <Button
              type="button"
              className="btn text-14 bg-blue fw-semibold py-2"
              onClick={handleAddEvents}
            >
              Add Events
            </Button>
          </div>

          <div className="col">
            <Button
              type="button"
              className="btn text-14 bg-blue fw-semibold py-2"
              onClick={() => handleInvite()}
              disabled={eventId === ''}
            >
              Share/Invite
            </Button>
          </div>
          <div className="col">
            <Button
              type="button"
              className="btn text-14 bg-white text-black py-2 fw-semibold"
              onClick={closeModal1}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      {modal2 && (
        <AddUser
          modal2={modal2}
          closeModal2={closeModal2}
          text={`${title},${formattedDateTime},${desc}`}
          url={eventLink}
        />
      )}
    </>
  );
};

export default AddEvent;
