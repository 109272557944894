import React, { useState, useRef, useEffect } from "react";
import styles from "../styles/style.module.css";
import dnlod from "../../../../assets/images/dnlod.png";
import pnedit from "../../../../assets/images/pnedit.png";
import dlt from "../../../../assets/images/dlt.png";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpensePayload } from "../interface";
import { WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import { addexpense, getexpenseById } from "../api";
import { uploadFileImg } from "../../../Posts/api";
import { toast } from "react-toastify";
interface Expenses {
  dailyPerDiem: number[];
  travelDayPerDiem: number[];
  hotelAllowance: number[];
  rentalCarAllowance: number[];
  fuel: number[];
  airFareAllowance: number[];
  mileage: number[];
  luggage: number[];
}
interface Receipt {
  dailyReceipt?: String;
  travelReceipt?: String;
  hotelReceipt?: String;
  carReceipt?: String;
  fuelReceipt?: String;
  airFareReceipt?: String;
  mileageReceipt?: String;
  luggageReceipt?: String;
}
interface ReceiptData {
  [key: string]: File[];
}

const AddExpenseProf = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const passedData = location.state;
  const [expenseData, setExpenseData] = useState<any[]>([]);
  const isEditing = expenseData.length > 0;
  const [expenses, setExpenses] = useState<Expenses>({
    dailyPerDiem: Array(7).fill(""),
    travelDayPerDiem: Array(7).fill(""),
    hotelAllowance: Array(7).fill(""),
    rentalCarAllowance: Array(7).fill(""),
    fuel: Array(7).fill(""),
    airFareAllowance: Array(7).fill(""),
    mileage: Array(7).fill(""),
    luggage: Array(7).fill(""),
  });
  const [uploadedReceipts, setUploadedReceipts] = useState<ReceiptData>({});

  useEffect(() => {
    const getExpense = async () => {
      if (passedData?.expenseId != undefined) {
        const res = await getexpenseById(passedData?.expenseId);
        setExpenseData(res?.data?.expenseDetails);
        if (res?.data?.expenseDetails) {
          const apiData = res.data.expenseDetails[0];
          // Convert API values to arrays of 7 elements
          const convertToArray = (value: any) =>
            Array.isArray(value) ? value : Array(7).fill(value || 0);

          setExpenses({
            dailyPerDiem: Array(7).fill(""),
            travelDayPerDiem: Array(7).fill(""),
            hotelAllowance: Array(7).fill(""),
            rentalCarAllowance: Array(7).fill(""),
            fuel: Array(7).fill(""),
            airFareAllowance: Array(7).fill(""),
            mileage: Array(7).fill(""),
            luggage: Array(7).fill(""),
          });
        }
      }
    };
    getExpense();
  }, [passedData?.expenseId]);

  const handleChange = (
    category: keyof Expenses,
    index: number,
    value: string
  ) => {
    const numericValue = Number(value) || 0;
    setExpenses((prev) => {
      const updatedCategory = [...prev[category]];
      updatedCategory[index] = numericValue;
      return { ...prev, [category]: updatedCategory };
    });
  };

  const calculateDailyTotal = (dayIndex: number) => {
    return Object.keys(expenses).reduce((total, category) => {
      const categoryKey = category as keyof Expenses;
      total += expenses[categoryKey][dayIndex] || 0;
      return total;
    }, 0);
  };

  const calculateWeeklyTotal = () => {
    return Object.keys(expenses).reduce((total, category) => {
      total += expenses[category as keyof typeof expenses].reduce((sum, val) => sum + (val || 0), 0);
      return total;
    }, 0);
  };

  const handleReceiptUpload = async (
    category: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((img) => {
        formData.append("files", img);
      });

      try {
        const response = await uploadFileImg(formData);
        console.log("Response from AWS upload:", response);
        const uploadedFiles = response.data?.files || [];
        if (uploadedFiles.length > 0) {
          const receiptCategoryMap: { [key: string]: keyof Receipt } = {
            dailyPerDiem: "dailyReceipt",
            travelDayPerDiem: "travelReceipt",
            hotelAllowance: "hotelReceipt",
            mileage: "mileageReceipt",
            rentalCarAllowance: "carReceipt",
            fuel: "fuelReceipt",
            airFareAllowance: "airFareReceipt",
            luggage: "luggageReceipt",
          };
          const receiptKey = receiptCategoryMap[category];
          console.log("Receipt key:", receiptKey);
          if (!receiptKey) {
            console.error(`No receipt key found for category: ${category}`);
            return;
          }
          setUploadedReceipts((prev) => {
            return {
              ...prev,
              [receiptKey]: uploadedFiles[0],
            };
          });
        } else {
          console.error("No files uploaded or file URL is missing.");
        }
      } catch (err) {
        console.log("Error uploading files:", err);
      }
    }
  };

  const renderReceiptRequired = (category: string) => {
    const receiptMapping: Record<string, string> = {
      dailyPerDiem: "dailyReceipt",
      travelDayPerDiem: "travelReceipt",
      hotelAllowance: "hotelReceipt",
      rentalCarAllowance: "carReceipt",
      fuel: "fuelReceipt",
      airFareAllowance: "airFareReceipt",
      mileage: "mileageReceipt",
      luggage: "luggageReceipt",
    };
    const receiptKey = receiptMapping[category];
    const receiptRequired = passedData?.expenseKeys?.[receiptKey] === true;
    return (
      <td>{receiptRequired ? "Receipt Required" : "Receipt Not Required"}</td>
    );
  };

  const handleDeleteImage = (category: string, index: number) => {
    const updatedReceipts = [...(uploadedReceipts[category] || [])];
    updatedReceipts.splice(index, 1);
    setUploadedReceipts((prev) => ({
      ...prev,
      [category]: updatedReceipts,
    }));
  };

  const handleDownloadImage = (file: File | undefined) => {
    if (file) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    }
  };

  const handleSendExpense = async () => {
    const expenseDetails = Array.from({ length: 7 }, (_, dayIndex) => {
      return {
        workedDate: new Date(
          new Date().setDate(new Date().getDate() - (6 - dayIndex)) // Adjust dates for past 7 days
        ).toISOString(),
        dailyPerDiem: expenses.dailyPerDiem?.[dayIndex] ?? 0,
        travelDayPerDiem: expenses.travelDayPerDiem?.[dayIndex] ?? 0,
        hotelAllowance: expenses.hotelAllowance?.[dayIndex] ?? 0,
        mileage: expenses.mileage?.[dayIndex] ?? 0,
        rentalCarAllowance: expenses.rentalCarAllowance?.[dayIndex] ?? 0,
        fuel: expenses.fuel?.[dayIndex] ?? 0,
        airFareAllowance: expenses.airFareAllowance?.[dayIndex] ?? 0,
        luggage: expenses.luggage?.[dayIndex] ?? 0,
        dailyTotal: calculateDailyTotal(dayIndex),
        dailyCoveredTotal: calculateDailyTotal(dayIndex),
        dailyReceipt: uploadedReceipts.dailyReceipt || "",
        travelReceipt: uploadedReceipts.travelReceipt || "",
        hotelReceipt: uploadedReceipts.hotelReceipt || "",
        mileageReceipt: uploadedReceipts.mileageReceipt || "",
        carReceipt: uploadedReceipts.carReceipt || "",
        fuelReceipt: uploadedReceipts.fuelReceipt || "",
        airFareReceipt: uploadedReceipts.airFareReceipt || "",
        luggageReceipt: uploadedReceipts.luggageReceipt || "",
        dailyCustomerSup: passedData?.expenseKeys?.dailyCustomerSup ?? false,
        travelCustomerSup: passedData?.expenseKeys?.travelCustomerSup ?? false,
        hotelCustomerSup: passedData?.expenseKeys?.hotelCustomerSup ?? false,
        carCustomerSup: passedData?.expenseKeys?.carCustomerSup ?? false,
        fuelCustomerSup: passedData?.expenseKeys?.fuelCustomerSup ?? false,
        airFareCustSup: passedData?.expenseKeys?.airFareCustSup ?? false,
        mileageCustomerSup: passedData?.expenseKeys?.mileageCustomerSup ?? false,
        luggageCustomerSup: passedData?.expenseKeys?.luggageCustomerSup ?? false,
      };
    });
  
    const payload = {
      workId: passedData?.workId,
      total: calculateWeeklyTotal(),
      status: WORK_STATUS_TYPE.PENDING, // Change status to PENDING
      expenseDetails: expenseDetails,
    };
  
    try {
      const expenseId = passedData?.expenseId; // Get expense ID from `passedData`
     
  
      await addexpense( payload);
      toast.success("Expense sent for approval successfully!");
    } catch (error: any) {
      toast.error(error.response?.data?.message || "An error occurred!");
    }
  };
  

  // const handleSendExpense = async () => {
  //   const expenseDetails = (Object.keys(expenses) as Array<keyof Expenses>)
  //     .filter((category) => passedData?.expenseKeys?.[category])
  //     .reduce(
  //       (acc, category) => {
  //         const weeklyTotal = expenses[category].reduce(
  //           (sum, value) => sum + (value || 0),
  //           0
  //         );
  //         if (category === "dailyPerDiem") acc.dailyPerDiem = weeklyTotal;
  //         if (category === "travelDayPerDiem")
  //           acc.travelDayPerDiem = weeklyTotal;
  //         if (category === "hotelAllowance") acc.hotelAllowance = weeklyTotal;
  //         if (category === "rentalCarAllowance")
  //           acc.rentalCarAllowance = weeklyTotal;
  //         if (category === "fuel") acc.fuel = weeklyTotal;
  //         if (category === "mileage") acc.mileage = weeklyTotal;
  //         if (category === "airFareAllowance")
  //           acc.airFareAllowance = weeklyTotal;
  //         if (category === "luggage") acc.luggage = weeklyTotal;
  //         return acc;
  //       },
  //       {} as {
  //         dailyPerDiem?: number;
  //         travelDayPerDiem?: number;
  //         hotelAllowance?: number;
  //         mileage?: number;
  //         rentalCarAllowance?: number;
  //         fuel?: number;
  //         airFareAllowance?: number;
  //         luggage?: number;
  //       }
  //     );
  //   const expenseDetail = {
  //     workedDate: new Date().toISOString(),
  //     dailyPerDiem: expenseDetails.dailyPerDiem ?? 0,
  //     travelDayPerDiem: expenseDetails.travelDayPerDiem ?? 0,
  //     hotelAllowance: expenseDetails.hotelAllowance ?? 0,
  //     mileage: expenseDetails.mileage ?? 0,
  //     rentalCarAllowance: expenseDetails.rentalCarAllowance ?? 0,
  //     fuel: expenseDetails.fuel ?? 0,
  //     airFareAllowance: expenseDetails.airFareAllowance ?? 0,
  //     luggage: expenseDetails.luggage ?? 0,
  //     dailyTotal: Object.values(expenseDetails).reduce(
  //       (sum, value) => sum + (value ?? 0),
  //       0
  //     ),
  //     dailyCoveredTotal: Object.values(expenseDetails).reduce(
  //       (sum, value) => sum + (value ?? 0),
  //       0
  //     ),
  //     dailyReceipt: uploadedReceipts.dailyReceipt || "",
  //     travelReceipt: uploadedReceipts.travelReceipt || "",
  //     hotelReceipt: uploadedReceipts.hotelReceipt || "",
  //     mileageReceipt: uploadedReceipts.mileageReceipt || "",
  //     carReceipt: uploadedReceipts.carReceipt || "",
  //     fuelReceipt: uploadedReceipts.fuelReceipt || "",
  //     airFareReceipt: uploadedReceipts.airFareReceipt || "",
  //     luggageReceipt: uploadedReceipts.luggageReceipt || "",
  //     dailyCustomerSup: passedData?.expenseKeys?.dailyCustomerSup,
  //     travelCustomerSup: passedData?.expenseKeys?.travelCustomerSup,
  //     hotelCustomerSup: passedData?.expenseKeys?.hotelCustomerSup,
  //     carCustomerSup: passedData?.expenseKeys?.carCustomerSup,
  //     fuelCustomerSup: passedData?.expenseKeys?.fuelCustomerSup,
  //     airFareCustSup: passedData?.expenseKeys?.airFareCustSup,
  //     mileageCustomerSup: passedData?.expenseKeys?.mileageCustomerSup,
  //     luggageCustomerSup: passedData?.expenseKeys?.luggageCustomerSup,
  //   };
  //   const payload = {
  //     workId: passedData?.workId,
  //     total: calculateWeeklyTotal(),
  //     status: WORK_STATUS_TYPE.DRAFT,
  //     expenseDetails: [expenseDetail],
  //   };
  //   try {
  //     const res = await addexpense(payload);
  //     toast.success("Expense data sent successfully!");
  //   } catch (error: any) {
  //     toast.error(error.data.message);
  //   }
  // };

  console.log(passedData, "pass");
  console.log(uploadedReceipts, "receipts url");
  console.log(expenseData, "api expense");

  return (
    <div className={styles.pageOuter}>
      <div className="container">
        <h4 className="fw-bold text-26 mb-4">{passedData?.title}</h4>
        <div className={`rounded-4 ${styles.baseInfo} ${styles.noMarg}`}>
          <div className={`table-responsive ${styles.tableexpense}`}>
            <table className="table">
              <thead className="bg-light align-middle">
                <tr>
                  <th>Expense</th>
                  <th>Allowance</th>
                  {[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ].map((day) => (
                    <th key={day}>{day}</th>
                  ))}
                  <th>Weekly Total</th>
                  <th>Receipt</th>
                </tr>
              </thead>
              <tbody>
                {(Object.keys(expenses) as Array<keyof Expenses>)
                  .filter((category) => passedData?.expenseKeys?.[category])
                  .map((category) => {
                    return (
                      <tr key={category}>
                        <td>{category.replace(/([A-Z])/g, " $1")}</td>
                        <td>
                          <input
                            type="text"
                            disabled
                            value={passedData?.expenseKeys?.[category] || ""}
                          />
                        </td>
                        {expenses[category].map((value, index) => (
                          <td key={index}>
                            <input
                              type="number"
                              value={value}
                              onChange={(e) =>
                                handleChange(category, index, e.target.value)
                              }
                            />
                          </td>
                        ))}
                        <td>
                          {expenseData.length > 0
                            ? expenseData[0][category] // Use the value from API if available
                            : expenses[category]
                                .reduce((sum, val) => sum + (val || 0), 0)
                                .toFixed(2)}
                        </td>
                        {renderReceiptRequired(category)}
                      </tr>
                    );
                  })}
              </tbody>

              <tfoot>
                <tr>
                  <td>
                    <strong>Daily Total</strong>
                  </td>
                  <td></td>
                  {Array(7)
                    .fill(0)
                    .map((_, dayIndex) => (
                      <td key={dayIndex}>
                        <strong>
                          ${calculateDailyTotal(dayIndex).toFixed(2)}
                        </strong>
                      </td>
                    ))}
                  <td>
                    <strong>${calculateWeeklyTotal().toFixed(2)}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          {/* --- Upload Receipt Table --- */}
          <div className="row align-items-center mt-4 justify-content-end pt-4">
            <div className="col-6">
              <h3 className="text-18 fw-bold mb-0">Upload Receipt</h3>
              <p className="text-12 fw-normal mb-0">
                *Converts the pdf into Image
              </p>
            </div>
            <div className="col-6 text-end">
              <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold float-end">
                <i className="me-2" style={{ transform: "rotate(180deg)" }}>
                  <img src={dnlod} alt="" className="img-fluid" />
                </i>{" "}
                Upload Receipt
              </button>
            </div>
          </div>

          <div
            className={`table-responsive ${styles.uploadtimetable} ${styles.tabletimesheet}`}
          >
            <table className="table rounded-3 overflow-hidden" cellSpacing={10}>
              <thead className="align-middle bg-primary">
                <tr>
                  <th className="ps-3">Expense</th>
                  <th className="ps-3">Receipt Required</th>
                  <th className="ps-3">Upload Receipt</th>
                  <th className="ps-3 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {(Object.keys(expenses) as Array<keyof Expenses>)
                  .filter((category) => passedData?.expenseKeys?.[category])
                  .map((category, index) => {
                    const receiptMapping: Record<string, string> = {
                      dailyPerDiem: "dailyReceipt",
                      travelDayPerDiem: "travelReceipt",
                      hotelAllowance: "hotelReceipt",
                      rentalCarAllowance: "carReceipt",
                      fuel: "fuelReceipt",
                      airFareAllowance: "airFareReceipt",
                      mileage: "mileageReceipt",
                      luggage: "luggageReceipt",
                    };

                    const receiptKey = receiptMapping[category];
                    const receiptRequired =
                      passedData?.expenseKeys?.[receiptKey] === true;

                    return (
                      <tr key={category}>
                        <td className="ps-3">
                          {category.replace(/([A-Z])/g, " $1")}
                        </td>
                        <td className="ps-3">
                          {receiptRequired ? "Yes" : "No"}
                        </td>
                        <td className="ps-3 d-flex">
                          {receiptRequired && (
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleReceiptUpload(category, e)}
                            />
                          )}
                        </td>
                        <td className="ps-3 text-center">
                          <ul className="d-inline-block mx-auto text-center w-100 mb-0">
                            <li className="d-inline-block me-2">
                              <i
                                className="d-block p-2 rounded-circle bg-blue"
                                onClick={() =>
                                  handleDownloadImage(
                                    uploadedReceipts[category]?.[index]
                                  )
                                } // Pass index to download handler
                              >
                                <img
                                  src={dnlod}
                                  alt="download"
                                  height="20px"
                                  width="20px"
                                  style={{ filter: "brightness(20.5)" }}
                                />
                              </i>
                            </li>
                            <li className="d-inline-block me-2">
                              <i
                                className="d-block p-2 rounded-circle bg-blue"
                                onClick={() =>
                                  handleDeleteImage(category, index)
                                } // Pass index to delete handler
                              >
                                <img
                                  src={dlt}
                                  alt="delete"
                                  height="20px"
                                  width="20px"
                                  style={{
                                    filter: "brightness(20.5) invert(1)",
                                  }}
                                />
                              </i>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <button className="btn btn-primary" onClick={handleSendExpense}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpenseProf;


