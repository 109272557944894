import React, { useState, useEffect } from "react";
import { ModalBody, ModalFooter } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import { updateUserProfile } from "../../../home/api";
import { toast } from "react-toastify";

interface CompanyContact {
  name?: string;
  city?: string;
  phoneNumber?: string;
  email?: string;
}

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  refresh: () => void;
  companyContact?: CompanyContact; 
}

const EditCompanyInfoModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  title,
  refresh,
  companyContact
}) => {
  const [name, setName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Prefill form fields when companyContact changes
  useEffect(() => {
    if (companyContact) {
      setName(companyContact.name || "");
      setCity(companyContact.city || "");
      setPhoneNumber(companyContact.phoneNumber || "");
      setEmail(companyContact.email || "");
    }
  }, [companyContact]);

  const validate = () => {
    let newErrors: { [key: string]: string } = {};

    if (!name.trim()) newErrors.name = "Company Name is required.";
    if (!city.trim()) newErrors.city = "Address is required.";

    // Email Validation
    if (!email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      newErrors.email = "Invalid email format.";
    }

    // Phone Number Validation (Only digits & exactly 10 characters)
    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone number is required.";
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be exactly 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdateInfo = async () => {
    if (validate()) {
      const payload = {
        companyContact: {
          name,
          city,
          phoneNumber,
          email,
        },
      };
      try {
        await updateUserProfile(payload);
        refresh();
        toast.success("Updated successfully")
        closeModal();
      } catch (error) {
        console.log("Error while updating the company details", error);
      }
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form>
        <ModalBody>
          <div className="addModalPro">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Company Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (e.target.value.trim()) {
                        setErrors((prev) => ({ ...prev, name: "" }));
                      }
                    }}
                  />
                  {errors.name && <p className="text-danger">{errors.name}</p>}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Company Phone</label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Enter telephone"
                    value={phoneNumber}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                      setPhoneNumber(value);
                      if (/^\d{10}$/.test(value)) {
                        setErrors((prev) => ({ ...prev, phoneNumber: "" }));
                      }
                    }}
                  />
                  {errors.phoneNumber && <p className="text-danger">{errors.phoneNumber}</p>}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Company Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email here"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(e.target.value)) {
                        setErrors((prev) => ({ ...prev, email: "" }));
                      }
                    }}
                  />
                  {errors.email && <p className="text-danger">{errors.email}</p>}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Address"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      if (e.target.value.trim()) {
                        setErrors((prev) => ({ ...prev, city: "" }));
                      }
                    }}
                  />
                  {errors.city && <p className="text-danger">{errors.city}</p>}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="btn-hover-effect mb-2 justify-content-end">
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">Back</span>
            <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
              <img src={blackarrow} alt="" width={15} height={15} style={{ transform: "rotate(-180deg)" }} />
            </i>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={handleUpdateInfo}
            className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">Save</span>
            <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
              <img src={whitearrow} alt="" width={15} height={15} />
            </i>
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditCompanyInfoModal;
