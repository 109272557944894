import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import penciledit from "../../../../assets/images/penciledit.svg";
import downloadbalck from "../../../../assets/images/download_black.svg";
import printblack from "../../../../assets/images/print_black.svg";
import certificate from "../../../../assets/images/certificate.png";
import EditCertificateModal from "../modals/EditCertificateModal";
import { CertificateProps } from "../../interface";

interface TheCertificateProps {
  docFile: CertificateProps[];
  refresh: () => void;
}

const Certifications: React.FC<TheCertificateProps> = ({ refresh, docFile }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<CertificateProps | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);
  const openModal = (data?: CertificateProps, index?: number) => {
    setEditData(data || null);

    if (index !== null && index !== undefined && index >= 0) {
      setIndexNumber(index);
    }
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <h5 className="text-20 fw-semibold mb-0 ">Certifications</h5>
        </div>
        <div className="col-2">
          <div className="text-end">
          </div>
        </div>
      </div>
      <ul className={styles.experienceSet}>
        <div className="row align-items-start justify-content-start g-3">
          {docFile.map((certification, index) => (
            <li key={index} className="col-lg-6 col-12">
              <div className="w-100 p-2 border rounded-3">
                <h4>{certification?.name}</h4>
                <h6>Start Date {certification?.startDate} — End Date {certification?.endDate}</h6>
                <div className="position-relative overflow-hidden rounded-3">
                  <figure className="mb-0 photobanner">
                    <img src={certification?.filename} alt="" className="img-fluid w-100 objectfitcover" height={"280px"} />
                  </figure>
                  <ul
                    className="d-flex align-items-center justify-content-end position-absolute top-0 end-0"
                    style={{ gap: "15px" }}
                  >
                    <li
                      className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2 cursor-pointer"
                      onClick={() => openModal(certification, index)}
                    >
                      <i className="me-1">
                        <img src={penciledit} alt="" width={12} height={12} />
                      </i>
                      <span className="text-10">Edit</span>
                    </li>
                    <li
                      className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2 cursor-pointer"
                      onClick={() => {
                        const printContent = `
                          <div style="text-align: center; font-family: Arial, sans-serif;">
                            <h1>${certification?.name}</h1>
                            <h3>Start Date: ${certification?.startDate}</h3>
                            <h3>End Date: ${certification?.endDate}</h3>
                            <img src="${certification?.filename}" alt="Certificate" style="max-width: 100%; height: auto; margin-top: 20px;" />
                          </div>
                        `;

                        const printWindow = window.open("", "_blank");
                        if (printWindow) {
                          printWindow.document.open();
                          printWindow.document.write(`
                          <!DOCTYPE html>
                          <html>
                            <head>
                              <title>Print Certificate</title>
                            </head>
                            <body onload="window.print(); window.close();">
                              ${printContent}
                            </body>
                          </html>
                        `);
                          printWindow.document.close();
                        }
                      }}
                    >
                      <i className="me-1">
                        <img src={printblack} alt="" width={12} height={12} />
                      </i>
                      <span className="text-10">Print</span>
                    </li>
                    <li
                      className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2 cursor-pointer"
                      onClick={async () => {
                        try {
                          const response = await fetch(certification?.filename || "");
                          if (!response.ok) {
                            throw new Error("Failed to fetch image.");
                          }
                          const blob = await response.blob();

                          const url = URL.createObjectURL(blob);
                          const link = document.createElement("a");
                          link.href = url;
                          link.download = certification?.name || "certificate";

                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                          URL.revokeObjectURL(url);
                        } catch (error) {
                          console.error("Error downloading the image:", error);
                        }
                      }}
                    >
                      <i className="me-1">
                        <img src={downloadbalck} alt="" width={12} height={12} />
                      </i>
                      <span className="text-10">Download</span>
                    </li>

                  </ul>
                </div>
              </div>
            </li>
          ))}
          <li>
            <div onClick={() => openModal()} className="mt-2">
              <h4 className="text-14 fw-semibold text-blue cursor-pointer" >+ Add more</h4>
            </div>
          </li>
        </div>
      </ul>
      <EditCertificateModal
        showModal={showModal}
        closeModal={closeModal}
        title={editData ? "Edit Certications" : "Add"}
        refresh={refresh}
        docFile={docFile}
        editData={editData}
        index={indexNumber} />
    </div>
  );
};

export default Certifications;
