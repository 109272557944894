import React from "react";
import { Dropdown, FormControl, ModalBody, ModalFooter } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import upload from "../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
}

const EditSocialMediaModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  title,
}) => {
  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg" backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form>
        <ModalBody>
          <div className="addModalPro">
            <div className="row">              
              <div className="col-12">
                <div className="formGroup">
                  <label>Instagram</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Instagram"
                    />
                </div>
              </div>
              <div className="col-12">
                <div className="formGroup">
                  <label>linkedin</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Linkdin"
                    />
                </div>
              </div>
              <div className="col-12">
                <div className="formGroup">
                  <label>Facobook</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Linkdin"
                    />
                  </div>
              </div>
              <div className="col-12">
                <div className="formGroup">
                  <label>Youtube</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Linkdin"
                    />
                  </div>
              </div>
              <div className="col-12">
                <div className="formGroup">
                  <label>Twitter</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Linkdin"
                    />

                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="btn-hover-effect mb-2 justify-content-end">
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Back
            </span>
            <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
              <img
                src={blackarrow}
                alt=""
                width={15}
                height={15}
                style={{ transform: "rotate(-180deg)" }}
              />
            </i>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Save
            </span>
            <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
              <img src={whitearrow} alt="" width={15} height={15} />
            </i>
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditSocialMediaModal;
