import React, { useEffect, useState } from "react";
import styles from "../../css/styles/style.module.css";
import { InterviewQuestionResponse } from "../OpenContracts/interface";
import { getInterviewQuestions } from "../OpenContracts/api";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import InterviewScheduleModal from "../../modals/ScheduleInterviewModal/InterviewScheduleModal";

interface InterViewQusProps {
  workId: string;
  userId: string;
  onClose?: () => void;
  modal1?: boolean;
  openModal2?: () => void;
  closeModal1?: () => void;
}

const ViewInterViewQuestions: React.FC<InterViewQusProps> = ({
  workId,
  userId,
  openModal2,
  closeModal1,
  modal1,
}) => {
  const [viewInterviewQuestions, setShowInterviewQuestions] =
    useState<InterviewQuestionResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const getInterviewQus = async () => {
    const response = await getInterviewQuestions(userId, workId);
    setShowInterviewQuestions(response?.data);
    setLoading(false);
    setLoadingState(true);
  };

  useEffect(() => {
    setLoading(true);
    getInterviewQus();
  }, [workId, userId]);

  return (
    <>
      <Modal show={modal1} onHide={closeModal1} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Interview Questions</Modal.Title>
        </Modal.Header>
        <ModalBody>
          {loading ? (
            <>
            <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
          ) : (
            <div className="p-4">
              <div className="text-start mb-4">
                <p className="text-14 text-muted">Submitted Answers</p>
              </div>
              {viewInterviewQuestions?.interviewAnswers?.map((data, idx) => {
                return (
                  <div key={idx} style={{ margin: 10 }}>
                    <p>
                      <b>
                        {idx + 1}.{data?.question?.question}
                      </b>
                    </p>
                    <p>
                      <b>Answer:</b>{" "}
                      <span dangerouslySetInnerHTML={{ __html: data.answer }} />
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </ModalBody>
        <ModalFooter className="btn-hover-effect g-3">
          <div className="col">
            <Button
              type="button"
              className="btn text-14 bg-blue fw-semibold py-2"
              onClick={openModal2}
            >
              Next
            </Button>
          </div>
          <div className="col">
            <Button
              type="button"
              className="btn text-14 bg-white text-black py-2 fw-semibold"
              onClick={closeModal1}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ViewInterViewQuestions;
