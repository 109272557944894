import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import security from "../../../../assets/images/security.svg";
import downloadbalck from "../../../../assets/images/download_black.svg";
import printblack from "../../../../assets/images/print_black.svg";
import check from "../../../../assets/images/check_green.svg";
import CertInsuranceModal from "../modals/CertInsuranceModal";
import { insuranceProps } from "../../interface";

const Insurance : React.FC<insuranceProps> = ({refresh, doc}) => {
  console.log(doc,"the insurance doc ")
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<string | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);

  const openModal = (data?: string, index?: number) => {
    setEditData(data || null); // Set data to edit or null for new entry
    setIsModalOpen(true);
    console.log("index---", index);
    if (index !== null && index !== undefined && index >= 0) {
      console.log("index-if", index);
      setIndexNumber(index);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); // Clear data when closing modal
  };

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-7 col-12">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Certificate of Insurance
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-12 text-end">
          <ul
            className="d-flex align-items-center justify-content-end mb-0"
            style={{ gap: "15px" }}
          >
            {/* <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
              <i className="me-1">
                <img src={printblack} alt="" width={12} height={12} />
              </i>
              <span className="text-10">Print</span>
            </li> */}
            <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
              <i className="me-1">
                <img src={downloadbalck} alt="" width={12} height={12} />
              </i>
              <span className="text-10">Download</span>
            </li>
            <li>
              <div className="text-end">
                <i>
                  <img src={penbl} alt="" className="cursor-pointer" onClick={() => openModal()}/>
                </i>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-12 text-start my-3">
          <h4 className="justify-content-start text-black fw-semibold text-14 mb-2">
            <i className="me-2">
              <img src={check} alt="" width={15} />
            </i>
           {doc?.name}.pdf
          </h4>
        </div>
        <div className="col-12">
          <div className={styles.note}>
            <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
              <div className="col-lg-1 col-2 text-center">
                <i>
                  <img src={security} alt="" />
                </i>
              </div>
              <div className="col-lg-11 col-10">
                <p className="text-14 fw-medium mb-0 ps-2">
                  Please provide your financial information securely to
                  facilitate payments. Your security is extremely important to
                  us, and only Automate America Financial Administration will
                  have access to this information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CertInsuranceModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Edit Certificates of Insurance"
        refresh={refresh}
        doc={doc}
      />
    </div>
  );
};

export default Insurance;
