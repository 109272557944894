import React, { useState } from "react";
import styles from "../../../css/styles/style.module.css";
import { Button, Modal, Form } from "react-bootstrap";

type ModalProp = {
  handleClose: () => void;
  showModal: boolean;
  action?: string;
  workStatus?: string;
  id?: string;
  status?: string;
  changeStatus: (reason: string) => void;
};

const Reject = ({ showModal, handleClose, changeStatus }: ModalProp) => {
  const [reason, setReason] = useState("");

  const handleConfirm = () => {
    changeStatus(reason);
    setReason(""); // Reset after submission
  };

  return (
    <Modal backdrop="static" show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className={styles.modalTitle}>
          Are You Sure You Want To Reject This Timesheet?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <Form>
          <div className="the-reject-main p-3">
          <Form.Group controlId="rejectReason">
            <Form.Label className={styles.label}>Reason</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Enter the reason for rejection"
              className={styles.textarea}
            />
          </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <div className="d-flex gap-2">
        <Button
          className={styles.confirmButton}
          onClick={handleConfirm}
          disabled={!reason.trim()}
        >
          Confirm
        </Button>
        <Button variant="secondary" className={styles.cancelButton} onClick={handleClose}>
          Cancel
        </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default Reject;
