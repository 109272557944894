import { axios } from "../../../../lib/axios";
import { AxiosResponse } from "../../../../shared/models";
import { API_ROUTES } from "../../../../utils/routes/apiRoutes";
import { eventDetailRes } from "../interface";

export const getEventDetail = async (): Promise<
  AxiosResponse<eventDetailRes[]>
> => {
  return axios.get(API_ROUTES.EVENT.EVENTDETAILS);
};

export const getProfessionalRequest = async (
  id: any,
  status: any,
): Promise<any> => {
  return axios.get(
    `${API_ROUTES.PROFESSIONAL.GET_PROF}/${id}?status=${status}`
  );
};

export const getProfessionalRequestPast = async (
  id: string,
  status: string,
  workStatus: string
): Promise<any> => {
  return axios.get(
    `${API_ROUTES.PROFESSIONAL.GET_PROF}/${id}?status=${status}&workStatus=${workStatus}`
  );
};

export const getMyProfessionalRequestPast = async (
  id: string,
  status: string,
  workStatus: string
): Promise<any> => {
  return axios.get(
    `${API_ROUTES.PROFESSIONAL.GET_PROF}/${id}?status=${status}&workStatus=${workStatus}`
  );
};