import React, { useEffect } from "react";
import styles from "../../../components/common/css/styles/style.module.css";
import pro from "../../../assets/images/pro.png";
import loc from "../../../assets/images/loc.png";
import rdinfo from "../../../assets/images/rdinfo.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import storage from "../../../utils/storage";
import Slider from "react-slick";
import index from "../../Join/routes";
import { TYPE } from "../../../utils/enums/types";
import { getInterviewData } from "../api";

const GetAllTaskCalander = (props: any) => {
  console.log(props, "the propssssssssssss");
  const { getAllEventData } = props;
  const navigate = useNavigate();

  const handelVideoCall = (item: any) => {
    // storage.setClientInterviewEventId({
    //   day: moment.utc(item?.eventDate).local().format("MM-DD-YYYY"),
    //   time: moment.utc(item?.eventTime, "hh:mm").local().format("hh:mm A"),
    //   videoCallDetails: item.videoDetails,
    //   userId: item?.userId._id,
    // });
    //   storage.setClientInterviewUserId(item?.userId._id);
    // navigate(APP_ROUTES.VIDEO_CALL.AUDIENCE);

    window.open(`${item}`, '_blank');
  };

  const cancelContract = () => {
    console.log("cancel contract");
  };

  // const _getInterviewData = async () => {
  //   const response = await getInterviewData(TYPE.INTERVIEW);
  //   console.log(response, "interview data");
  // }

  // useEffect(() => {
  // _getInterviewData();
  // }, []);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {getAllEventData?.length ? (
          getAllEventData?.map((elem: any, ind: number) => (
            <>
              <div className="col" key={ind}>
                <div className={styles.ContarctOuter}>
                  <div className={`px-3 py-2 ${styles.ContarctHead}`}>
                    <div
                      className={`py-1 w-100 d-flex align-items-center justify-content-between ${styles.leftContract}`}
                    >
                      <h5 className="fw-semibold text-14 mb-0">ID: #457</h5>
                      <button className={`rounded-pill ${styles.shedule}`}>
                        <i className="fas fa-video"></i> {elem?.interviewType}
                      </button>
                    </div>
                  </div>
                  <div className={styles.outerNameDetail}>
                    <div className="row align-items-center pb-3 border-bottom">
                      <div className="col-md-12">
                        <div className="style_outerNDetail__kXLdh">
                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="mb-3">
                                <h4 className="fw-semibold text-18 clamp clamp1">
                                  {" "}
                                  Professional Details
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-12 pe-0">
                        <div className="style_leftName__QdRtk">
                          <div className="style_PrileImg__kzlGW">
                            <span className="text-center w-100">
                              <i>
                                <img
                                  src={pro}
                                  alt=""
                                  className=" img-fluid rounded-circle"
                                  style={{
                                    width: "100px",
                                    minHeight: "60px",
                                  }}
                                />
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-6">
                        <div className="style_leftName__QdRtk">
                          <div className="style_PrileImg__kzlGW">
                            <div className="">
                              <h5 className="text-capitalize clamp clamp1">
                                {elem?.userId?.name}
                              </h5>
                              <h6 className="clamp clamp1">
                                Pia Automation . inc
                              </h6>
                              <p className="clamp clamp1 d-initial align-items-center">
                                <i className="d-inline-block">
                                  <img src={loc} alt="" />
                                </i>{" "}
                                {elem?.userId?.countryId?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-6">
                        <div className="ps-3 style_rightName__wODa6">
                          <p>
                            Start Date:{" "}
                            {moment
                              .utc(elem?.workId?.times?.startDate)
                              .local()
                              .format("MM-DD-YYYY")}
                          </p>
                          <p>
                            End Date:{" "}
                            {moment
                              .utc(elem?.workId?.times?.endDate)
                              .local()
                              .format("MM-DD-YYYY")}
                          </p>
                          <p>
                            Rate Per Hour : ${elem?.workId?.rates?.baseRate}
                          </p>
                          <p>
                            Expense Terms : Net{" "}
                            {elem?.workId?.times?.noOfPepole}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`py-0 ${styles.VideoCallBtns}`}>
                    <div className="row align-items-center mx-0">
                      <div className="col-6">
                      <button onClick={cancelContract} className="rounded-3 text-14 py-2 p-2 bg-blue text-white w-100 text-center fw-semibold">
                      <i className="far fa-times-circle"></i> Cancel Contract
                    </button>
                      </div>
                      <div className="col-6">
                      <button
                       className="rounded-3 text-14 py-2 p-2 bg-blue text-white w-100 text-center fw-semibold"
                      onClick={() => handelVideoCall(elem?.videoLink)}
                    >
                      <i className="fas fa-video"></i>{" "}
                      {moment
                        .utc(elem?.eventDate)
                        .local()
                        .format("DD MMM YYYY")}
                    </button>
                      </div>
                    </div>
                    
                    
                  </div>
                  <div className={`my-2 ${styles.disc}`}>
                    <h4 className="mb-0 text-18 fw-semibold">
                      Additional Notes{" "}
                    </h4>
                    <p className="mb-2">{elem.additionalNotes}</p>
                  </div>
                  <div className={styles.Infor}>
                    <p className="text-14 d-flex align-items-center">
                      <i className="me-2">
                        <img src={rdinfo} alt="" />
                      </i>{" "}
                      If you cancel a project that you committed to, your
                      account will be subject to cancelation.
                    </p>
                  </div>
                </div>
              </div>
            </>
          ))
        ) : (
          <p>Data No Found </p>
        )}
      </Slider>
    </>
  );
};

export default GetAllTaskCalander;
