import React, { useEffect, useRef, useState } from "react";
import Header from "../../../header/routes";
import info from "../../../../assets/images/tip.png";
import exportt from "../../../../assets/images/exportt.png";
import { Link, useLocation, useParams } from "react-router-dom";
import styles from "../../../common/timesheets/styles/styles/style.module.css";
import moment from "moment";
import { getInvoice } from "../../expense/api";
import { INVOICE_UNPAID_TYPE } from "../../../../utils/enums/timesheetOrExpenseStatusTypes";
import Toltip from "../../toltip/page";
import { addImagesToPdf } from "../../../../common/api";

const InvoiceTable = () => {
  const { id } = useParams();
  console.log(id, "invooice work id ")
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isBusiness = searchParams.get("isBusiness") === 'true';

  const [approvedExpenseData, setApprovedExpenseData] = useState<any>();
  const [pendingExpenseData, setPendingExpenseData] = useState<any>();
  const [loader, setLoader] = useState<boolean>(true);
  const contentRef = useRef(null);
  const contentRef1 = useRef(null)
  const [paidDownloading, setPaidDownloading] = useState<boolean>(false);
  const [pendingDownloading, setPendingDownloading] = useState<boolean>(false);



  const getApprovedData = async () => {
    const response = await getInvoice(id, INVOICE_UNPAID_TYPE.PAID, false);
    setApprovedExpenseData(response?.data);
  };

  const getPendingData = async () => {
    const response = await getInvoice(
      id,
      INVOICE_UNPAID_TYPE.UNPAID,
      isBusiness as unknown as boolean
    );
    console.log(response.data, "the pending expense data ")
    setPendingExpenseData(response?.data);
  };

  const download = (ref: any, name: any, setDownloading: any) => {
    addImagesToPdf([ref], setDownloading, name)

  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getApprovedData();
        await getPendingData();
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false);
      }
    };

    fetchData();
  }, [isBusiness]);

  return (
    <>
      <Header />
      <div className={styles.TimeShettTable}>
        <div className="container">
          <h3 className="fw-bold text-26 mb-4">Invoices</h3>
          {loader ? (
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              <div className={styles.TimeShettTableOuter}>
                <div className={styles.TimeShettTableHead}>
                  <div className="col-8 topTipHover">
                    <div className="w-100 d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                        Paid Invoices
                      </h4>
                      <i>
                        <img className="customeTolImg" src={info} alt="" />
                      </i>
                      <Toltip
                      />
                    </div>
                  </div>
                  <div className="col-4 text-end">
                    <button onClick={() => download(contentRef, "paid", setPaidDownloading)} className="btn bg-white text-14 fw-bold font-inter d-flex align-items-center w-auto float-end">
                      {" "}
                      <i className="me-2"><img src={exportt} alt="" /></i>
                      Export Data
                    </button>
                  </div>
                </div>
                <div ref={contentRef}>
                  <div className={`table-responsive ${styles.tabletimesheet}`}>
                    <table className="table align-middle">
                      <tr>
                        <th>WR#</th>
                        <th>PROFESSIONAL NAME</th>
                        <th>START DATE</th>
                        <th>END DATE</th>
                        <th>APPROVED DATE</th>
                        <th>TOTAL AMOUNT</th>
                        <th>ACTIONS</th>
                      </tr>

                      <>
                        {approvedExpenseData?.length > 0 ? (
                          approvedExpenseData?.map((data: any, idx: number) => {
                            return (
                              <tr key={idx}>
                                <td>#{data?.work?.workNumber}</td>
                                <td>{data?.user?.name}</td>
                                <td>
                                  {moment(data.startDate).format("MM-DD-YYYY")}
                                </td>
                                <td>
                                  {moment(data.endDate).format("MM-DD-YYYY")}
                                </td>

                                <td>
                                  {moment(data.approvedDate).format("MM-DD-YYYY")}
                                </td>
                                <td>${data?.total}</td>
                                <td>
                                  <Link to={`/invoice/${id}`}>
                                    View Details
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr><td colSpan={12} className="text-14 fw-semibold text-center">No Invoices Found</td></tr>
                        )}
                      </>
                    </table>
                  </div></div>
              </div>
              <div className={styles.TimeShettTableOuter}>
                <div className={styles.TimeShettTableHead}>
                  <div className="col-8 topTipHover">
                    <div className="w-100 d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                        Pending to be Paid
                      </h4>
                      <i>
                        <img className="customeTolImg" src={info} alt="" />
                      </i>
                      <Toltip
                      />
                    </div>
                  </div>
                  <div className="col-4 text-end">
                    <button onClick={() => download(contentRef1, "pending", setPendingDownloading)} className="btn bg-white text-14 fw-bold font-inter d-flex align-items-center w-auto float-end">
                      {" "}
                      <i className="me-2"><img src={exportt} alt="" /></i>
                      Export Data
                    </button>
                  </div>

                </div>
                <div className={`table-responsive ${styles.tabletimesheet}`}>
                  <div ref={contentRef1}>
                    <table className="table align-middle">
                      <tr>
                        <th>WR#</th>
                        <th>PROFESSIONAL NAME</th>
                        <th>START DATE</th>
                        <th>END DATE</th>
                        <th>APPROVED DATE</th>
                        <th>TOTAL HOURS</th>
                        <th>ACTIONS</th>
                      </tr>

                      <>
                        {pendingExpenseData?.length > 0 ? (
                          pendingExpenseData?.map((data: any, idx: number) => {
                            return (
                              <tr key={idx}>
                                <td>#{data?.work?.workNumber}</td>
                                <td>{data?.user?.name}</td>
                                <td>
                                  {moment(data.startDate).format("MM-DD-YYYY")}
                                </td>
                                <td>
                                  {moment(data.endDate).format("MM-DD-YYYY")}
                                </td>
                                <td>
                                  {moment(data.approvedDate).format("MM-DD-YYYY")}
                                </td>
                                <td>${data?.total}</td>
                                <td>
                                  <Link to={`/invoice/${id}?timesheetID=${data?.timesheetsId}`}>
                                    Pay Now
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr><td colSpan={12} className="text-14 fw-semibold text-center">No Invoices Found</td></tr>
                        )}
                      </>
                    </table></div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InvoiceTable;
