import React from "react"
import Header from "../../header/routes"
import styles from "../styles/style.module.css";
import notifyimage from "../../../assets/images/notify_image.png";

const NotificationList = () => {
    return (
        <>
        <Header />
        <div className="container">
            <div className={`mt-4 rounded-4 ${styles.UserProfileOuter}`}>
                <h2 className="text-24 fw-semibold mb-0">Notifications</h2>
                <ul>
                    <li className={styles.active}>
                        <a className="text-decoration-none d-flex align-items-center">
                            <i><img src={notifyimage} alt="" /></i>
                            <div className=" ps-3">
                                <h3 className="text-14 mb-0 fw-medium fw-semibold">Your timesheet for your active contract is due</h3>
                                <p className="text-10 opacity-75 text-black mb-0">10 sec ago.</p>
                            </div> 
                        </a>
                    </li>
                    <li>
                        <a className="text-decoration-none d-flex align-items-center">
                            <i><img src={notifyimage} alt="" /></i>
                            <div className=" ps-3">
                                <h3 className="text-14 mb-0 fw-medium">Your timesheet for your active contract is due</h3>
                                <p className="text-10 opacity-75 text-black mb-0">10 sec ago.</p>
                            </div> 
                        </a>
                    </li>
                    <li>
                        <a className="text-decoration-none d-flex align-items-center">
                            <i><img src={notifyimage} alt="" /></i>
                            <div className=" ps-3">
                                <h3 className="text-14 mb-0 fw-medium">Your timesheet for your active contract is due</h3>
                                <p className="text-10 opacity-75 text-black mb-0">10 sec ago.</p>
                            </div> 
                        </a>
                    </li>
                    <li className={styles.active}>
                        <a className="text-decoration-none d-flex align-items-center">
                            <i><img src={notifyimage} alt="" /></i>
                            <div className=" ps-3">
                                <h3 className="text-14 mb-0 fw-medium">Your timesheet for your active contract is due</h3>
                                <p className="text-10 opacity-75 text-black mb-0">10 sec ago.</p>
                            </div> 
                        </a>
                    </li>
                   
                </ul>

                <div className="p-3">
                    <div className="formGroup col-3">
                      <button type="button" className="btn btn-primary fw-medium py-2 text-14">
                       See Older Notification
                      </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default NotificationList