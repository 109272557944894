import React, { useEffect, useState } from "react";
import styles from "../styles/sigup.module.css";
import { useNavigate } from "react-router-dom";
import left from "../../../../assets/images/left.png";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setAboutData } from "../../../../redux/reducers/auth";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import { RootState } from "../../../../redux/store";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";
import { getMasterTypes } from "../api";
import { toast } from "react-toastify";
import { postMasterType } from "../../../../components/work/api";
import { APIResponseError } from "../interface";
import { MASTER_TYPE } from "../../../../utils/enums/masterTypes.enum";

interface OptionType {
  value: string;
  label: string;
}
const dropdownValidationSchema = Yup.object({
  selectedOption: Yup.object().required("Please choose or create an option"),
});

const About: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector((state: RootState) => state.userData);
  const [options, setOptions] = useState<OptionType[]>([]);

  const fetchOptions = async () => {
    try {
      const response = await getMasterTypes(MASTER_TYPE.ABOUT);
      const formattedOptions = response.data.map((item: any) => ({
        value: item._id,
        label: item.name,
      }));
      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const signupFormik = useFormik({
    initialValues: {
      selectedOption: userDetail?.aboutData?.About ? { value: userDetail.aboutData.About, label: userDetail.aboutData.About } : null,
    },
    validationSchema: dropdownValidationSchema,
    onSubmit: (values) => {
      const selectedOption = {
        About: values.selectedOption ? values.selectedOption.label : "", 
      };
      dispatch(setAboutData(selectedOption));
      navigate(AUTH_ROUTES.PRIVACY_POLICY);
    },
  });

  const handleCreateOption = async (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue };

    try {
      await postMasterType({ name: inputValue, type: MASTER_TYPE.ABOUT, alias: inputValue });
      setOptions((prevOptions) => [...prevOptions, newOption]);
      signupFormik.setFieldValue("selectedOption", newOption); 
    } catch (error) {
      const apiError = error as APIResponseError;
      toast.error(apiError?.data?.message || "Error adding new option");
    }
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.occupationdetails}>
          <div className={styles.signuppage}>
            <div className={styles.signUPContainer} style={{ padding: "15px" }}>
              <form onSubmit={signupFormik.handleSubmit}>
                <h5>How did you hear about us</h5>
                <span className={styles.Leftntm}>
                  <a href="#">
                    <img src={left} alt="" onClick={() => navigate(AUTH_ROUTES.CITIZENSHIP)} />
                  </a>
                </span>
                <p>From where you heard about us</p>
                <div className={styles.fromGroup}>
                  <CreatableSelect
                    id="selectedOption"
                    name="selectedOption"
                    className="basic-select"
                    classNamePrefix="select"
                    onChange={(option) => signupFormik.setFieldValue("selectedOption", option)}
                    onCreateOption={handleCreateOption}
                    options={options} 
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    value={signupFormik.values.selectedOption} 
                  />
                  {signupFormik.touched.selectedOption && signupFormik.errors.selectedOption && (
                    <div className={styles.error}>
                      {signupFormik.errors.selectedOption}
                    </div>
                  )}
                </div>
                <div className="col-lg-3 col-6 mx-auto">
                  <div className="text-center my-3 btn-hover-effect border rounded-3">
                    <button
                      type="submit"
                      className="btn bg-white arrowblue border-white border-1 position-relative w-100 pe-5 text-center"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold w-100">Next</span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                      </i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
