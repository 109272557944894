import React from 'react'
import styles from '../../css/styles/style.module.css'
import userimg from "../../../../assets/images/user.jpg"
import loc from '../../../../assets/images/loc.png'
interface Props {
    expenseDetails: any
}

const WhiteGloveForInvoice: React.FC<Props> = ({ expenseDetails }) => {
    console.log(expenseDetails, "the detail;sssssssssssssssssssssssssssssssssssss")

    return (
        <div className="Contracts OngoingContractDetails">
            <div className="container px-0">

                <div className={styles.OngoingContractDetails}>
                    <div className={styles.ContractTabs}>

                        <div className={styles.OuterSlides}>
                            <div className="">
                                <div className={`px-0 ${styles.outerNameDetail}`}>
                                    <div className="row g-3">
                                        <div className="col-lg-6">
                                            <div className={`py-3 ${styles.leftName}`} style={{ minHeight: "170px" }}>

                                                <div className={styles.PrileImg}>
                                                    <span className="text-center">
                                                        {/* <img src={expenseDetails?.user?.profilePicture} alt="" /> */}
                                                        <img src="https://automateamerica-assets.s3.us-east-1.amazonaws.com/uploads/1733396056925-emp2.jpeg" alt="" />

                                                        <h5 className="text-14 py-2 justify-content-center fw-medium">#{expenseDetails?.work?.workNumber || "2223233"}</h5>
                                                        <button className="text-12 fw-semibold btn-info text-blue rounded-pill px-2 py-1 border-0 bg-lightblue">{expenseDetails?.work?.workType === "hourly_contract" ? "Hourly Contract" : "" || "Hourly Contract"}</button>
                                                    </span>
                                                    <div className="ms-3">
                                                        <h5>{expenseDetails?.professional?.name || "Daniel "}</h5>
                                                        <h5 className="fw-medium text-14 font-poppins lh-base">Pia Automation . inc</h5>
                                                        <h5 className="fw-medium text-14 font-poppins lh-base">PLC Programer</h5>
                                                        <p className="fw-medium text-14 font-poppins lh-base ps-0 d-flex align-items-center justify-content-start">
                                                            <i className='pe-2'><img src={loc} alt="" className='position-relative' /></i>{expenseDetails?.work?.street || "Chattanooga,Tennessee,37402"
                                                            }</p>
                                                        <h6 className="fw-bold text-blue text-14 font-poppins lh-base">
                                                            Rate PerHour : ${expenseDetails?.baseRate || "0"}
                                                            (Net {expenseDetails?.hourlyPaymentTerm?.length ? expenseDetails.hourlyPaymentTerm[0].paymentTerm : "N/A"})
                                                        </h6>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhiteGloveForInvoice