import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { toast } from "react-toastify";
import { sendOTP } from "../api";
import { RootState } from "../../../../redux/store";
import { MESSAGES } from "../../../../utils/messages";
import { APIResponseError, sendOTPResponse } from "../interface";
import { setContact } from "../../../../redux/reducers/auth";
import { setOtpTypeMethod } from "../../../../redux/reducers/auth";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import styles from "../styles/sigup.module.css";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import left from "../../../../assets/images/left.png";
import phnImg from "../../../../assets/images/icon-call.png";
import sms from "../../../../assets/images/icon-msg.png";
import checkblue from '../../../../assets/images/check_blue.svg'

const PhoneVerify: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector((state: RootState) => state.userData);
  const [phoneNumber, setPhoneNumber] = useState(userDetail?.contact?.phoneNum || "");
  const [countryCode, setCountryCode] = useState<string>(userDetail?.contact?.countryCode || '');
  const [otpMethod, setOtpMethod] = useState<'sms' | 'call'>('sms');
  const [error, setError] = useState<string | null>(null);

  console.log(userDetail, 'detailsssss');
  

  const handlePhoneChange = (value: string | undefined | null) => {
    if (value) {
      setPhoneNumber(value);
      const parsedNumber = parsePhoneNumber(value);
      setCountryCode(parsedNumber?.countryCallingCode || "");
    } else {
      setCountryCode("");
      setPhoneNumber("");
    }
  };

  const validatePhoneNumber = (number: string) => {
    const parsedNumber = parsePhoneNumber(number);
    return parsedNumber && parsedNumber.nationalNumber?.length === 10;
  };

  const handleOtpMethodChange = (method: 'sms' | 'call') => {
    setOtpMethod(method);
    dispatch(setOtpTypeMethod(method));
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validatePhoneNumber(phoneNumber)) {
      setError("Please enter a valid 10-digit phone number.");
      return;
    }
    setError(null);

    const parsedNumber = parsePhoneNumber(phoneNumber);
    const nationalNumber = parsedNumber?.nationalNumber || phoneNumber;

    const payload = {
      countryCode: `+${countryCode}`,
      phoneNumber: nationalNumber,
      type: otpMethod,
    };

    toast.promise(sendOTP(payload), {
      pending: {
        render() {
          return MESSAGES.SENDOTP.VERIFY;
        },
      },
      success: {
        render({ data }) {
          let successResponse = data as sendOTPResponse;
          const contactObj = {
            phoneNum: phoneNumber,
            phoneNumVerifyId: successResponse?.data?._id,
            countryCode: countryCode,
            number: phoneNumber,
          };
          dispatch(setContact(contactObj));
          localStorage.setItem("phoneNumber", phoneNumber);
          navigate(AUTH_ROUTES.OTP_VERIFY);
          return MESSAGES.SENDOTP.SUCCESS;
        },
      },
      error: {
        render(error) {
          let errorMessage = error as APIResponseError;
          return errorMessage?.data?.data?.message;
        },
      },
    });
  };


  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className={styles.signUPContainer} style={{ padding: "15px" }}>
            <form onSubmit={handleSubmit}>
              <h5>Enter phone number</h5>
              <span className={styles.Leftntm}>
                <a href="#">
                  <img src={left} alt="" onClick={() => navigate(AUTH_ROUTES.EMAIL_VERIFY)} />
                </a>
              </span>
              <p>
                A code will be texted to this number for verification or we will
                call you.
              </p>

              <div className={styles.fromGroup}>
                <div className={styles.resentOTP}>
                  <div>
                    <PhoneInput
                      placeholder="Eg. +1 254 2546 333"
                      value={phoneNumber}
                      onChange={handlePhoneChange}
                      defaultCountry="US"
                      international
                      countrySelectProps={{ unicodeFlags: true }}
                    />
                    {error && <div className={styles.error}>{error}</div>}
                  </div>
                  <p>
                    Would you rather receive a phone call with your{" "}
                    <br />
                    verification code?
                  </p>




                  <div className="phn_div d-flex justify-content-center gap-2 otp_icon">
                    <i className={otpMethod === 'call' ? 'active' : ''}
                      onClick={() => handleOtpMethodChange('call')}>
                      <img
                        src={phnImg}
                        alt="Phone call"

                        style={{

                          cursor: 'pointer'
                        }}
                      />
                    </i>
                    <i
                      className={otpMethod === 'sms' ? 'active' : ''}
                      onClick={() => handleOtpMethodChange('sms')}
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        src={sms}
                        alt="SMS"

                        style={{

                          cursor: 'pointer'
                        }}
                      />
                    </i>
                    <div className="select-check position-absolute">
                      <span><img src={checkblue} alt="" style={{ cursor: 'pointer' }} /></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-6 mx-auto">
                <div className="text-center my-3 btn-hover-effect border rounded-3">
                  <button
                    type="submit"
                    className="btn bg-white arrowblue border-white border-1 position-relative w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">
                      Next
                    </span>
                    <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneVerify;
