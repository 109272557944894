export enum TIMESHEET_OR_EXPENSE_TYPE {
    PENDING = 'pending',
    APPROVED = 'approved',
    CURRENT = 'current',
    DUE = 'due',
    REJECT = 'rejected',
    TIMESHEET='timesheet'
}
export enum INVOICE_UNPAID_TYPE {
    UNPAID = 'unpaid',
    PAID = 'paid',
}