import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../styles/home.module.css";
import tmone from "../../../assets/images/tmone.png";
import tmtwo from "../../../assets/images/tmtwo.png";
import tmthree from "../../../assets/images/tmthree.png";
import tmfour from "../../../assets/images/tmfour.png";
import info from "../../../assets/images/info_icon.svg";
import pin from "../../../assets/images/pin.png";
import userImg from "../../../assets/images/user.jpg";
import link from "../../../assets/images/link.png";
import Header from "../../header/routes";
import blink from "../../../assets/images/blink.png";
import bchat from "../../../assets/images/bchat.png";
import bemail from "../../../assets/images/bemail.png";
import blinked from "../../../assets/images/blinked.png";
import bfacebook from "../../../assets/images/bfacebook.png";
import bwhatsapp from "../../../assets/images/bwhatsapp.png";
import { getuserProfile, updateUserProfile } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { WORK_TABS } from "../../../utils/enums/workTabs.enum";
import { changeContract, changeTab } from "../../../redux/reducers/dashboard";
import CustomerRequest from "../../common/customerrequest/routes";
import OpenContacts from "../../common/Contracts/OpenContracts/openContracts";
import Closecontract from "../../common/closedcontract/routes/page";
import OnGoingContracts from "../../common/Contracts/OngoingContracts/ongoingContracts";
import Toltip from "../../common/toltip/page";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import OngoingDirectJob from "../../common/jobs/OngoingDirectJob/Index";
import ClosedDirectJob from "../../common/jobs/ClosedDirectJob/Index";
import OpenDirectJob from "../../common/jobs/OpenDirectJob/Index";
import PendingAdminJob from "../../common/jobs/PendingAdminJob/Index";
import DraftDirectJob from "../../common/jobs/DraftDirectJob/Index";
import { setBusinessDetails } from "../../../redux/reducers/businessDetails";
import { setBusinessGroupData } from "../../../redux/reducers/businessGroupData";
import storage from "../../../utils/storage";
import OnGoingContractsProfessional from "../../common/Contracts/wokDoneByMe/contracts/OngoingContractsProfessional/ongoingContractsProfessional";
import MyProfessionals from "../../MyProfessional/MyProfessionals";
import PastHiredProf from "../../MyProfessional/PastHiredProf";
import JobOfferPending from "../../common/Contracts/wokDoneByMe/contracts/JobOfferPending/JobOfferPending";
import MyApplicationToJobs from "../../common/Contracts/wokDoneByMe/contracts/MyApplicationToJobs/MyApplicationToJobs";
import JobOfferRejected from "../../common/Contracts/wokDoneByMe/contracts/JobOfferRejected/JobOfferRejected";
import CompletedClosedContact from "../../common/Contracts/wokDoneByMe/contracts/Completed_ClosedContract/CompletedClosedContact";
import OnGoingDirectJobProfessional from "../../common/Contracts/wokDoneByMe/DirectInhouseJobs/OnGoingDirectJob";
import JobOffersPending from "../../common/Contracts/wokDoneByMe/DirectInhouseJobs/JobOffersPending";
import MyApplicationToJobsDirect from "../../common/Contracts/wokDoneByMe/DirectInhouseJobs/MyApplicationToJobs";
import JobOfferRejectedDirect from "../../common/Contracts/wokDoneByMe/DirectInhouseJobs/JobOffersRejected";
import CompletedClosedJobsDirect from "../../common/Contracts/wokDoneByMe/DirectInhouseJobs/CompletedClosedJobs";
import AddCalendar from "../../Calendar/AddCalendar";
import CustomerHired from "../../CustomerHiredPast/CustomerHired";
import DraftPending from "../../common/Contracts/DraftPendingContracts/DraftPending";
import ChatModule from "../../ChatModule/routes";
import adminchatlogo from "../../../assets/images/adminchatlogo1x.png";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [profileDetetails, setProfileDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const handleShowActiveTab = (value: WORK_TABS): void => {
    dispatch(changeTab(value));
  };

  const refresh = () => {
    setLoading(true);
    getuserProfile().then((res: any) => {
      console.log(res, "data profile");
      setProfileDetails(res);
      storage.setUserProfileImg(res?.data?.profilePicture);
      dispatch(
        setBusinessDetails({
          businessName: res.data?.businessDetail?.businessName,
          _id: res.data?.businessDetail?._id,
          groupName: res.data?.businessDetail?.group,
        })
      );
      dispatch(setBusinessGroupData(res.data.businessGroupData));
      setLoading(false);
    });
  };

  useEffect(() => {
    refresh();
  }, []);
  const navigateToProfile = () => {
    navigate("/editProfile");
  };

  const handleToggle = () => {
    updateUserProfile({
      isAvailable: !profileDetetails?.data?.isAvailable,
    }).then((res) => {
      refresh();
    });
  };
  return (
    <>
      <Header />
      {!loading ? (
        <div className="HomeOuter">
          <div className={styles.Mydashboard}>
            <div className="container">
              <div className={styles.topDashborad}>
                <h3>My Dashboard</h3>
                <div className="row g-lg-4 g-3">
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className={styles.timsheetInner}>
                      <span>
                        <img src={tmone} alt="" />
                      </span>
                      <div>
                        <h6>Timesheets</h6>
                        <h5>
                          {profileDetetails?.data?.totalTimesheets}
                          {/* <Link to="#">
                            More <i className="fas fa-arrow-right"></i>
                          </Link> */}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div
                      className={`${styles.timsheetInner} ${styles.timeTwo}`}
                    >
                      <span>
                        <img src={tmtwo} alt="" />
                      </span>
                      <div>
                        <h6>Expense Reports</h6>
                        <h5>
                          {profileDetetails?.data?.expensesDetails}
                          {/* <Link to="#">
                            More <i className="fas fa-arrow-right"></i>
                          </Link> */}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div
                      className={`${styles.timsheetInner} ${styles.timeThree}`}
                    >
                      <span>
                        <img src={tmthree} alt="" />
                      </span>
                      <div>
                        <h6>Invoices</h6>
                        <h5>
                          {profileDetetails?.data?.totalInvoices}
                          {/* <Link to="#">
                            More <i className="fas fa-arrow-right"></i>
                          </Link> */}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div
                      className={`${styles.timsheetInner} ${styles.timeFOur}`}
                    >
                      <span>
                        <img src={tmfour} alt="" />
                      </span>
                      <div>
                        <h6>Purchase Orders</h6>
                        <h5>
                          {/* {profileDetetails?.data?.totalReview} */}0{" "}
                          {/* <Link to="#">
                            More <i className="fas fa-arrow-right"></i>
                          </Link> */}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.ProfieDash}>
                <div className={styles.ProfileLink}>
                  {/* <Link to="#">View as Business</Link> */}
                  <Link to={APP_ROUTES.FINDOPPORTUNITIES}>Search Contract</Link>
                  <Link to="#">Search Business</Link>
                  {/* <Link to="#">Watchlist</Link> */}
                  <button onClick={() => navigate("/editProfile")}>
                    <i className="fas fa-pen"></i> Edit profile
                  </button>
                </div>
                <div className="row align-items-center mx-0">
                  <div className="col-xl-2 col-lg-3 col-12">
                    <div className={styles.ProfileDetails}>
                      <div className={styles.Imagero}>
                        <img
                          src={
                            profileDetetails?.data?.profilePicture
                              ? profileDetetails?.data?.profilePicture
                              : userImg
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-10 col-lg-9 col-12 px-3 py-4">
                    <div className={styles.NamePro}>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="text-capitalize">
                          {profileDetetails?.data?.name}{" "}
                        </h5>
                        <div className="form-check form-switch p-0">
                          <div>
                            <label
                              className={`form-check-label ${
                                profileDetetails?.data?.isAvailable
                                  ? styles.available
                                  : styles.unavailable
                              }`}
                            >
                              {profileDetetails?.data?.isAvailable
                                ? "I am Available"
                                : "Unavailable"}
                            </label>
                            <input
                              checked={profileDetetails?.data?.isAvailable}
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                              onChange={handleToggle}
                            />
                          </div>
                        </div>
                      </div>

                      <h6 className="my-1">
                        {profileDetetails?.data?.occupationDetails?.length &&
                          profileDetetails?.data?.occupationDetails[0]
                            ?.name}{" "}
                        <span>
                          <i className="fas fa-star"></i> (
                          {profileDetetails?.data?.totlalReview} reviews)
                        </span>
                      </h6>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-xl-4 col-lg-5 col-12">
                          <ul className={`mt-0 ${styles.profile_des}`}>
                            <li className="d-flex align-items-center">
                              <i className="me-2">
                                <img src={pin} alt="" />
                              </i>{" "}
                              {profileDetetails?.data?.countoryDetails?.name}
                            </li>
                            <li className="d-flex align-items-center">
                              <i className="me-2">
                                <img src={link} alt="" />
                              </i>{" "}
                              {profileDetetails?.data?.phoneNumber}
                            </li>
                          </ul>
                        </div>

                        <div className="col-xl-8 col-lg-8 col-12">
                          <div
                            className={`d-flex gap-3 jusity-content-end align-items-center ${styles.bostns}`}
                          >
                            <button
                              className="btn btn-orange text-16"
                              onClick={() =>
                                navigate(APP_ROUTES.BOOST_PROFILE, {
                                  state: { profileDetetails },
                                })
                              }
                            >
                              Boost My Profile
                            </button>
                            <div className="dropdown">
                              <button
                                className={`dropdown-toggle rounded-2 ${styles.ShareProfile}`}
                                type="button"
                                id="sharebuttonsdashboard"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Share{" "}
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="sharebuttonsdashboard"
                              >
                                <li>
                                  <Link to="#">
                                    <img src={blink} alt="" />
                                    Copy Link
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={bchat} alt="" />
                                    SMS/Message
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={bemail} alt="" />
                                    Email
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={blinked} alt="" />
                                    Linkedin
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={bfacebook} alt="" />
                                    Facebook
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={bwhatsapp} alt="" />
                                    Whatsapp
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className={styles.ContractTbs}>
              <div className="rounded-3 shadow">
                <ul className={styles.tabList}>
                  <li
                    className={
                      activeTab === WORK_TABS.DONEBYME ? styles.active : ""
                    }
                    onClick={() => handleShowActiveTab(WORK_TABS.DONEBYME)}
                  >
                    Work Being Done By Me or My Employees
                  </li>
                  <li
                    className={
                      activeTab === WORK_TABS.DONEFORME ? styles.active : ""
                    }
                    onClick={() => handleShowActiveTab(WORK_TABS.DONEFORME)}
                  >
                    Work Being Done For Me
                  </li>
                </ul>
              </div>
              {activeTab === WORK_TABS.DONEFORME ? (
                <>
                  <>
                    <div
                      className={`justify-content-start my-3 ${styles.AllContract}`}
                    >
                      <div className="d-flex align-items-center mb-2">
                        <h4 className="form-check-label text-28 fw-bold mb-0 font-inter">
                          Contracts
                        </h4>
                        <i>
                          <img className="customeTolImg" src={info} alt="" />
                        </i>
                        <Toltip />
                      </div>
                      <OnGoingContracts />
                      <DraftPending />
                      <OpenContacts />
                      <Closecontract />
                      <CustomerRequest />
                      <div className="col-12">
                        <div className="topTipHover w-100">
                          <div className="w-100 d-flex align-items-center mt-4">
                            <h4 className="form-check-label text-20 fw-bold  mb-0 font-inter">
                              Direct In-House Jobs
                            </h4>
                            <i>
                              <img className="customeTolImg" src={info} alt="" />
                            </i>
                            <Toltip />
                          </div>
                        </div>
                      </div>
                      <OngoingDirectJob />
                      <DraftDirectJob />
                      <PendingAdminJob />
                      <OpenDirectJob />
                      <ClosedDirectJob />
                      <MyProfessionals />
                      <PastHiredProf />
                      <CustomerHired />
                      <AddCalendar />
                      <button
                        onClick={navigateToProfile}
                        className="btn btn-primary btn-sml mb-5 mt-3"
                      >
                        Go to My Profile
                      </button>
                    </div>
                  </>
                </>
              ) : (
                <>
                  <>
                    <div
                      className={`justify-content-start my-3 ${styles.AllContract}`}
                    >
                      <div className="d-flex align-items-center mb-2">
                        <h4 className="form-check-label text-28 fw-bold mb-0 font-inter">
                          Contracts
                        </h4>
                        <i>
                          <img className="customeTolImg" src={info} alt="" />
                        </i>
                        <Toltip />
                      </div>

                      <OnGoingContractsProfessional />
                      <JobOfferPending />
                      <MyApplicationToJobs />
                      <JobOfferRejected />
                      <CompletedClosedContact />
                      <div className="col-12">
                        <div className="topTipHover w-100">
                          <div className="w-100 d-flex align-items-center mt-4">
                            <h4 className="form-check-label text-20 fw-bold  mb-0 font-inter">
                              Direct In-House Jobs
                            </h4>
                            <i>
                              <img className="customeTolImg" src={info} alt="" />
                            </i>
                            <Toltip />
                          </div>
                        </div>
                      </div>
                      <OnGoingDirectJobProfessional />

                      {/* <PendingContracts /> */}
                      {/* <Shedulecontract /> */}
                    </div>
                  </>
                  {/* <Jobs /> */}
                  <JobOffersPending />
                  <MyApplicationToJobsDirect />
                  <JobOfferRejectedDirect />
                  <CompletedClosedJobsDirect />
                  <AddCalendar />
                  {/* <PendingApplication /> */}
                  {/* <Myteams /> */}
                  <button
                    onClick={navigateToProfile}
                    className="btn btn-primary btn-sml mb-5 mt-3"
                  >
                    Go to My Profile
                  </button>
                  {/* <EditProfile /> */}
                  {/* <ViewProfile /> */}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={`text-center ${styles.spinner}`}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
