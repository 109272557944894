import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { addEvents, getVideoLink } from "../../../Calendar/api";
import moment from "moment";
import { MESSAGES } from "../../../../utils/messages";
import { EVENT_TYPE } from "../../../../utils/enums/types";
import {
  Button,
  Dropdown,
  Modal,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  professional_id: string;
  work_id: string;
  occupation_name: string;
  professional_name: string;
  interviewScheduled: (data: boolean) => void;
}

const InterviewScheduleModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  professional_id,
  work_id,
  occupation_name,
  professional_name,
  interviewScheduled,
}) => {
  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState("");
  const [notes, setNotes] = useState("");
  const [videoLink, setVideoLink] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<any>("");
  const options = ["video", "phonecall", "inPerson"];

  const handleDateChange = (selectedDate: Date | null) => {
    setDate(selectedDate);
  };

  const handleSubmit = async () => {
    if (work_id && professional_id && date && time) {
      const combinedDateTime = moment(date)
        .set({
          hour: moment(time, "HH:mm").hour(),
          minute: moment(time, "HH:mm").minute(),
          second: 0,
          millisecond: 0,
        })
        .toDate();
      const payload = {
        workId: work_id,
        userId: professional_id,
        title: occupation_name,
        eventDate: moment(combinedDateTime).local().valueOf(),
        eventTime: moment(combinedDateTime).local().valueOf(),
        eventType: EVENT_TYPE.INTERVIEW,
        interviewType: selectedOption,
        videoLink: videoLink,
        additionalNotes: notes,
      };
      try {
        await toast.promise(addEvents(payload), {
          pending: MESSAGES.CALENDAR.PENDING,
          success: MESSAGES.CALENDAR.SUCCESS,
          error: MESSAGES.CALENDAR.ERROR,
        });
        onClose();
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Please fill in all fields");
    }
  };

  const onValueChange = (value: string): void => {
    setSelectedOption(value);
  };

  const handlGenerateLink = async () => {
    const response = await getVideoLink();
    setVideoLink(response?.data?.url);
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Request for Interview</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <div className="p-4 requestinterview">
            <div className="text-start mb-4">
              <p className="text-14 text-muted">
                A notification will be sent to the professional, and it will be
                <br /> added to both your and the professional's calendars.
              </p>
            </div>
            <div className="form-group mb-3">
              <label className="text-14">Interview Scheduling for:</label>
              <input
                type="text"
                className="form-control"
                placeholder={`${occupation_name}`}
                disabled
              />
            </div>
            <div className="form-group mb-3">
              <label className="text-14">Professional Name:</label>
              <input
                type="text"
                className="form-control"
                placeholder={`${professional_name}`}
                disabled
              />
            </div>
            <div className="form-group mb-3">
              <label className="text-14">Set Interview Date:</label>
              <DatePicker
                // className={styles.formGroup}
                selected={date}
                onChange={handleDateChange}
                placeholderText="Start Date"
                minDate={new Date()}
                dateFormat="MM-dd-YYYY"
                className="form-control"
              />
            </div>
            <div className="form-group mb-3">
              <label className="text-14">Set Interview Time:</label>
              <input
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                name="time"
                placeholder="Johns Yahi"
                className="form-control"
              />
            </div>
            <div className="form-group mb-3">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" name="travelTip">
                  {selectedOption || "Select Interview Type"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div
                    style={{
                      maxHeight: "300px",
                      overflowY: "scroll",
                    }}
                  >
                    {options.map((item) => (
                      <Dropdown.Item
                        key={item}
                        onClick={() => onValueChange(item)}
                      >
                        {item}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {selectedOption === "video" && (
              <div className="form-group mb-3">
                <label className="text-14">Add Video Conferencing:</label>
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    className="form-control w-75"
                    value={videoLink}
                    placeholder="Click on button to get link"
                    disabled
                  />
                  <div className="input-group-append w-25">
                    <button
                      className="btn btn-primary form-control w-100 px-0 text-14"
                      type="button"
                      onClick={handlGenerateLink}
                    >
                      Generate Link
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="form-group">
              <textarea
                name="notes"
                className="form-control"
               style={{minHeight: "100px"}}
                placeholder="Additional Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col">
            <Button
              type="button"
              className="btn text-14 bg-blue fw-semibold py-2"
              onClick={handleSubmit}
            >
              Send Request
            </Button>
          </div>
          <div className="col">
            <Button
              type="button"
              className="btn text-14 bg-white text-black py-2 fw-semibold"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default InterviewScheduleModal;
