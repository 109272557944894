export const API_ROUTES = {
  COMMON: {
    UPLOAD_FILE: "/common/upload",
    UPLOAD_POST: "/common/upload/post",
    PRIVACY_POLICY: "/common/privacy-policy",
    RTC_TOKEN_GENERATE: "common/rtc-token",
    JOIN_MEMBER_VALIDATION: "/user/business-Group-Member-Verify",
  },
  CHAT: {
    USER_CHAT: "/user/chats",
    BLOCK_CHAT_USER: "user/chats/block-unblock",

    REPORT_USER: "user/chats/report-user",
    // USER_AVAILABLE:""
  },
  EVENTS_CALENDAR: {
    ADD_EVENTS: "/user/event",
    GET_EVENTS: "/user/event",
    VIDEO_LINK: '/user/event/video/link',
    EVENT_LINK: '/common/event',
  },
  AUTH: {
    LOGIN: "auth/user/login",
    SIGNUP: "auth/user/signup",
    SEND_OTP: "/common/send-otp",
    VERIFY_OTP: "/common/verify-otp",
    RESET_PASSWORD: "auth/user/change-password",
    FORGOT_PASSWORD: "auth/user/forgot-password",
    RESEND_OTP_LOGIN: "common/resend-otp",
    LOGOUT_USER: "auth/user/logout",
    GET_USER: "auth/user/profile",
    SIGNUP_BUSINESS: "/user/business-Group-Member-Register",
  },

  MASTER: {
    OCCUPATION_LIST: "/user/master-public-occupation",
    CITIZENSHIPS: "/common/countries",
    GET_MASTERBYTYPE: "/user/master",
    POST_MASTERBYTYPE: "/user/master",
    COMPANIES_LIST: "user/business",
  },
  CONTRACTS: {
    CREATE_CONTRACT: "/user/work",
    WORK_UPDATE_BYID: "/user/work",
    OPEN_CONTRACTS: "/user/work",
    GET_WORKDETAILS: "/user/work",
    FILTER_CONTRACTS: "/user/work-apply",
    DOWNLOAD: "/user/work/xl",
    END_CONTRACT: "/user/work/end-job",
    UNAPPROVED_END_CONTRACT: "/user/work/unapproved/end-job",
  },
  JOBS: {
    SELECTEDJOB: "/user/work",
    REJECTJOB: "/user/work/reject-job",
    SELECTJOB_PROF: "/user/work/select-job",
  },
  SEND_FOR_APPROVAL: {
    SEND_APPROVAL: "user/work-sent-approve",
  },
  DIRECT_JOB: {
    GET_DIRECT_JOB: "user/work",
    COMPLETED_JOB_DETAILS: "user/work",
  },
  HOURLY_CONTRACTS: {
    GET_CONTRACTS: "/user/work",
  },
  FEED: {
    GET_FEED: "user/getFeed",
    GET_RECCOMENDED: "user/work-recommented",
    GET_PROFESSIONALS: "user",
    CONNECT_WITH_FRIENDS: "user/connection",
    POSTS: "user/post",
    ENDURE_FRIENDS: "/user/endorsement",
    GIVE_ENDURESMENT_FRIENDS: "/user/endorsement",
    MY_FRIENDS: "/user/connection/friends",
    CONNECT_REQUEST: "/user/connection/request",
    REQUEST: "user/connection/accept-reject",
  },
  WORK: {
    APPLY: "/user/work-apply",
    GET_WORKDETAILS_COUNTS: "user/work",
    CHANGE_WORK_STATUS: "user/work-apply",
  },
  PAYMENT_TERM: {
    GET_PAYMENT_TERMS: "user/paymentterms",
  },
  AGGREMENT: {
    SSA: "user/work-ssa",
    MSA: "user/work-msa",
  },
  TIMESHEETS: {
    GET_TIMESHEET: "user/timesheet",
    GET_TIMESHEET_DETAIL:'user/work-data-daily',
    GET_TIMESHEET_BY_ID: "user/timesheet",
    ADD_TIMESHEET: "user/timesheet",
    EDIT_TIMESHEET: "user/timesheet",
    CHANGE_STATUS: "user/timesheet/status-change/",
    SEND_FOR_APPROVAL: "user/timesheet/sent-for-approval",
  },
  EXPENSE: {
    GET_EXPENSE:'user/work-data-daily',
    EXPENSE: "user/expense",
    GET_EXPENSE_BY_ID: "user/expense",
    GET_INVOICE_BY_ID: "user/invoice",
    SEND_FOR_APPROVAL: "user/expense/sent-for-approval",
    CHANGE_STATUS: "user/expense/status-change/",
  },
  INVOICE: {
    GET_INVOICE: "user/invoice",
    GET_PROFESSIONAL:'user/invoice/information'
  },
  TOOTTIP: {
    GET_TOOTL_TIP: "user/helptext",
    GET_TOOTL_TIP_FILTER: "user/helptext-by-filter",
  },
  INTERVIEW_QUESTIONS: {
    GET_INTERVIEW_QUESTION_BYUSERID: "user/work/question-answer",
  },
  PROFILE: {
    PROFESSIONALS:'user/professionals',
    GET_ALL_USER_PROFILES: 'user',
    GET_USERPROFILE_BY_ID: 'user/profile',
    UPDATE_PROFILE: 'auth/user/profile',
    GET_USERPROFILE: 'auth/user/profile',
    DELETE: 'auth/user/delete',
  },
  VIDEO_CALL: {

  },
  INQUIRY: {
    INQUIRY: "user/websiteinquiries",
  },
  BOOST: {
    BOOST: "user/boost",
    PLAN: "user/boost/plan",
  },
  PAYMENT_STATUS: {
    STATUS: "user/payment/status",
  },
  RTC_TOKEN: {
    GENERATE_RTC_TOKEN: "common/rtc-token",
  },
  EVENT: {
    EVENTDETAILS: "user/event/request",
  },
  BUSINESS: {
    BUSINESS_GROUP: "user/business-Group",
    ADD_EMPLOYEE: "user/business-Group-Member-Add",
    ADD_BUSINESS: "user/business",
    GET_BUSINESS_MEMBER: "user/business-Group-Specific-Member",
    LISTINGTEAM: "user/business-Group-Member-Other",
  },
  PROFESSIONAL: {
    GET_PROF: "user/work/professional",
  },
  CONNECTION_PLAN: {
    PLANS: "user/connection/plans",
    SUBSCRIPTION: 'user/subscription/plan',
  },
};
