import React, { useEffect, useState } from "react";
import styles from "../../expense/styles/style.module.css"
import infogr from "../../../../assets/images/infogr.png"
import crtick from "../../../../assets/images/crtick.png"
import pnedit from "../../../../assets/images/pnedit.png"
import dlt from "../../../../assets/images/dlt.png"
import print from "../../../../assets/images/print.png"
import dnlod from "../../../../assets/images/dnlod.png"
import Header from "../../../header/routes";
import { useLocation, useParams } from "react-router-dom";
import { getTimesheetById } from "../api";
import { GetTimesheetByIdResponse, TimesheetDetail } from "../interface/getTimesheetById";
import {
  getMondayOfCurrentWeek,
  getWeekDaysMap,
} from "../../../../utils/daysMap";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import WhiteGloveContractDetails from "../../Contracts/whiteGloveHourlyContract/whileGloveHourlyContract";
import Toltip from "../../toltip/page";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { updateTimesheet } from "../api";

const TimesheetDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const ongoingContractsDetails = location?.state?.currentOngoingData
  const [currentOngoingData, setCurrentOngoingData] = useState<any>([])
  const [timeSheetStatusData, setTimesheetStatusData] = useState<GetTimesheetByIdResponse>();
  const searchParams = new URLSearchParams(location.search);
  const timesheetID = searchParams.get("timesheetID");
  const [editableTimesheetDetails, setEditableTimesheetDetails] = useState<TimesheetDetail[]>([]);

  console.log(timesheetID, "Timesheet ID");

  const approvedTimesheets = async () => {
    try {
      const response: any = await getTimesheetById(timesheetID || id);
      if (response?.data) {
        console.log("API Response:", response.data);
        setTimesheetStatusData(response.data);
        setEditableTimesheetDetails([...response.data.timesheetDetails]); 
      }
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    }
  };
  const handleInputChange = (index: number, field: keyof TimesheetDetail, value: any) => {
    const numericValue = field === "hourPerDay" || field === "travelTime" ? Number(value) || 0 : value;

    setEditableTimesheetDetails((prevDetails) => {
      const updatedDetails = prevDetails.map((entry, i) =>
        i === index
          ? {
            ...entry,
            [field]: numericValue,
            total:
              field === "hourPerDay"
                ? numericValue + (entry.travelTime ?? 0)
                : field === "travelTime"
                  ? numericValue + (entry.hourPerDay ?? 0)
                  : entry.total,
          }
          : entry
      );

      const totalStraightTime = updatedDetails.reduce(
        (sum, item) => sum + Number(item.hourPerDay ?? 0),
        0
      );

      let cappedStraightTime = totalStraightTime > 40 ? 40 : totalStraightTime;
      const totalOvertime = totalStraightTime > 40 ? totalStraightTime - 40 : 0;

      const totalTravelTime = updatedDetails.reduce(
        (sum, item) => sum + Number(item.travelTime ?? 0),
        0
      );
      const totalHours = updatedDetails.reduce(
        (sum, item) => sum + Number(item.total ?? 0),
        0
      );

      setTimesheetStatusData((prevData) => ({
        ...prevData!,
        totalStraightTime: cappedStraightTime,
        totalTravelTime,
        totalHours,
        totalOvertime,
      }));

      return updatedDetails;
    });
  };
  


  useEffect(() => {
    console.log("Updated editableTimesheetDetails:", editableTimesheetDetails);
  }, [editableTimesheetDetails]);
  useEffect(() => {
    approvedTimesheets();
    setCurrentOngoingData(ongoingContractsDetails)
  }, []);

  const handleUpdate = async () => {
    try {
      const updatedData: any = {
        workId: timeSheetStatusData?.workId,
        totalStraightTime: timeSheetStatusData?.totalStraightTime ?? 0,
        overTime: timeSheetStatusData?.totalOvertime ?? 0,
        doubleTime: timeSheetStatusData?.doubleTime ?? 0,
        totalTravelTime: timeSheetStatusData?.totalTravelTime ?? 0,
        totalHours: timeSheetStatusData?.totalHours ?? 0,
        ssaRates: {
          baseRate: timeSheetStatusData?.ssaRates.baseRate ?? 0,
          overTimeRate: timeSheetStatusData?.ssaRates.overTimeRate ?? 0,
          doubleTimeRate: timeSheetStatusData?.ssaRates.doubleTimeRate ?? 0,
          travelTimeAllowed: timeSheetStatusData?.ssaRates.travelTimeAllowed ?? 0,
        },
        status: timeSheetStatusData?.status ?? "pending",
        timesheetDetails: editableTimesheetDetails.map(detail => {
          const { _id, ...rest } = detail;
          return {
            ...rest,
            total: Number(detail.total),
            hourPerDay: String(detail.hourPerDay),
          };
        }),
      };

      await updateTimesheet(timesheetID || id, updatedData);
      toast.success("Timesheet updated successfully!");
    } catch (error) {
      toast.error("Failed to update timesheet!");
      console.error("Error updating timesheet:", error);
    }
  };


  const mondayDate = getMondayOfCurrentWeek();
  const daysMap = getWeekDaysMap();
  const isInvoiceDetailRoute = new RegExp(`^${APP_ROUTES.INVOICE_MAIN}/[a-fA-F0-9]{24}$`).test(location.pathname);
  const isInvoiceProfessionalDetailRoute = new RegExp(`^${APP_ROUTES.INVOICE_MAIN_PROFESSIONAL}/[a-fA-F0-9]{24}$`).test(location.pathname);

  return (
    <>
      {!isInvoiceDetailRoute && !isInvoiceProfessionalDetailRoute && <Header />}
      <div className={styles.pageOuter}>
        <div className="container">
          <div className="">
            <h4 className="fw-bold text-30 mb-4">
              Timesheet Details
            </h4>
            <div className={`rounded-4 ${styles.baseInfo} ${styles.noMarg}`}>
              {!isInvoiceDetailRoute && !isInvoiceProfessionalDetailRoute && <WhiteGloveContractDetails expenseDetails={timeSheetStatusData} ongoingContractDetails={currentOngoingData} />
              }              <div className={`rounded-3 col-12 ${styles.timeSheetdetils} ${styles.noMarg}`}>
                <div className="row align-items-center mb-3">
                  <div className="col-4 topTipHover">
                    <div className="w-100 d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                        Timesheet Details
                      </h4>
                      <i>
                        <img className="customeTolImg" src={infogr} alt="" />
                      </i>
                      <Toltip
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="d-flex align-items-center justify-content-end g-2">
                      {/* <div className="px-1"><button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'>View Signed Timesheet </button></div> */}
                      {/* <div className="px-1"><button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={dlt} alt="" /></i> Delete</button></div> */}
                      {/* <div className="px-1"><button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={crtick} alt="" /></i> Notify</button></div> */}
                      {/* <div className="px-1"> <button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={pnedit} alt="" /></i> Edit</button></div> */}
                      <div className="px-1"> <button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={print} alt="" /></i> Print</button></div>
                      <div className="px-1"> <button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={dnlod} alt="" /></i> Export Data</button></div>
                    </div>
                  </div>
                </div>

                <div className={`table-responsive ${styles.tabletimesheet}`}>

                  <table className="table align-middle">
                    <tr>
                      <td><span className="fw-semibold">Engineer:</span></td>
                      <td><span className="fw-normal">{timeSheetStatusData?.user?.name}</span></td>
                      <td colSpan={2} className=" text-center"><span className="fw-semibold">Monday / Week Start Date</span></td>

                    </tr>
                    <tr>
                      <td><span className="fw-semibold">Customer Company Name :</span></td>
                      <td><span className="fw-normal">{ }</span></td>
                      <td rowSpan={2} colSpan={2} className=" text-center"><span className="fw-normal">{mondayDate}</span></td>
                    </tr>
                    <tr>
                      <td><span className="fw-semibold">Business Number :</span></td>
                      <td><span className="fw-normal">{timeSheetStatusData?.work?.primaryContact?.mobileNumber}</span></td>
                    </tr>
                    <tr>
                      <td><span className="fw-semibold">Business Contact :</span></td>
                      <td><span className="fw-normal">{timeSheetStatusData?.work?.primaryContact?.name}</span></td>
                      <td><span className="fw-semibold">Business Email : </span></td>
                      <td><span className="fw-normal">{timeSheetStatusData?.work?.primaryContact?.email}</span></td>
                    </tr>
                    <tr>
                      <td><span className="fw-semibold">Contract Number :</span></td>
                      <td colSpan={4}><span className="fw-normal"> {timeSheetStatusData?.work?.workNumber}</span></td>

                    </tr>

                    <tr>
                      <td><span className="fw-semibold">End Customer :</span></td>
                      <td colSpan={4}><span className="fw-normal"></span></td>
                    </tr>

                  </table>

                </div>

                {/* Hours days functional table  */}
                <div className={`table-responsive table-striped ${styles.tabletimesheet}`}>

                  <table className="table align-middle mb-0">
                    <thead>
                      <th>
                        <span className="fw-normal">Day</span>
                      </th>
                      <th>
                        <span className="fw-normal">Date</span>
                      </th>
                      <th>
                        <span className="fw-normal">Hours Worked Per Day</span>
                      </th>
                      <th>
                        <span className="fw-normal">Holiday</span>
                      </th>
                      <th>
                        <span className="fw-normal">Travel Time Hours</span>
                      </th>
                      <th>
                        <span className="fw-normal"> Total</span>
                      </th>
                    </thead>
                    <tbody>
                      {editableTimesheetDetails.length > 0 ? (
                        editableTimesheetDetails.map((entry, index) => (
                          <tr key={entry._id}>
                            <td className="text-center">
                              <span className="fw-semibold">
                                {moment(entry.workedDate).format("dddd")} Work Report
                              </span>
                            </td>
                            <td>
                              <span className="fw-semibold">
                                {moment(entry.workedDate).format("MM-DD-YYYY")}
                              </span>
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                value={entry.hourPerDay ?? ""}
                                onChange={(e) => handleInputChange(index, "hourPerDay", e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                checked={entry.isHoliday ?? false}
                                onChange={(e) => handleInputChange(index, "isHoliday", e.target.checked)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                value={entry.travelTime ?? ""}
                                onChange={(e) => handleInputChange(index, "travelTime", e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                value={entry.total ?? ""}
                                readOnly
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">No timesheet data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                </div>
                <div className="row">
                  <div className="col-6">
                    <div className={`table-responsive ${styles.tabletimesheet}`}>
                      <table className="table align-middle">
                        <tr>
                          <td><span className="fw-medium">Total Straight Time</span></td>
                          <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.totalStraightTime}</span></td>
                        </tr>
                        <tr>
                          <td><span className="fw-medium">Total Over Time</span></td>
                          <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.overTime}</span></td>
                        </tr>
                        <tr>
                          <td><span className="fw-medium">Sunday/Holiday</span></td>
                          <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.doubleTime}</span></td>
                        </tr>
                        <tr>
                          <td><span className="fw-medium">Total Travel TIme</span></td>
                          <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.totalTravelTime}</span></td>
                        </tr>
                        <tr>
                          <td><span className="fw-medium">Total Hours</span></td>
                          <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.totalHours}</span></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={`table-responsive ${styles.tabletimesheet}`}>
                      <table className="table align-middle">
                        <tr>
                          <td><span className="fw-semibold">Bill Rates</span></td>
                          <td className="text-end"><span className="fw-normal"></span></td>
                        </tr>
                        <tr>
                          <td><span className="fw-medium">Base Rate (per hour)</span></td>
                          <td className="text-end"><span className="fw-normal"> ${timeSheetStatusData?.work?.rates?.baseRate}</span></td>
                        </tr>
                        <tr>
                          <td><span className="fw-medium">OT Rate (per hour)</span></td>
                          <td className="text-end"><span className="fw-normal">${timeSheetStatusData?.work?.rates?.overTimeRate}</span></td>
                        </tr>
                        <tr>
                          <td><span className="fw-medium">Sunday/Holiday</span></td>
                          <td className="text-end"><span className="fw-normal">${timeSheetStatusData?.work?.rates?.doubleTimeRate}</span></td>
                        </tr>
                        <tr>
                          <td><span className="fw-medium">Travel Time Rate</span></td>
                          <td className="text-end"><span className="fw-normal"> ${timeSheetStatusData?.work?.travels?.travelTimeRate }</span></td>
                        </tr>
                        <tr>
                          <td><span className="fw-medium">Travel Trips Allowed (per month)</span></td>
                          <td className="text-end"><span className="fw-normal">${timeSheetStatusData?.ssaRates?.travelTimeAllowed}</span>
                          </td>
                        </tr>
                        {/* <tr>
                          <td><span className="fw-medium">Travel Trips Allowed (per month)</span></td>
                          <td className="text-end"><span className="fw-normal"></span></td>
                        </tr> */}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="">
                  <h4 className="text-20 fw-semibold text-capitalized">Comment</h4>
                  <div className="">
                    <textarea className="form-control" style={{ height: '150px' }}>

                    </textarea>
                  </div>
                </div>
                <div className="mt-3 row g-2">
                  <div className="col-3">
                    <button className="bg-blue text-white p-2 rounded-2 text-14 px-4 w-100">
                      Upload Signed Timesheet
                    </button>
                  </div>
                  <div className="col-3">
                    <button className="bg-blue text-white p-2 rounded-2 text-14 px-4 w-100">
                      Print
                    </button>
                  </div>
                  {/* <div className="col-3">
                    <button
                      className="bg-blue text-white p-2 rounded-2 text-14 px-4 w-100"
                      onClick={handleUpdate}
                    >
                      Update Timesheet
                    </button>
                  </div> */}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimesheetDetails;
