import React, { useEffect, useState } from "react";
import styles from "../../css/styles/style.module.css";
import info from "../../../../assets/images/info_icon.svg";
import bag from "../../../../assets/images/bag_orange.svg";
import rejectedapp from "../../../../assets/images/rejectedapp.svg";
import offeraccepted from "../../../../assets/images/offeraccepted.svg";
import loc from "../../../../assets/images/icon_location_filled.svg";
import checkmark from "../../../../assets/images/checkmark.svg";
import star from "../../../../assets/images/star.svg";
import Slider from "react-slick";
import { CONTRACT_Type } from "../../../../utils/enums/types";
import moment from "moment";
import { TYPE, WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { WORK_TABS } from "../../../../utils/enums/workTabs.enum";
import { getContractList } from "../../Contracts/api";
import { ContractResponse } from "../../Contracts/interface";
import Toltip from "../../toltip/page";
import { endContractJob } from "../../../home/api";
import Swal from "sweetalert2";
import { APIResponseErrors } from "../../../work/interface";
import { JobResponseDirect } from "../../Contracts/interface/directjobInterface";
import { useNavigate } from "react-router-dom";
import { setActiveStep } from "../../../../redux/reducers/addDirectJob";
import { DIRECT_JOB_STEPS } from "../../../contractBeforeLogin/DirectJobType/AddDirectJob/Enums";
import storage from "../../../../utils/storage";
import { AddDirectJobRootState } from "../../../../models";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";

const OngoingDirectJob = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [onGoingJobs, setOngoingJobs] = useState<JobResponseDirect[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var ongoingdirectjob = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchOngoingContracts = async () => {
      setLoading(true);
      try {
        let response: ContractResponse | any;
        if (activeTab === WORK_TABS.DONEFORME) {
          response = await getContractList(
            CONTRACT_Type.DIRECT_JOB,
            TYPE.DONEFORME,
            WORK_STATUS_TYPE.ONGOING
          );
          console.log(response, "res");
        } else if (activeTab === WORK_TABS.DONEBYME) {
          response = await getContractList(
            CONTRACT_Type.DIRECT_JOB,
            TYPE.DONEBYME,
            WORK_STATUS_TYPE.ONGOING
          );
          console.log(response, "res");
        } else {
          response = { data: [] };
        }
        setOngoingJobs(response.data);
      } catch (error) {
        console.error("Error fetching ongoing contracts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOngoingContracts();
  }, [activeTab]);

  const _handleEndContractJob = async (id: string) => {
    try {
      const res = await endContractJob(id);
      Swal.fire({
        title: "Your request has been sent to Admin for Closing this Job!",
        // text: res.message as string,
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.log("Occuring error while ending the contract/job", error);
      const apiError = error as APIResponseErrors;
      const errorMessage = apiError?.data?.message as string;
      if (apiError.data.status == 400) {
        Swal.fire({
          html: `<span style="color: red;">${errorMessage}</span>`,
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "confirmBtn",
          },
          buttonsStyling: false,
        });
      } else {
        Swal.fire({
          title: "Unexpected Error",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const _handleCopyJob = (job: JobResponseDirect) => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.OVERVIEW_FORM));
    storage.saveDirectJob({
      ...AddDirectJobRootState.deserialize(job),
      activeStep: DIRECT_JOB_STEPS.OVERVIEW_FORM,
    });
    navigate(APP_ROUTES.ADD_DIRECT_JOBS);
  };

  return (
    <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
      <div className={`${styles.AllContracta}`}>
        <div className="row align-items-center justify-content-between mx-0 g-0">
          <div className="col-12">
            <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="form-check-label text-20 fw-bold  font-inter mb-0">
                  Ongoing Direct In-House Jobs
                </h4>
                <i>
                  <img className="customeTolImg" src={info} alt="" />
                </i>
                {onGoingJobs?.length > 0 ? '' : <span className="badge rounded-3 bg-lightblue text-black text-16 fw-semibold px-3 py-2 ms-2">0</span>}
                <Toltip />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {onGoingJobs?.length > 0 && onGoingJobs ? (
        <>
          {loading ? (
            <div className="mt-3">
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div>
              <div className={`mt-3 ${styles.OuterSlides}`}>
                <div className="col-12">
                  <div className="custom-ongoing">
                    <Slider {...settings}>
                      {onGoingJobs?.length > 0
                        ? onGoingJobs?.map((data: any, idx) => {
                            return (
                              <div
                                key={idx}
                                className={`mt-0 ${styles.ContarctOuter}`}
                              >
                                <div className={styles.ContarctHead}>
                                  <div className="row align-item-center justify-content-between mx-0 w-100 position-relative">
                                    <div className="col-xxl-7 col-8 mb-2">
                                      <div className={styles.leftContract}>
                                        <h5 className="clamp clamp1">
                                          {data?.occupation?.name}
                                        </h5>
                                        <span>
                                          Start Date:{" "}
                                          {moment(data.times.startDate).format(
                                            "MM-DD-YYYY"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-xxl-5 col-4 mb-2">
                                      <div
                                        className={styles.leftContract}
                                        style={{ textAlign: "end" }}
                                      >
                                        {/* <button>{data?.workStatus === WORK_STATUS_TYPE.ONGOING ? "Ongoing" : data.workStatus === WORK_STATUS_TYPE.APPROVED ? "Approved" : data.workStatus === WORK_STATUS_TYPE.OPEN ? "Open" : ""}</button> */}
                                        <button
                                          className={`position-absolute top-0 ${styles.statusdetails}`}
                                        >
                                          Ongoing
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-xxl-7 col-lg-12 col-12">
                                      <div className={styles.leftContract}>
                                        <div className={styles.Local}>
                                          <div
                                            className={`justify-content-start ${styles.adrssLoc}`}
                                          >
                                            <span
                                              className={`py-2 rounded-2 me-2 mb-0 border-lightorange bg-lightorange text-lightorange text-capitalize`}
                                            >
                                              <img
                                                src={bag}
                                                alt="White Glove Contract"
                                              />
                                              {data?.workType === "direct_job"
                                                ? "Standard Job"
                                                : ""}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-5 col-lg-12 col-12 mt-2 mt-xl-0">
                                      <div
                                        className={styles.leftContract}
                                        style={{ textAlign: "end" }}
                                      >
                                        <button
                                          className={`border-blue bg-blue text-white`} 
                                          onClick={() => _handleCopyJob(data)}
                                        >
                                          <i className="far fa-copy"></i> Copy
                                        </button>
                                        <button
                                          className={`bg-white text-danger border-danger text-capitalize`}
                                          onClick={() =>
                                            _handleEndContractJob(data?._id)
                                          }
                                        >
                                          close job
                                        </button>
                                        {/* <button>{data?.workStatus === WORK_STATUS_TYPE.ONGOING ? "Ongoing" : data.workStatus === WORK_STATUS_TYPE.APPROVED ? "Approved" : data.workStatus === WORK_STATUS_TYPE.OPEN ? "Open" : ""}</button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={styles.outerNameDetail}>
                                  <div className="row align-items-center">
                                    <div className="col-md-12">
                                      <div className={styles.outerNDetail}>
                                        <div className="row align-items-center">
                                          <div className="col-12">
                                            <div className={styles.PrileImgs}>
                                              <h4 className="fw-medium text-18 mb-3">
                                                Professionals Who Are Working
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <Slider {...ongoingdirectjob}>
                                        {data?.professionals &&
                                        data?.professionals?.length > 0
                                          ? data?.professionals?.map(
                                              (item: any, ind:any) => (
                                                <div key={ind} className="col">
                                                  <div className="row align-items-start">
                                                    <div className="col-md-3 pe-0">
                                                      <div
                                                        className={
                                                          styles.leftName
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            styles.PrileImg
                                                          }
                                                        >
                                                          <span className="text-center w-100">
                                                            <i>
                                                              <img
                                                                src={
                                                                  item?.userData
                                                                    ?.profilePicture
                                                                    ? item
                                                                        ?.userData
                                                                        ?.profilePicture
                                                                    : ""
                                                                }
                                                                alt=""
                                                                height={60}
                                                                width={60}
                                                                className="img-fluid w-100"
                                                              />
                                                            </i>
                                                            <h4 className="mt-2 mb-0 fw-semibold px-1 py-2 text-9 text-capitalize bg-lightblue text-blue rounded-pill">
                                                              view profile
                                                            </h4>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                      <div
                                                        className={
                                                          styles.leftName
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            styles.PrileImg
                                                          }
                                                        >
                                                          <div className="">
                                                            <h5 className="clamp clamp1">
                                                              {
                                                                item?.userData
                                                                  .name
                                                              }
                                                            </h5>

                                                            {/* <h6>{item?.occupationIds}</h6> */}
                                                            <p className="d-flex">
                                                              <img
                                                                src={loc}
                                                                alt=""
                                                              />{" "}
                                                              {
                                                                item?.country
                                                                  ?.name
                                                              }
                                                            </p>
                                                            <h6 className="d-flex">
                                                              Reviews: 20 | 4.5{" "}
                                                              <i>
                                                                <img
                                                                  src={star}
                                                                  alt=""
                                                                />
                                                              </i>
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )
                                          : null}
                                      </Slider>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`border-top ${styles.timeSheets}`}
                                >
                                  <div className="row g-3">
                                    <div className="col-lg-4 col-6">
                                      <div
                                        className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                      >
                                        <div className="col-2 mt-1">
                                          <i>
                                            <img
                                              src={checkmark}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </i>
                                        </div>
                                        <div className="col-10">
                                          <h6 className="fw-medium mb-1">
                                            Applications Received
                                          </h6>
                                          <h3 className="mb-0">
                                            {data?.receivedApplication}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                      <div
                                        className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                      >
                                        <div className="col-2 mt-1">
                                          <i>
                                            <img
                                              src={checkmark}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </i>
                                        </div>
                                        <div className="col-10">
                                          <h6 className="fw-medium mb-1">
                                            Shortlisted Applicants
                                          </h6>
                                          <h3 className="mb-0">
                                            {data?.shortlistedApplication}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                      <div
                                        className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                      >
                                        <div className="col-2 mt-1">
                                          <i>
                                            <img
                                              src={checkmark}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </i>
                                        </div>
                                        <div className="col-10">
                                          <h6 className="fw-medium mb-1">
                                            Offers Pending
                                          </h6>
                                          <h3 className="mb-0">
                                            {data?.pendingApplication}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                      <div
                                        className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                      >
                                        <div className="col-2 mt-1">
                                          <i>
                                            <img
                                              src={offeraccepted}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </i>
                                        </div>
                                        <div className="col-10">
                                          <h6 className="fw-medium mb-1">
                                            Offers Accepted
                                          </h6>
                                          <h3 className="mb-0">20</h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                      <div
                                        className={`d-flex align-items-start bg-lightred border-0 ${styles.timesheetData}`}
                                      >
                                        <div className="col-2 mt-1">
                                          <i>
                                            <img
                                              src={rejectedapp}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </i>
                                        </div>
                                        <div className="col-10">
                                          <h6 className="fw-medium mb-1">
                                            Rejected Applicants
                                          </h6>
                                          <h3 className="mb-0">
                                            {data?.rejectedApplication}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={`row align-items-center justify-content-between mt-3 ${styles.chatAdmin}`}
                                  >
                                    <div className="col-12">
                                      <button className="rounded-3 bg-blue text-white w-100 text-center fw-semibold p-2 text-14">
                                        View details
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {/* )} */}
                              </div>
                            );
                          })
                        : ""}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : ''}
    </div>
  );
};

export default OngoingDirectJob;
