import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, CalendarProps } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import "./calendar.module.style.css";
import styles from "../../components/common/css/styles/style.module.css";
import AddEvent from "./AddEvent";
import { getEvents, getInterviewData } from "./api";
import { Link } from "react-router-dom";
import GetAllTaskCalander from "./components/allTaskCalander";
import { TYPE } from "../../utils/enums/types";

const localizer = momentLocalizer(moment);

const AddCalendar = () => {
  const [allEvents, setAllEvents] = useState<
    { title: string; start: Date; end?: Date }[]
  >([]);
  const [getAllEventData, setAllEventData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [view, setView] = useState<string>("month");
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [allTask, setAllTask] = useState<string>("all");
  const [upcomingTask, setUpcomingTask] = useState<any>([]);
  const [completedTask, setCompletedTask] = useState<any>([]);
  const [allInterviews, setAllInterviews] = useState<any>([]);
  const [modal1, setModal1] = useState<boolean>(false);
  const [modal2, setModal2] = useState<boolean>(false);
  const [weekEvent, setWeekEvent] = useState([]);

  const openModal1 = () => {
    setModal2(false);
    setTimeout(() => {
      setModal1(true);
    }, 0);
  };

  const openModal2 = () => {
    setModal1(false);
    setTimeout(() => {
      setModal2(true);
    }, 0);
  };

  const closeModal1 = () => {
    setModal1(false);
    setModal2(false);
  };

  const closeModal2 = () => {
    setModal2(false);
    setModal1(false);
  };

  const handleViewChange = (newView: string) => {
    setView(newView);
  };

  const handleNavigate = (date: Date, view: string) => {
    setCurrentDate(date);
    setView(view);
  };

  const fetchEvents = async () => {
    try {
      const [calanderData, AllTasks, weekData] = await Promise.all([
        getEvents(TYPE.MONTH),
        getEvents(TYPE.ALL),
        getEvents(TYPE.WEEK),
      ]);
      if (calanderData?.data) {
        const formattedEvents = calanderData?.data?.map((event: any) => ({
          title: event?.title,
          start: moment.utc(event?.eventDate).local().toDate(),
          end: moment.utc(event?.eventDate).local().toDate(),
          status: event?.status,
        }));
        setAllEvents(formattedEvents);
      }
      if (AllTasks?.data?.length) setAllEventData(AllTasks?.data);
      if (weekData?.data) {
        const formattedWeekEvents = weekData?.data?.map((event: any) => ({
          title: event?.title,
          start: moment.utc(event?.eventDate).local().toDate(),
          end: moment.utc(event?.eventDate).local().toDate(),
          status: event?.status,
        }));
        setWeekEvent(formattedWeekEvents); // Store week events in a separate state
      }
    } catch (err) {
      setError("Failed to fetch events");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleAddEvents = (event: { title: string; start: Date }) => {
    const end = moment(event.start).add(1, "hour").toDate();
    setAllEvents([...allEvents, { ...event, end }]);
    fetchEvents();
  };

  useEffect(() => {
    handelTaskCalenderData("all");
  }, []);

  const handelTaskCalenderData = async (string: string) => {
    setAllTask(string);
    if (string === "all") {
      const response = await getInterviewData(TYPE.INTERVIEW, TYPE.ALL);
      setAllInterviews(response?.data);
    } else if (string === "upcoming") {
      const data = await getInterviewData(TYPE.INTERVIEW, TYPE.UPCOMING);
      setUpcomingTask(data?.data);
    } else if (string === "completed") {
      const data = await getInterviewData(TYPE.INTERVIEW, TYPE.COMPLETED);
      setCompletedTask(data?.data);
    }
  };

  const eventStyleGetter = (event: any) => {
    let style: React.CSSProperties = {};
    switch (event.status) {
      case "upcoming":
        style.backgroundColor = "#2880DA";
        break;
      case "completed":
        style.backgroundColor = "#FFD800";
        break;
      case "pending":
        style.backgroundColor = "#FF9E81";
        break;
    }
    return {
      style: style,
    };
  };

  return (
    <>
      <div className="calenderBlock px-0">
        <div className="container">
          <div className="calendertopBar">
            <h1>My Calendar</h1>
            <Button type="button" onClick={() => openModal1()}>
              <i className="fas fa-plus"></i> Add Events
            </Button>
          </div>
          <div className="grid">
            <div className="left">
              <Calendar
                localizer={localizer}
                events={allEvents}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={eventStyleGetter}
                style={{ height: 700 }}
                onView={handleViewChange}
                onNavigate={handleNavigate}
              />
            </div>
            <div className="right">
              <div className="rightBar">
                <div className="informationItem">
                  <h5>Activity Information</h5>
                  <ul>
                    <li>Upcoming Task</li>
                    <li>Pending Task</li>
                    <li>Completed Task </li>
                  </ul>
                </div>
                <div className="recentItem">
                  <h5>Recent Activity</h5>
                  {weekEvent
                    ? weekEvent?.map((data: any, ind: number) => (
                        <div key={ind} className="item">
                          <div className="image">
                            <span></span>
                          </div>
                          <div className="text">
                            <h6>{data?.title}</h6>
                            <p>
                              {data?.additionalNotes}{" "} 
                              {moment
                                .utc(data?.eventDate)
                                .local()
                                .format("DD MMM YYYY")}
                            </p>
                            <p>
                              {moment
                                .utc(data?.eventDate)
                                .local()
                                .format("hh: mm A")}
                            </p>
                          </div>
                        </div>
                      ))
                    : "No Recent Events Added Yet!"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddEvent
        openModal2={openModal2}
        closeModal1={closeModal1}
        closeModal2={closeModal2}
        modal1={modal1}
        modal2={modal2}
        addEvent={handleAddEvents}
      />

      <div className={styles.detailsInfo}>
        <div className="container">
          <div className={styles.headingBar}>
            <div className="">
              <ul>
                <li className={allTask === "all" ? styles.activeList : ""}>
                  <Link to="" onClick={() => handelTaskCalenderData("all")}>
                    All Interviews
                  </Link>
                </li>
                <li className={allTask === "upcoming" ? styles.activeList : ""}>
                  <Link
                    to=""
                    onClick={() => handelTaskCalenderData("upcoming")}
                  >
                    Upcoming
                  </Link>
                </li>
                <li
                  className={allTask === "completed" ? styles.activeList : ""}
                >
                  <Link
                    to=""
                    onClick={() => handelTaskCalenderData("completed")}
                  >
                    Completed
                  </Link>
                </li>
              </ul>
            </div>
            <div className="">
              <button>
                <i className="fas fa-upload"></i> Export
              </button>
            </div>
          </div>
          <div className="row">
            {(allTask === "all" && (
              <GetAllTaskCalander getAllEventData={allInterviews} />
            )) ||
              (allTask === "upcoming" && (
                <GetAllTaskCalander getAllEventData={upcomingTask} />
              )) ||
              (allTask === "completed" && (
                <GetAllTaskCalander getAllEventData={completedTask} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCalendar;
