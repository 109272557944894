import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styles from "../../css/styles/style.module.css";
import { ContractResponse } from "../interface";
import moment from "moment";
import bags from "../../../../assets/images/bags2.png";
import loc from "../../../../assets/images/icon_location_filled.svg";
import checkmark from "../../../../assets/images/checkmark.svg";
import rejectedapp from "../../../../assets/images/rejectedapp.svg";
import offeraccepted from "../../../../assets/images/offeraccepted.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { WORK_TABS } from "../../../../utils/enums/workTabs.enum";
import { getOngoingContractList } from "../OpenContracts/api";
import { CONTRACT_Type, PLAN_TYPE } from "../../../../utils/enums/types";
import { TYPE, WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import bag from "../../../../assets/images/grbag.png";
import Swal from "sweetalert2";
import { endContractJob } from "../../../home/api";
import { APIResponseErrors } from "../../../work/interface";
import { setActiveStep } from "../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../shared/enums";
import storage from "../../../../utils/storage";
import { AddContractRootState } from "../../../../models";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";

const OngoingStandard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [onGoingContracts, setOngoingContracts] = useState<ContractResponse[]>(
    []
  );
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  console.log(dashboardData, "dashboard data ");
  const { activeTab } = dashboardData;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchOngoingContracts = async () => {
      setLoading(true);
      try {
        let response: ContractResponse | any;
        if (activeTab === WORK_TABS.DONEFORME) {
          response = await getOngoingContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            WORK_STATUS_TYPE.ONGOING,
            PLAN_TYPE.STANDARD
          );
          console.log(response, "res");
        } else {
          response = { data: [] };
        }
        setOngoingContracts(response.data);
      } catch (error) {
        console.error("Error fetching ongoing contracts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOngoingContracts();
  }, [activeTab]);

  const _handleCopy = (contract: ContractResponse) => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS?.OVERVIEW_FORM));
    storage.saveContract({
      ...AddContractRootState?.deserialize(contract),
      activeStep: ADD_CONTRACT_STEPS?.OVERVIEW_FORM,
    });
    navigate(APP_ROUTES?.ADD_HOURLY_CONTRACT);
  };

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const _handleEndContractJob = async (id: string) => {
    try {
      const res = await endContractJob(id);
      Swal.fire({
        title: "Your request has been sent to Admin for Ending this Contract!",
        // text: res.message as string,
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.log("Occuring error while ending the contract/job", error);
      const apiError = error as APIResponseErrors;
      const errorMessage = apiError?.data?.message as string;
      if (apiError.data.status == 400) {
        Swal.fire({
          html: `<span style="color: red;">${errorMessage}</span>`,
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "confirmBtn",
          },
          buttonsStyling: false,
        });
      } else {
        Swal.fire({
          title: "Unexpected Error",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <div
      className="tab-pane fade"
      id="nav-profile"
      role="tabpanel"
      aria-labelledby="nav-profile-tab"
    >
      {onGoingContracts?.length > 0 ? (
        <div className={`${styles.OuterSlides}`}>
          <Slider {...settings}>
            {onGoingContracts?.length > 0
              ? onGoingContracts?.map((data: ContractResponse, idx) => {
                return (
                  <div key={idx} className={`mt-0 ${styles.ContarctOuter}`}>
                    <div className={styles.ContarctHead}>
                      <div className="row align-item-center justify-content-between mx-0 w-100 position-relative">
                        <div className="col-xl-7 col-lg-12 col-md-5 col-12">
                          <div className={styles.leftContract}>
                            <h5>{data?.occupation?.name}</h5>
                            <span>
                              Start Date :
                              {moment(data.times.startDate).format(
                                "MM-DD-YYYY"
                              )}
                            </span>
                            {" | "}
                            <span>
                              End Date :
                              {moment(data.times.endDate).format(
                                "MM-DD-YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-5 col-lg-12 col-md-7 col-12 mb-2">
                          <div
                            className={styles.leftContract}
                            style={{ textAlign: "end" }}
                          >
                            <button
                              className={`position-absolute top-0 ${styles.statusdetails}`}
                            >
                              Ongoing
                            </button>
                          </div>
                        </div>
                        <div className="col-xxl-7 col-lg-12 col-12">
                          <div className={styles.leftContract}>
                            <div className={styles.Local}>
                              <div
                                className={`justify-content-start ${styles.adrssLoc}`}
                              >
                                <span
                                  className={`py-2 mb-0 rounded-2 me-2  border-lightgreen bg-lightgreen lh-base`}
                                >
                                  <img src={bag} alt="White Glove Contract" />
                                  {data.planType === "whiteglove"
                                    ? "White Glove Contract"
                                    : data.planType === "standard"
                                      ? "Standard Contract"
                                      : ""}
                                </span>
                                {data?.workLocation ? (
                                  <span
                                    className={`py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base`}
                                  >
                                    {data?.workLocation === "Remote"
                                      ? "Remote Contract"
                                      : data?.workLocation === "Onsite"
                                        ? "Onsite Contract"
                                        : data?.workLocation === "Hybrid"
                                          ? "Hybrid Contract"
                                          : "Unknown Location"}
                                  </span>
                                ) : (
                                  <span className="py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base">
                                    No Work Location
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-5 col-lg-12 col-md-7 col-12 text-end mt-2">
                          <div
                            className={styles.leftContract}
                            style={{ textAlign: "end" }}
                          >
                            <button className={styles.CoptText} onClick={() => _handleCopy(data)}>
                              <i className="far fa-copy"></i> Copy
                            </button>
                            {/* <button>{data?.workStatus === WORK_STATUS_TYPE.ONGOING ? "Ongoing" : data.workStatus === WORK_STATUS_TYPE.APPROVED ? "Approved" : data.workStatus === WORK_STATUS_TYPE.OPEN ? "Open" : ""}</button> */}
                            <button
                              onClick={() => _handleEndContractJob(data?._id)}
                            >
                              End Contract
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row p-3 g-3">
                      <div className="col-lg-4 col-6">
                        <div
                          className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                        >
                          <div className="col-2 mt-1">
                            <i>
                              <img
                                src={checkmark}
                                alt=""
                                className="img-fluid"
                              />
                            </i>
                          </div>
                          <div className="col-10">
                            <h6 className="fw-medium mb-1">
                              Applications Received
                            </h6>
                            <h3 className="mb-0">
                              {data?.receivedApplication || 0}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-6">
                        <div
                          className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                        >
                          <div className="col-2 mt-1">
                            <i>
                              <img
                                src={checkmark}
                                alt=""
                                className="img-fluid"
                              />
                            </i>
                          </div>
                          <div className="col-10">
                            <h6 className="fw-medium mb-1">
                              Shortlisted Applicants
                            </h6>
                            <h3 className="mb-0">
                              {data?.shortlistedApplication || 0}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-6">
                        <div
                          className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                        >
                          <div className="col-2 mt-1">
                            <i>
                              <img
                                src={checkmark}
                                alt=""
                                className="img-fluid"
                              />
                            </i>
                          </div>
                          <div className="col-10">
                            <h6 className="fw-medium mb-1">Offers Pending</h6>
                            <h3 className="mb-0">
                              {data?.pendingApplication || 0}
                            </h3>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-6">
                        <div
                          className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                        >
                          <div className="col-2 mt-1">
                            <i>
                              <img
                                src={offeraccepted}
                                alt=""
                                className="img-fluid"
                              />
                            </i>
                          </div>
                          <div className="col-10">
                            <h6 className="fw-medium mb-1">
                              Offers Accepted
                            </h6>
                            <h3 className="mb-0">20</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-6">
                        <div
                          className={`d-flex align-items-start bg-lightred border-0 ${styles.timesheetData}`}
                        >
                          <div className="col-2 mt-1">
                            <i>
                              <img
                                src={rejectedapp}
                                alt=""
                                className="img-fluid"
                              />
                            </i>
                          </div>
                          <div className="col-10">
                            <h6 className="fw-medium mb-1">
                              Rejected Applicants
                            </h6>
                            <h3 className="mb-0">
                              {data?.rejectedApplication || 0}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {data.planType === "whiteglove" &&
                            <div className={styles.timeSheets}>
                             
                              {data?.ownWork ? (
                                <>
                                  <div className="text-center py-3">
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `${APP_ROUTES.SSAAGGREMENT}/${data?._id}`
                                        )
                                      }
                                      className="btn btn-primary w-50 mx-auto"
                                    >
                                      View SSA
                                    </button>
                                  </div>

                                </>
                              ) : (
                                <div className='row'>
                                <div className='col-6'>
                                  <div className="text-center py-3">
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `${APP_ROUTES.MSAAGGREMENT}/${data._id}/${data.userInfo._id}`
                                        )
                                      }
                                      className="btn btn-primary w-100 mx-auto"
                                    >
                                      View MSA
                                    </button>
                                  </div>
                                  </div>
                                  <div className='col-6'>
                                  <div className="text-center py-3">
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `${APP_ROUTES.ONGOING_CONTRACT_DETAIL}/${data?._id}`,
                                          {
                                            state: { professionalFlag: true,data },
                                          }
                                        )
                                      }
                                      className="btn btn-primary w-100 mx-auto"
                                    >
                                      Track Progress
                                    </button>
                                  </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          } */}
                  </div>
                );
              })
              : ""}
          </Slider>
        </div>
      ) : (
        'No Data'
      )}
    </div>
  );
};

export default OngoingStandard;
