import { axios } from "../../../../lib/axios";
import { API_ROUTES } from "../../../../utils/routes/apiRoutes";
import { ChnageStatusTimesheet } from "../../timesheets/interface";
import { ExpensePayload } from "../interface";

export const getExpense = async (workId: any, type: string): Promise<any> => {
    return axios.get(`${API_ROUTES.EXPENSE.EXPENSE}?workId=${workId}&type=${type}`);
};
export const getExpenseDetail = async (type: string, workId: any): Promise<any> => {
    return axios.get(`${API_ROUTES.EXPENSE.GET_EXPENSE}?type=${type}&workId=${workId}`);
};

export const getexpenseById = async (id: any): Promise<any> => {
    return axios.get(`${API_ROUTES.EXPENSE.GET_EXPENSE_BY_ID}/${id}`);
};
export const addexpense = async (payload: any): Promise<any> => {
    return axios.post(API_ROUTES.EXPENSE.EXPENSE, payload);
};
export const addexpenseEdit = async (id: string, payload: any): Promise<any> => {
    return axios.put(`${API_ROUTES.EXPENSE.EXPENSE}/${id}`, payload);
  };
  export const sentForAppovalExpense = async (id: string, payload: any): Promise<any> => {
    return axios.put(`${API_ROUTES.EXPENSE.SEND_FOR_APPROVAL}/${id}`, payload);
  };

export const updateExpense = async (payload: any): Promise<any> => {
    return axios.post(API_ROUTES.EXPENSE.EXPENSE, payload);
};
export const expenseSendforApproval = (expenseId: string,) => {
    return axios.put(`${API_ROUTES.EXPENSE.SEND_FOR_APPROVAL}/${expenseId}`,)
  }
  export const changeExpenseStatus = async (id: any, status: any,reason:any): Promise<ChnageStatusTimesheet> => {
    return axios.put(`${API_ROUTES.EXPENSE.CHANGE_STATUS}${id}`, { status,reason });
  };
  export const getInvoice = async (workId: any, type: string,isBusiness:boolean): Promise<any> => {
    return axios.get(`${API_ROUTES.INVOICE.GET_INVOICE}?workId=${workId}&type=${type}&isBusiness=${isBusiness}`);
};

export const getInvoiceById = async (id: any): Promise<any> => {
    return axios.get(`${API_ROUTES.EXPENSE.GET_INVOICE_BY_ID}/${id}`);
};

export const invoiceProfessional = async (id: any, type: any): Promise<any> => {
    return axios.get(
      `${API_ROUTES.INVOICE.GET_PROFESSIONAL}/${id}?isBusiness=false&invoiceType=${type}`
    );
  };
  
export const invoiceClient = async (id: any): Promise<any> => {
    return axios.get(`${API_ROUTES.INVOICE.GET_PROFESSIONAL}/${id}?isBusiness=true`);
};
