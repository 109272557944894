import React, { useEffect, useState } from "react";
import styles from "../styles/sigup.module.css";
import { useNavigate } from "react-router-dom";
import left from "../../../../assets/images/left.png";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setOccupation } from "../../../../redux/reducers/auth";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import { getMasterTypes } from "../api";
import { MASTER_TYPE } from "../../../../utils/enums/masterTypes.enum";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import whitearrow from '../../../../assets/images/whitearrow.svg';
import { occupationDropdownSchema } from "../validations";
import { RootState } from "../../../../redux/store";
import storage from "../../../../utils/storage";
import { toast } from "react-toastify";
import { postMasterType } from "../../../../components/work/api";
import { MESSAGES } from "../../../../utils/messages";
import { APIResponseError } from "../interface";
import CreatableSelect from "react-select/creatable";


interface OptionType {
  value: string; 
  label: string; 
}

interface OccupationResponse {
  _id: string; 
  name: string; 
}

const Occupation: React.FC = () => {
  const token = storage.getToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [occupationList, setOccupationList] = useState<OptionType[]>([]);
  const {occupationData} = useSelector((state: RootState) => state.userData);

  console.log(occupationData, 'userDetails');
  

  const getoccupationData = async () => {
    try {
      const response = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
      const formattedOccupations: OptionType[] = response.data.map((occupation: OccupationResponse) => ({
        value: occupation?._id,
        label: occupation?.name,
      }));
      setOccupationList(formattedOccupations);
    } catch (error) {
      console.error('Error fetching occupation data:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedOccupation: occupationData?.occupation.map((occ: any) => ({
        value: occ?._id, 
        label: occ?.name, 
      })) || [],
      selectedOccupationName: occupationData?.occupationName || "",
    },
    validationSchema: occupationDropdownSchema,
    onSubmit: (values) => {
      const occupationData = {
        occupation: values.selectedOccupation.map((occ: OptionType) => occ.value), // Extracting only the value (IDs)
        occupationName: values.selectedOccupationName,
      };
      console.log(occupationData,"testt")
      dispatch(setOccupation(occupationData));
      navigate(AUTH_ROUTES.CITIZENSHIP);
    },
  });

  useEffect(() => {
    getoccupationData();
  }, []);

  const handleCreateOccupation = async (newOccupationName: string) => {
    const payload = {
      type: MASTER_TYPE.OCCUPATIONS,
      name: newOccupationName,
      alias: newOccupationName,
    };

    toast.promise(postMasterType(payload), {
      pending: {
        render() {
          return MESSAGES.POSTING_CONTRACTS.VERIFYING;
        },
      },
      success: {
        render(newOccupation) {
          const newId = newOccupation?.data?.data?._id || newOccupationName;
          const newOccupationObj: OptionType = { value: newId, label: newOccupationName };

          const updatedOccupations = [...formik.values.selectedOccupation, newOccupationObj];
          formik.setFieldValue("selectedOccupation", updatedOccupations);
          formik.setFieldValue("selectedOccupationName", newOccupationName);

          const occupationValues = updatedOccupations.map((occ) => occ.value);
          dispatch(
            setOccupation({
              occupation: occupationValues,
              occupationName: newOccupationName,
            })
          );
          return MESSAGES.POSTING_CONTRACTS.ADDING;
        },
      },
      error: {
        render(error) {
          let errorMessage = error as APIResponseError;
          return errorMessage?.data?.data?.message;
        },
      },
    });
  };

  console.log(formik.values.selectedOccupation?.[0]?.label, 'formik values');
  

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll ">
        <div className={styles.occupationdetails}>
          <div className={styles.signuppage}>
            <div className={styles.signUPContainer} style={{ padding: "15px" }}>
              <form onSubmit={formik.handleSubmit}>
                <h5> Primary Occupation</h5>
                <span className={styles.Leftntm}>
                  <a href="#">
                    <img src={left} alt="" onClick={() => navigate(AUTH_ROUTES.PASSWORD)} />
                  </a>
                </span>
                <p>Select occupation</p>
                <div className={styles.fromGroup}>
                  <CreatableSelect
                    name="dropdown-basic"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onCreateOption={handleCreateOccupation}
                    options={occupationList}
                    onChange={(option) => {
                      const updatedOccupations = Array.isArray(option) ? option : [option];
                      formik.setFieldValue("selectedOccupation", updatedOccupations);
                      formik.setFieldValue(
                        "selectedOccupationName",
                        updatedOccupations.map((occ) => occ.label).join(", ")
                      );
                    }}
                    value={formik.values.selectedOccupation} 
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                  />
                  {formik.touched.selectedOccupation && formik.errors.selectedOccupation && (
                    <div className={styles.error}>
                      {Array.isArray(formik.errors.selectedOccupation)
                        ? formik.errors.selectedOccupation.map((error, index) => (
                          typeof error === 'string' ? (
                            <div key={index}>{error}</div>
                          ) : null 
                        ))
                        : typeof formik.errors.selectedOccupation === 'string' ? (
                          <div>{formik.errors.selectedOccupation}</div>
                        ) : null 
                      }
                    </div>
                  )}
                </div>

                <div className="col-lg-3 col-6 mx-auto">
                  <div className="text-center my-3 btn-hover-effect border rounded-3">
                    <button
                      type="submit"
                      className="btn bg-white arrowblue border-white border-1 position-relative w-100 pe-5 text-center"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold w-100">Next</span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                      </i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Occupation;
