import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../css/styles/style.module.css";
import cal from "../../../../assets/images/calender_icon.svg";
import loc from "../../../../assets/images/icon_location.svg";
import skill from "../../../../assets/images/top_skill.svg";
import grbag from "../../../../assets/images/bag_orange.svg";
import chatic from "../../../../assets/images/chatic.png";
import Slider from "react-slick";
import moment from "moment";
import { ContractResponse } from "../../Contracts/interface";
import { getContractList } from "../../Contracts/api";
import { TYPE, WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import { CONTRACT_Type } from "../../../../utils/enums/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { WORK_TABS } from "../../../../utils/enums/workTabs.enum";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import ChatModule from "../../../ChatModule/routes";
import Toltip from "../../toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import tip from "../../../../../src/assets/images/info_icon.svg";
import DateTime from "../../../Datetimefilter/routes";
import SortFilter from "../../../Sortfilter/routes";
import CustomModal from "../../../../shared/CustomModal/customModal";
import BoostContract from "../../../../shared/CustomModal/BoostContractModal";
import { APIResponseErrors } from "../../../work/interface";
import Swal from "sweetalert2";
import { unApprovedEndContractJob } from "../../../home/api";
import { JobResponseDirect } from "../../Contracts/interface/directjobInterface";
import { setActiveStep } from "../../../../redux/reducers/addDirectJob";
import { DIRECT_JOB_STEPS } from "../../../contractBeforeLogin/DirectJobType/AddDirectJob/Enums";
import storage from "../../../../utils/storage";
import { AddDirectJobRootState } from "../../../../models";

const PendingAdminJob = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [isExpanded, setIsExpanded] = useState(false);
  const [pendingContarct, setPendingContract] = useState<ContractResponse[]>(
    []
  );
  const [showChatModal, setShowChatmodal] = useState<boolean>(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteCont, setDeleteCont] = useState<boolean>(false);
  const [workId, setWorkId] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2.4,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };

  const fetchContracts = async () => {
    setLoading(true);
    try {
      let response: any;
      if (activeTab === WORK_TABS.DONEFORME) {
        response = await getContractList(
          CONTRACT_Type.DIRECT_JOB,
          TYPE.DONEFORME,
          WORK_STATUS_TYPE.PENDING
        );
      } else if (activeTab === WORK_TABS.DONEBYME) {
        response = await getContractList(
          CONTRACT_Type.DIRECT_JOB,
          TYPE.DONEBYME,
          WORK_STATUS_TYPE.PENDING
        );
      } else {
        response = { data: [] };
      }
      setPendingContract(response.data);
    } catch (error) {
      console.error("Error fetching contracts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContracts();
  }, [activeTab]);

  const handleOpenAdminChat = () => {
    setShowChatmodal(true);
  };

  const _handleBoostJob = (id: string) => {
    setWorkId(id);
    setModalDetail({
      show: true,
      title: "",
      flag: "boostContract",
    });
  };

  const _handleEndContractJob = async (id: string) => {
    const result = await Swal.fire({
      title: "Are you sure, you want to delete this job?",
      // text: "This job will be sent to Admin for deletion. Do you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      customClass: {
        confirmButton: "confirmBtn",
        cancelButton: "cancelBtn",
      },
      buttonsStyling: false,
    });
    if (result.isConfirmed) {
      setLoading(true); // Start loading before making the API call
      try {
        const res = await unApprovedEndContractJob(id);
        Swal.fire({
          title: "Your Job Has Been Deleted!",
          icon: "success",
          confirmButtonText: "OK",
        });
        setDeleteCont((prevState) => !prevState);
      } catch (error) {
        console.log("Error while ending the contract/job", error);
        const apiError = error as APIResponseErrors;
        const errorMessage = apiError?.data?.message as string;
        if (apiError.data.status == 400) {
          Swal.fire({
            html: `<span style="color: red;">${errorMessage}</span>`,
            icon: "error",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "confirmBtn",
            },
            buttonsStyling: false,
          });
        } else {
          Swal.fire({
            title: "Unexpected Error",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } finally {
        setLoading(false); // Stop loading after the operation is complete
      }
    }
  };

  const _handleCopyJob = (job: JobResponseDirect) => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.OVERVIEW_FORM));
    storage.saveDirectJob({
      ...AddDirectJobRootState.deserialize(job),
      activeStep: DIRECT_JOB_STEPS.OVERVIEW_FORM,
    });
    navigate(APP_ROUTES.ADD_DIRECT_JOBS);
  };

  useEffect(() => {
    fetchContracts();
  }, [deleteCont]);

  return (
    <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
      <div className={`${styles.AllContracta}`}>
        <div className="row align-items-center justify-content-between mx-0 g-0">
          <div className="col-12">
            <div className="topTipHover w-100">
              <div className="d-flex align-items-center">
                <h4 className="form-check-label text-20 fw-bold  font-inter mb-0">
                  Direct In-House Jobs Pending Admin Approval
                </h4>
                <i>
                  <img className="customeTolImg" src={tip} alt="" />
                </i>
                {pendingContarct?.length > 0 ? '' : <span className="badge rounded-3 bg-lightblue text-black text-16 fw-semibold px-3 py-2 ms-2">0</span>}
                <Toltip
                  slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                  labelId={CONSTANT_MANAGER.HOME_DATA.MY_TEAMS}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {pendingContarct?.length > 0 && pendingContarct ? (
        <>
          {loading ? (
            <div className="mt-3">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            </div>
          ) : (
            <div className={`mt-3 ${styles.OuterSlides}`}>
              <div className="col-12">
                <div className="custom-ongoing">
                  <Slider {...settings}>
                    {pendingContarct?.length > 0
                      ? pendingContarct?.map((data: any, idx) => {
                          return (
                            <>
                              <div key={idx} className={styles.ContarctOuter}>
                                <div className={styles.ContarctHead}>
                                  <div className="row align-item-center justify-content-between mx-0 w-100 position-relative">
                                    <div className="col-xl-7 col-lg-12 col-md-5 col-12 mb-2">
                                      <div className={styles.leftContract}>
                                        <h5>{data?.occupation?.name}</h5>
                                        <span>
                                          Start Date:{" "}
                                          {moment(data.times.startDate).format(
                                            "MM-DD-YYYY"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-12 col-md-7 col-12 mb-2">
                                      <div
                                        className={styles.leftContract}
                                        style={{ textAlign: "end" }}
                                      >
                                        <button
                                          className={`position-absolute top-0 bg-lightorange text-lightorange ${styles.draftstatusdetails}`}
                                        >
                                          Pending
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-xl-7 col-lg-12 col-12">
                                      <div className={styles.leftContract}>
                                        <div className={styles.Local}>
                                          <div
                                            className={`justify-content-start ${styles.adrssLoc}`}
                                          >
                                            <span
                                              className={`py-2 rounded-2 me-2 mb-0 border-lightorange text-lightorange bg-lightorange`}
                                            >
                                              <img src={grbag} alt="" />
                                              {data?.workType === "direct_job"
                                                ? "Standard Job"
                                                : ""}
                                            </span>
                                            <span
                                              className={`py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base`}
                                            >
                                              {data?.workLocation === "Remote"
                                                ? "Remote Job"
                                                : data?.workLocation ===
                                                  "Onsite"
                                                ? "Onsite Job"
                                                : data?.workLocation ===
                                                  "Hybrid"
                                                ? "Hybrid Job"
                                                : "Unknown Location"}
                                            </span>
                                            {/* {data?.workLocation ? (
                                            <span
                                              className={`py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base`}
                                            >
                                              {data?.workLocation === "Remote"
                                                ? "Remote Contract"
                                                : data?.workLocation ===
                                                  "Onsite"
                                                ? "Onsite Contract"
                                                : data?.workLocation ===
                                                  "Hybrid"
                                                ? "Hybrid Contract"
                                                : "Unknown Location"}
                                            </span>
                                          ) : (
                                            <span
                                              className={`py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base`}
                                            >
                                              No Work Location
                                            </span>
                                          )} */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-12 col-12 mt-lg-0 mt-2">
                                      <div
                                        className={styles.leftContract}
                                        style={{ textAlign: "end" }}
                                      >
                                        <button
                                          className={`border-blue bg-blue text-white`} 
                                          onClick={() => _handleCopyJob(data)}
                                        >
                                          <i className="far fa-copy"></i> Copy
                                        </button>
                                        <button
                                          className={`bg-white text-danger border-danger ${styles.deletecontracts}`}
                                          onClick={() =>
                                            _handleEndContractJob(data?._id)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={styles.OuterPadd}>
                                  <div className={styles.PendingCOnatctList}>
                                    <div className={styles.Local}>
                                      <div className={styles.adrssLoc}>
                                        <p className="mb-1 text-13">
                                          <img src={loc} alt="" />{" "}
                                          {data?.address}
                                        </p>
                                      </div>
                                      <p className="text-13">
                                        <img src={cal} alt="" /> Sent For
                                        Approval:{" "}
                                        {moment(data.times.startDate).format(
                                          "MM-DD-YYYY"
                                        )}
                                      </p>
                                    </div>
                                    <div
                                      className="fw-semibold clamp clamp1 my-2"
                                      style={{ minHeight: "20px" }}
                                    >
                                      <div
                                        className="text-14"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            isExpanded && data.description
                                              ? data?.description
                                              : data?.description?.slice(0, 90),
                                        }}
                                      />
                                      {/* <span
                                          onClick={toggleReadMore}
                                          className={`fw-medium ${styles.readMore}`}
                                        >
                                          {isExpanded
                                            ? "Show Less"
                                            : "Read More"}
                                        </span> */}
                                    </div>

                                    {data?.technicalSkills?.length && (
                                      <div className={styles.PenigSkils}>
                                        <ul className="row align-items-start justify-content-start gap-0">
                                          <li className="col-4 pe-0">
                                            <div className="row align-items-start mb-3">
                                              <div className="col-3 text-center pe-0">
                                                <i>
                                                  <img
                                                    src={skill}
                                                    alt=""
                                                    className="img-fluid"
                                                    width="35px"
                                                    height="35px"
                                                  />
                                                </i>
                                              </div>
                                              <div className="col-9 ps-0">
                                                <h5 className="mb-0 fw-bold text-14 text-uppercase">
                                                  Top Skill
                                                </h5>
                                                <p className="text-14 mb-0">
                                                  {" "}
                                                  {
                                                    data?.technicalSkills?.[0]
                                                      ?.manufacturerDetails
                                                      ?.name
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </li>
                                          {data?.technicalSkills?.[1]
                                            ?.manufacturerDetails?.name ? (
                                            <li className="col-4 pe-0">
                                              <div className="row align-items-start mb-3">
                                                <div className="col-3 text-center pe-0">
                                                  <i>
                                                    <img
                                                      src={skill}
                                                      alt=""
                                                      className="img-fluid"
                                                      width="35px"
                                                      height="35px"
                                                    />
                                                  </i>
                                                </div>
                                                <div className="col-9 ps-0">
                                                  <h5 className="mb-0 fw-bold text-14 text-uppercase">
                                                    Second skill
                                                  </h5>
                                                  <p className="text-14 mb-0">
                                                    {
                                                      data?.technicalSkills?.[1]
                                                        ?.manufacturerDetails
                                                        ?.name
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </li>
                                          ) : null}
                                        </ul>
                                        {/* <ul>
                                            {data.technicalSkills?.map(
                                              (skill: any) => {
                                                return (
                                                  <>
                                                    <li>
                                                      <h5>Top Skills</h5>
                                                      <p>
                                                        {
                                                          skill
                                                            .manufacturerDetails
                                                            ?.name
                                                        }
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <h5>Second Skills</h5>
                                                      <p>
                                                        {
                                                          skill
                                                            .manufacturerModelDetails
                                                            ?.name
                                                        }
                                                      </p>
                                                    </li>
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul> */}
                                      </div>
                                    )}
                                    <div
                                      className={`pt-0 mb-4 ${styles.PenigSkilRate}`}
                                    >
                                      <h5 className="mb-2">Pay Rates</h5>
                                      <ul>
                                        <li>
                                          <span>Base</span>
                                          <label>
                                            ${data?.rates?.baseRate}
                                          </label>
                                        </li>
                                        <li>
                                          <span>OverTime</span>
                                          <label>
                                            ${data?.rates?.overTimeRate}
                                          </label>
                                        </li>
                                        <li>
                                          <span>Sun/Holidays</span>
                                          <label>
                                            ${data?.rates?.doubleTimeRate}
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      className={`row align-items-center justify-content-between mt-3 ${styles.chatAdmin}`}
                                      // onClick={handleOpenAdminChat}
                                    >
                                      {data?.boosts ? (
                                        <div className="col-12">
                                          <button className="rounded-3 text-14 p-2 bg-blue text-white w-100 text-center fw-semibold text-capitalize">
                                            Boosted On{" "}
                                            {moment(
                                              data.boosts?.startDate
                                            ).format("DD-MM-YYYY")}{" "}
                                            {" | "}
                                            Renewal{" "}
                                            {moment(
                                              data.boosts?.endDate
                                            ).format("DD-MM-YYYY")}
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="col-12">
                                          <button
                                            onClick={() =>
                                              _handleBoostJob(data._id)
                                            }
                                            className="rounded-3 text-14 p-2 bg-blue text-white w-100 text-center fw-semibold text-capitalize"
                                          >
                                            Boost this job
                                          </button>
                                        </div>
                                      )}

                                      {/* <a href="#">
                                          <img src={chatic} alt="" />
                                          Chat with Admin
                                        </a> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : ""}
                  </Slider>
                </div>
              </div>
            </div>
          )}
        </>
      ) : ''}
      {showChatModal && <ChatModule onClose={() => setShowChatmodal(false)} />}
      {modalDetail.show && (
        <CustomModal
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={true}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "boostContract" ? "boostContract" : ""
          }
          child={
            modalDetail.flag === "boostContract" ? (
              <BoostContract
                onCloseModal={() => handleOnCloseModal()}
                workId={workId}
              />
            ) : null
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      )}
    </div>
  );
};

export default PendingAdminJob;
