import React, { useEffect, useRef, useState } from 'react'
// import styles from "../../../common/timesheets/styles/styles/style.module.css"
import styles from "../../expense/styles/style.module.css"
import info from "../../../../assets/images/tip.png"
import share from "../../../../assets/images/share.svg"
import print from "../../../../assets/images/print.png"
import dnlod from "../../../../assets/images/dnlod.png"
import logo from "../../../../assets/images/logo.png"
import Header from '../../../header/routes'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { getInvoice, getInvoiceById, getexpenseById, invoiceClient } from '../../expense/api'
import { getMondayOfCurrentWeek, getWeekDaysMap } from '../../../../utils/daysMap'
import Toltip from '../../toltip/page'
import TimesheetDetails from '../../timesheets/routes/detailstimesheet'
import ExpenseDetails from '../../expense/expenseDetails'
import WhiteGloveForInvoice from '../../expense/WhiteGloveForInvoice/WhiteGloveForInvoice'
import { APP_ROUTES } from '../../../../utils/routes/AppRoutes'
import { addImagesToPdf } from '../../../../common/api'

function Invoice() {

    const { id } = useParams();
    const navigate = useNavigate();
    const [expenseDetails, setExpenseDetails] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [downlaoding, setDownloading] = useState<Boolean>(false);

    const contentRef = useRef(null)

    useEffect(() => {
        const debounceFetch = setTimeout(async () => {
            if (id) {
                setIsLoading(true)
                try {
                    const response: any = await invoiceClient(id);
                    setExpenseDetails(response?.data);
                    console.log(expenseDetails, "the invoice details")
                } catch (error) {
                    console.error("Error fetching expense:", error);
                } finally {
                    setIsLoading(false); // Stop loading once the API call finishes
                }
            }
        }, 500);

        return () => clearTimeout(debounceFetch);
    }, [id]);

    return (
        <>
            <Header />
            <div className={styles.pageOuter}>
                <div className='container'>

                    <div className={styles.rightContainer}>
                        <h2 className="fw-bold text-26 mb-4">Invoices</h2>

                        <div className={`${styles.baseInfo} ${styles.noMarg}`}>
                            <div className={styles.leftContract}>
                                <div className="d-flex align-items-center">
                                    <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                                        White Glove Hourly Contracts
                                    </h4>
                                    <i>
                                        <img className="customeTolImg" src={info} alt="" />
                                    </i>
                                    <Toltip
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {isLoading ? (
                                    <div className="d-flex justify-content-center align-items-center w-100">
                                        <div className="spinner-grow text-primary" role="status">
                                        </div>
                                    </div>
                                ) : (
                                    <WhiteGloveForInvoice expenseDetails={expenseDetails} />
                                )}
                            </div>




                            <div className={` ${styles.timeSheetdetilshead} mt-3`}>
                                <div className="col-4">
                                    <div className="d-flex align-items-center">
                                        <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                                            Invoice
                                        </h4>
                                        <i>
                                            <img className="customeTolImg" src={info} alt="" />
                                        </i>
                                        <Toltip />
                                    </div>
                                </div>
                                <div className="col-8 text-end">
                                    <div className="d-flex align-items-center justify-content-end g-2">
                                        <div className="px-1">
                                            {" "}
                                            <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-2 font-inter fw-semibold">
                                                <i className="me-2">
                                                    <img src={print} alt="" />
                                                </i>{" "}
                                                Print
                                            </button>
                                        </div>

                                        <div className="px-1">
                                            {" "}
                                            <button onClick={() => addImagesToPdf([contentRef], setDownloading, "invoice")} className="border text-14 d-flex align-items-center py-2 px-3 rounded-2 font-inter fw-semibold">
                                                <i className="me-2">
                                                    <img src={dnlod} alt="" />
                                                </i>{" "}
                                                {downlaoding ? "Downloading..." : "Download"}
                                            </button>
                                        </div>
                                        <div className="px-1">
                                            {" "}
                                            <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-2 font-inter fw-semibold">
                                                <i className="me-2">
                                                    <img
                                                        src={share}
                                                        alt=""
                                                        style={{ filter: "invert(1)" }}
                                                    />
                                                </i>{" "}
                                                Share Invoice
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div ref={contentRef} className={`rounded-3 ${styles.timeSheetdetils} ${styles.noMarg}`}>
                                <div className={`text-center ${styles.invoiceLogo}`}>
                                    <img src={logo} alt="" className="mx-auto" />
                                </div>
                                <div className={`table-responsive ${styles.tabletimesheet}`}>
                                    <table className="table align-middle">
                                        <tr>
                                            <td>
                                                <span className="fw-semibold">Invoice#</span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">{expenseDetails?.invoiceNumber}</span>
                                            </td>
                                            <td colSpan={4} className=" text-center"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="fw-semibold">Professional Name:</span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">{expenseDetails?.professional?.name}</span>
                                            </td>
                                            <td>
                                                <span className="fw-semibold">Date :</span>
                                            </td>
                                            <td className="text-center">
                                                <span className="fw-normal">
                                                    {new Date(expenseDetails?.createdAt).toLocaleDateString('en-US', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        year: 'numeric',
                                                    })}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="fw-semibold">Business Name:</span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">{expenseDetails?.customer?.name}</span>
                                            </td>
                                            <td colSpan={4} className=" text-center"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="fw-semibold">Street Adddress:</span>
                                            </td>
                                            <td>
                                                <span className="fw-normal">{expenseDetails?.work?.address}</span>
                                            </td>
                                            <td colSpan={4} className=" text-center"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="fw-semibold">City, State, Zip</span>
                                            </td>
                                            <td colSpan={4}>
                                                <span className="fw-normal">
                                                    {`${expenseDetails?.work?.city || ''}, ${expenseDetails?.work?.state || ''}`}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="fw-semibold">Contact Number :</span>
                                            </td>
                                            <td colSpan={4}>
                                                <span className="fw-normal">{expenseDetails?.professional?.phoneNumber}</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <span className="fw-semibold">Email:</span>
                                            </td>
                                            <td colSpan={4}>
                                                <span className="fw-normal">{expenseDetails?.professional?.email}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="fw-semibold">Customer Name</span>
                                            </td>
                                            <td colSpan={4}>
                                                <span className="fw-normal"></span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className={`row align-items-start mx-0 ${styles.bginfo}`}>
                                    <div className="col-4">
                                        <div className=" my-3">
                                            <h3 className="text-18 fw-bold">To : Automate America</h3>
                                            <h6 className="text-14 fw-semibold mb-2">Email : ap@automateamerica.com</h6>
                                            <p className="text-14 fw-medium mb-0">PO BOX 124544</p>
                                            <p className="text-14 fw-medium mb-0">Greer,SC 294783</p>
                                            <p className="text-14 fw-medium mb-0">+1 (864) 509-6622</p>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className={`table-responsive ${styles.tabletimesheet}`}>
                                            <table className="table align-middle">
                                                <tr>
                                                    <td colSpan={4}>
                                                        <span className="fw-semibold"> Supplier Deposit Informaiton</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="fw-semibold">Bank Name</span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-normal">XXXXXXXXXX</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="fw-semibold">Routing #</span>
                                                    </td>
                                                    <td className="">
                                                        <span className="fw-normal">XXXXXXXXXX</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="fw-semibold">Acc # :</span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-normal">XXXXXXXXXX</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="fw-semibold">Account Name # :</span>
                                                    </td>
                                                    <td>
                                                        <span className="fw-normal">XXXXXXXXXX</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className={`table-responsive ${styles.tablelesswidth} ${styles.tabletimesheet}`}>
                                    <table className="table align-middle mb-0">
                                        <thead>
                                            <tr>
                                                <th>Business</th>
                                                <th>Business <br />Contact</th>
                                                <th>Business <br />#</th>
                                                <th>PO <br />Number</th>
                                                <th>Payment <br />Term</th>
                                                <th>Expense <br />Terms</th>
                                                <th>Week <br />Start Date</th>
                                                <th>Week <br />End Date</th>
                                                <th>Due Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><span className="fw-normal">{expenseDetails?.customer?.name}</span></td>
                                                <td><span className="fw-normal">{expenseDetails?.customer?.phoneNumber}</span></td>
                                                <td><span className="fw-normal">{ }</span></td>
                                                <td><span className="fw-normal">98787</span></td>
                                                <td><span className="fw-normal">Net 60</span></td>
                                                <td><span className="fw-normal">Net 60</span> </td>
                                                <td>
                                                    <span className="fw-normal">
                                                        {expenseDetails?.timesheet?.startDate &&
                                                            new Date(expenseDetails.timesheet.startDate).toLocaleDateString('en-US', {
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                year: 'numeric',
                                                            })}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="fw-normal">
                                                        {expenseDetails?.timesheet?.endDate &&
                                                            new Date(expenseDetails.timesheet.endDate).toLocaleDateString('en-US', {
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                year: 'numeric',
                                                            })}
                                                    </span>
                                                </td>
                                                <td><span className="fw-normal">07-25-2024</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={`table-responsive ${styles.tablelesswidth} ${styles.tabletimesheet}`}>
                                    <table className="table align-middle mb-0">
                                        <thead>
                                            <tr>
                                                <th className="py-3" rowSpan={2}>Description</th>
                                                <th className="py-3">Hours</th>
                                                <th className="py-3">Bill Rate</th>
                                                <th className="py-3">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="py-3"><span className="fw-normal">Straight Time_Robort Programming (Monday - Saturday 0-40 hrs)</span></td>
                                                <td className="text-end py-3"><span className="fw-semibold">{expenseDetails?.timesheet?.totalStraightTime?.toFixed(2)}</span></td>
                                                <td className="text-end py-3"><span className="fw-normal">${typeof expenseDetails?.baseRate === 'number' ? expenseDetails.baseRate.toFixed(2) : 'Invalid Rate'}</span></td>
                                                <td className="text-end py-3">
                                                    <span className="fw-semibold">
                                                        ${((expenseDetails?.timesheet?.totalStraightTime || 0) * (expenseDetails?.baseRate || 0)).toFixed(2)}
                                                    </span>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-3"><span className="fw-normal">Overtime</span></td>
                                                <td className="text-end py-3"><span className="fw-semibold">{expenseDetails?.timesheet?.overTime?.toFixed(2)}</span></td>
                                                <td className="text-end py-3">
                                                    <span className="fw-normal">${expenseDetails?.overTimeRate?.toFixed(2)}</span>
                                                </td>
                                                <td className="text-end py-3">
                                                    <span className="fw-semibold">
                                                        ${((expenseDetails?.timesheet?.overTime || 0) * (expenseDetails?.overTimeRate || 0)).toFixed(2)}
                                                    </span>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-3"><span className="fw-normal">Double Time</span></td>
                                                <td className="text-end py-3"><span className="fw-semibold">{expenseDetails?.timesheet?.doubleTime?.toFixed(2)}</span></td>
                                                <td className="text-end py-3"><span className="fw-normal">${expenseDetails?.doubleTimeRate?.toFixed(2)}</span></td>
                                                <td className="text-end py-3">
                                                    <span className="fw-semibold">
                                                        ${((expenseDetails?.timesheet?.doubleTime || 0) * (expenseDetails?.doubleTimeRate || 0)).toFixed(2)}
                                                    </span>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-3"><span className="fw-normal">Travel Time</span></td>
                                                <td className="text-end py-3"><span className="fw-semibold">{expenseDetails?.timesheet?.totalTravelTime.toFixed(2)}</span></td>
                                                <td className="text-end py-3"><span className="fw-normal">${expenseDetails?.travelTimeRate?.toFixed(2)}</span></td>
                                                <td className="text-end py-3">
                                                    <span className="fw-semibold">
                                                        ${expenseDetails?.totalTravelTime?.toFixed(2)}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="offset-md-8 col-4">
                                    <div className={`table-responsive ${styles.tablelesswidth} ${styles.tabletimesheet}`}>
                                        <table className="table align-middle mb-0">
                                            <tr>
                                                <th className="py-2">Sub Total </th>
                                                <th className="text-end py-2">${expenseDetails?.totalTimesheetValue?.toFixed(2)}</th>
                                            </tr>
                                            {/* <tr>
                                                <th className="py-2" >Timesheet Late Fees </th>
                                                <th className="py-2 text-end">${expenseDetails?.jobApplyAgreement?.timesheetlateFees}</th>
                                            </tr> */}


                                            <tr>
                                                <th className="py-2">Total Invoice</th>
                                                <th className="py-2 text-end">
                                                    ${((expenseDetails?.totalTimesheetValue || 0) + (expenseDetails?.jobApplyAgreement?.timesheetlateFees || 0)).toFixed(2)}
                                                </th>

                                            </tr>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-2 col-md-4 col-12 my-3">
                                <div className={`bg-blue rounded-3 px-4 w-auto d-block text-center fw-semibold`}>
                                    <button onClick={() => navigate(`${APP_ROUTES.PAY}/123`)} className='cmbtn me-0'>Pay Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TimesheetDetails />
                {/* <ExpenseDetails /> */}

            </div>
        </>
    )
}

export default Invoice