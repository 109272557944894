import React from "react";
import styles from "../../../css/styles/style.module.css";
import { Button, Modal } from "react-bootstrap";
type ModalProp = {
  handleClose: () => void;
  showModal: boolean;
  action?: string;
  workStatus?: string;
  id?: string;
  status?: string;
  changeStatus: (reason?: string) => void;  
};
const Confirm = ({ showModal, handleClose, changeStatus }: ModalProp) => {
  return (
    <Modal backdrop="static" show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Are You Sure You Want To Perform This Action?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={() => changeStatus('')}>
            Confirm
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Confirm;
