import React from 'react';
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import congratscheck from '../../../assets/images/congra_check.png'
import { useNavigate } from 'react-router-dom';

 interface ThanksModalProps{
  showModal: boolean;
  closeModal: () => void;
 }
const ThanksComp: React.FC<ThanksModalProps> = ({
  showModal,closeModal
}) => {
  const navigate=useNavigate()
  const handleOkClick = () => {
navigate('/home')
  };

  return (

    <Modal show={showModal} onHide={closeModal} centered size="lg" backdrop='static'>
      {/* <Modal.Header closeButton>
        <Modal.Title>Congratulations</Modal.Title>
      </Modal.Header> */}
      <ModalBody>
      <div className="modal_catch d-flex flex-column justify-content-center text-center rounded-0 pt-4">
            <div className="text-center">
              <figure>
                <img src={congratscheck} alt="" className="img-fluid mx-auto my-4" />
              </figure>
            </div>
            <h1 className="text-24 fw-bold">Thanks!</h1>
            <p className="text-16 mb-0 ps-4 pe-4 pt-2">
            Your request has been successfully sent to johns. An email has been sent to him to complete the signup process in Automate america. Please wait patiently</p>
        </div>
      </ModalBody>
      
      <ModalFooter className="btn-hover-effect justify-content-center border-top-0 mb-3">
        
          <Button
            type="button"
            variant="secondary"
            onClick={handleOkClick}
            className="btn bg-blue arrowblue border border-1 rounded-3 position-relative py-2 w-auto mb-3"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-white">
            Ok
            </span>
          </Button>
        </ModalFooter>
    </Modal>
   
  );
};

export default ThanksComp;
