import React, { FC, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import infoicon from "../../../../assets/images/infoicon.svg";
import { Question } from "../../Contracts/interface";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";

interface Props {
  loading: boolean;
  questions: Question[];
  onAnsChange: (value: boolean, index: number, id: string, key?: "No") => void;
  onNext: () => void;
  showAlertText: boolean;
  showSaveBtn: boolean;
  showModal: boolean;
  onClose: () => void;
}

export const EligibilityQuestions: FC<Props> = ({
  questions,
  loading,
  showModal,
  onClose,
  onAnsChange,
  onNext,
  showAlertText,
  showSaveBtn,
}) => {
  return (
    <Modal show={true} onHide={onClose} centered size="lg" backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Eligibility Criteria</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <div className="modal_catch d-flex flex-column justify-content-start text-start rounded-0 pt-4">
          {loading ? (
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div className={styles.EligibalModal}>
              <div className="row">
                <div className="col-md-8">
                  <div className={styles.leftEligibal}>
                    <form>
                      {questions?.map((data: any, idx: number) => {
                        return (
                          <div key={idx} className={styles.formGroup} >
                            <h5 className="text-20 fw-semibold text-capitalize">
                              1.
                              {data?.question ? data?.question : ""}
                            </h5>
                            <div>
                              <label className="d-block">
                                <input
                                  type="radio"
                                  name={`answer-${idx}`}
                                  value="yes"
                                  onChange={(e) =>
                                    onAnsChange(true, idx, data?._id)
                                  }
                                />
                                <span className="ms-2">Yes</span>
                              </label>
                              <label className="d-block">
                                <input
                                  type="radio"
                                  name={`answer-${idx}`}
                                  value="no"
                                  onChange={(e) =>
                                    onAnsChange(false, idx, data?._id, "No")
                                  }
                                />
                                <span className="ms-2">No</span>
                              </label>
                              {showAlertText && (
                                <p className="unique-text">
                                  <em>
                                    (If you choose No, you will not be able to
                                    proceed ahead)
                                  </em>
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </form>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.rightEligibal}>
                    <img src={infoicon} alt="" />
                    <p>
                      These questions can help gauge your experience and skills
                      in various aspects of project management and technical
                      tasks. Be prepared to provide specific examples from your
                      past work to support your answers. These questions can
                      help gauge your experience and skills in various aspects
                      of project management and technical tasks. Be prepared to
                      provide specific examples from your past work to support
                      your answers. These questions can help gauge your
                      experience and skills in various aspects of project{" "}
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="eligibalBtn">
                  <button onClick={onNext} className="cmn-tbn">
                    Save & Next{" "}
                  </button>
                </div> */}
            </div>
          )}
        </div>
      </ModalBody>
      {showSaveBtn && (
        <ModalFooter className="btn-hover-effect justify-content-start border-top-0 mb-0">
          <Button
            type="button"
            variant="secondary"
            onClick={onNext}
            className="btn bg-blue arrowblue border border-1 rounded-3 position-relative py-2 w-auto mb-3"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-white">
              Save & Next{" "}
            </span>
          </Button>
        </ModalFooter>
      )}
    </Modal>
    // <div className="modal" style={{ display: "block" }}>
    //   <div className="modal-dialog ElibalModal">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title">Eligibility Criteria</h5>
    //         <button
    //           type="button"
    //           className="btn-close"
    //           data-bs-dismiss="modal"
    //           aria-label="Close"
    //           onClick={onClose}
    //         ></button>
    //       </div>
    //       <div className="modal-body">

    //       </div>

    //     </div>
    //   </div>
    // </div>
  );
};
