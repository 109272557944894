import React, { useEffect, useState } from "react";
import styles from "../../expense/styles/style.module.css";
import info from "../../../../assets/images/tip.png";
import share from "../../../../assets/images/share.svg";
import print from "../../../../assets/images/print.png";
import dnlod from "../../../../assets/images/dnlod.png";
import logo from "../../../../assets/images/logo.png";
import Header from "../../../header/routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { invoiceProfessional } from "../../expense/api";
import Toltip from "../../toltip/page";
import WhiteGloveForInvoice from "../../expense/WhiteGloveForInvoice/WhiteGloveForInvoice";

function InvoiceProfessional() {
  const { id, type } = useParams();
  console.log(id, "iddddddd")
  const navigate = useNavigate();
  const [expenseDetails, setExpenseDetails] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const debounceFetch = setTimeout(async () => {
      if (id) {
        setIsLoading(true);
        try {
          const response: any = await invoiceProfessional(id, type);
          setExpenseDetails(response?.data);
          console.log(response.data, "thedata of invoice professional")
        } catch (error) {
          console.error("Error fetching expense:", error);
        } finally {
          setIsLoading(false); // Stop loading once the API call finishes
        }
      }
    }, 500);

    return () => clearTimeout(debounceFetch);
  }, [id]);

  return (
    <>
      <Header />
      <div className={styles.pageOuter}>
        <div className="container">
          <div className={styles.rightContainer}>
            <h2 className="fw-bold text-26 mb-4">Invoices</h2>

            <div className={`${styles.baseInfo} ${styles.noMarg}`}>
              <div className={styles.leftContract}>
                <div className="d-flex align-items-center">
                  <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                    White Glove Hourly Contracts
                  </h4>
                  <i>
                    <img className="customeTolImg" src={info} alt="" />
                  </i>
                  <Toltip />
                </div>
              </div>
              <div className="row">
                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <div
                      className="spinner-grow text-primary"
                      role="status"
                    ></div>
                  </div>
                ) : (
                  <WhiteGloveForInvoice expenseDetails={expenseDetails} />
                )}
              </div>
              <div className={` ${styles.timeSheetdetilshead} mt-3`}>
                <div className="col-4">
                  <div className="d-flex align-items-center">
                    <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                      Invoice
                    </h4>
                    <i>
                      <img className="customeTolImg" src={info} alt="" />
                    </i>
                    <Toltip />
                  </div>
                </div>
                <div className="col-8 text-end">
                  <div className="d-flex align-items-center justify-content-end g-2">
                    <div className="px-1">
                      {" "}
                      <button onClick={() => window.print()} className="border text-14 d-flex align-items-center py-2 px-3 rounded-2 font-inter fw-semibold">
                        <i className="me-2">
                          <img src={print} alt="" />
                        </i>{" "}
                        Print
                      </button>
                    </div>

                    <div className="px-1">
                      {" "}
                      <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-2 font-inter fw-semibold">
                        <i className="me-2">
                          <img src={dnlod} alt="" />
                        </i>{" "}
                        Download Invoice
                      </button>
                    </div>
                    <div className="px-1">
                      {" "}
                      <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-2 font-inter fw-semibold">
                        <i className="me-2">
                          <img
                            src={share}
                            alt=""
                            style={{ filter: "invert(1)" }}
                          />
                        </i>{" "}
                        Share Invoice
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className={` ${styles.noMarg}`}>
                {/* <div className="py-3 text-center rounded-top-3" style={{background: "#38C407"}}>
                    <h4 className="text-24 fw-bold text-white mb-0">Paid Invoices</h4>
                </div> */}
                <div className={`rounded-3 rounded-top-0 ${styles.timeSheetdetils} ${styles.noMarg}`}>
                  <div className={`text-center ${styles.invoiceLogo}`}>
                    <img src={logo} alt="" className="mx-auto" />
                  </div>
                  <div className={`table-responsive ${styles.tabletimesheet}`}>
                    <table className="table align-middle">
                      <tr>
                        <td>
                          <span className="fw-semibold">Invoice#</span>
                        </td>
                        <td>
                          <span className="fw-normal">{expenseDetails?.invoiceNumber}</span>
                        </td>
                        <td colSpan={4} className=" text-center"></td>
                      </tr>
                      <tr>
                        <td>
                          <span className="fw-semibold">Professional Name:</span>
                        </td>
                        <td>
                          <span className="fw-normal">{expenseDetails?.professional?.name}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">Date :</span>
                        </td>
                        <td className="text-center">
                          <span className="fw-normal">
                            {new Date(expenseDetails?.createdAt).toLocaleDateString('en-US', {
                              month: '2-digit',
                              day: '2-digit',
                              year: 'numeric',
                            })}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="fw-semibold">Business Name:</span>
                        </td>
                        <td>
                          <span className="fw-normal">{expenseDetails?.customer?.name}</span>
                        </td>
                        <td colSpan={4} className=" text-center"></td>
                      </tr>
                      <tr>
                        <td>
                          <span className="fw-semibold">Street Adddress:</span>
                        </td>
                        <td>
                          <span className="fw-normal">{expenseDetails?.work?.address}</span>
                        </td>
                        <td colSpan={4} className=" text-center"></td>
                      </tr>
                      <tr>
                        <td>
                          <span className="fw-semibold">City, State, Zip</span>
                        </td>
                        <td colSpan={4}>
                          <span className="fw-normal">
                            {`${expenseDetails?.work?.city || ''}, ${expenseDetails?.work?.state || ''}`}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="fw-semibold">Contact Number :</span>
                        </td>
                        <td colSpan={4}>
                          <span className="fw-normal">{expenseDetails?.professional?.phoneNumber}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="fw-semibold">Email:</span>
                        </td>
                        <td colSpan={4}>
                          <span className="fw-normal">{expenseDetails?.professional?.email}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className={`row align-items-start mx-0 ${styles.bginfo}`}>
                    <div className="col-4">
                      <div className=" my-3">
                        <h3 className="text-18 fw-bold">From : Automate America</h3>
                        <h6 className="text-14 fw-semibold mb-2">Email : ap@automateamerica.com</h6>
                        <p className="text-14 fw-medium mb-0">PO BOX 124544</p>
                        <p className="text-14 fw-medium mb-0">Greer,SC 294783</p>
                        <p className="text-14 fw-medium mb-0">+1 (864) 509-6622</p>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className={`table-responsive ${styles.tabletimesheet}`}>
                        <table className="table align-middle">
                          <tr>
                            <td colSpan={4}>
                              <span className="fw-semibold"> Supplier Deposit Informaiton</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-semibold">Bank Name</span>
                            </td>
                            <td>
                              <span className="fw-normal">XXXXXXXXXX</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-semibold">Routing #</span>
                            </td>
                            <td className="">
                              <span className="fw-normal">XXXXXXXXXX</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-semibold">Acc # :</span>
                            </td>
                            <td>
                              <span className="fw-normal">XXXXXXXXXX</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="fw-semibold">Account Name # :</span>
                            </td>
                            <td>
                              <span className="fw-normal">XXXXXXXXXX</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className={`table-responsive ${styles.tablelesswidth} ${styles.tabletimesheet}`}>
                    <table className="table align-middle mb-0">
                      <thead>
                        <tr>
                          <th>Business</th>
                          <th>Business <br />Contact</th>
                          <th>Business <br />#</th>
                          <th>PO <br />Number</th>
                          <th>Payment <br />Term</th>
                          <th>Expense <br />Terms</th>
                          <th>Week <br />Start Date</th>
                          <th>Week <br />End Date</th>
                          <th>Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><span className="fw-normal">{expenseDetails?.customer?.name}</span></td>
                          <td><span className="fw-normal">{expenseDetails?.customer?.phoneNumber}</span></td>
                          <td><span className="fw-normal">{ }</span></td>
                          <td><span className="fw-normal"></span></td>
                          <td><span className="fw-normal">Net {expenseDetails?.hourlyPaymentTerm?.[0].paymentTerm}</span></td>
                          <td><span className="fw-normal">Net {expenseDetails?.expensePaymentTerm?.paymentTerm}</span> </td>
                          <td>
                            <span className="fw-normal">
                              {expenseDetails?.startDate &&
                                new Date(expenseDetails?.startDate).toLocaleDateString('en-US', {
                                  month: '2-digit',
                                  day: '2-digit',
                                  year: 'numeric',
                                })}
                            </span>
                          </td>
                          <td>
                            <span className="fw-normal">
                              {expenseDetails?.endDate &&
                                new Date(expenseDetails?.endDate).toLocaleDateString('en-US', {
                                  month: '2-digit',
                                  day: '2-digit',
                                  year: 'numeric',
                                })}
                            </span>
                          </td>

                          <td> <span className="fw-normal">
                            {expenseDetails?.dueDate &&
                              new Date(expenseDetails?.dueDate).toLocaleDateString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                              })}
                          </span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={`table-responsive ${styles.tablelesswidth} ${styles.tabletimesheet}`}>
                    {expenseDetails?.type === "timesheet" ? (
                      <table className="table align-middle mb-0">
                        <thead>
                          <tr>
                            <th className="py-3" rowSpan={2}>Description</th>
                            <th className="py-3">Hours</th>
                            <th className="py-3">Bill Rate</th>
                            <th className="py-3">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="py-3">
                              <span className="fw-normal">Straight Time_Robort Programming (Monday - Saturday 0-40 hrs)</span>
                            </td>
                            <td className="text-end py-3">
                              <span className="fw-semibold">{expenseDetails?.timesheet?.totalStraightTime?.toFixed(2)}</span>
                            </td>
                            <td className="text-end py-3">
                              <span className="fw-normal">${expenseDetails?.baseRate?.toFixed(2)}</span>
                            </td>
                            <td className="text-end py-3">
                              <span className="fw-semibold">
                                ${((expenseDetails?.timesheet?.totalStraightTime || 0) * (expenseDetails?.baseRate || 0)).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="py-3"><span className="fw-normal">Overtime</span></td>
                            <td className="text-end py-3">
                              <span className="fw-semibold">{expenseDetails?.timesheet?.overTime?.toFixed(2)}</span>
                            </td>
                            <td className="text-end py-3">
                              <span className="fw-normal">${expenseDetails?.overTimeRate?.toFixed(2)}</span>
                            </td>
                            <td className="text-end py-3">
                              <span className="fw-semibold">
                                ${((expenseDetails?.timesheet?.overTime || 0) * (expenseDetails?.overTimeRate || 0)).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="py-3"><span className="fw-normal">Double Time</span></td>
                            <td className="text-end py-3">
                              <span className="fw-semibold">{expenseDetails?.timesheet?.doubleTime?.toFixed(2)}</span>
                            </td>
                            <td className="text-end py-3">
                              <span className="fw-normal">${expenseDetails?.doubleTimeRate?.toFixed(2)}</span>
                            </td>
                            <td className="text-end py-3">
                              <span className="fw-semibold">
                                ${((expenseDetails?.timesheet?.doubleTime || 0) * (expenseDetails?.doubleTimeRate || 0)).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="py-3"><span className="fw-normal">Travel Time</span></td>
                            <td className="text-end py-3">
                              <span className="fw-semibold">{expenseDetails?.timesheet?.totalTravelTime?.toFixed(2)}</span>
                            </td>
                            <td className="text-end py-3">
                              <span className="fw-normal">${expenseDetails?.travelTimeRate?.toFixed(2)}</span>
                            </td>
                            <td className="text-end py-3">
                              <span className="fw-semibold">${expenseDetails?.totalTravelTime?.toFixed(2)}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <table className="table align-middle mb-0">
                      <thead>
                        <tr>
                          <th className="py-3">Date</th>
                          <th className="py-3">Daily Per Diem</th>
                          <th className="py-3">Travel Day Per Diem</th>
                          <th className="py-3">Hotel Allowance</th>
                          <th className="py-3">Mileage</th>
                          <th className="py-3">Rental Car Allowance</th>
                          <th className="py-3">Fuel</th>
                          <th className="py-3">Airfare Allowance</th>
                          <th className="py-3">Luggage</th>
                          <th className="py-3">Daily Total</th>
                          <th className="py-3">Covered Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {expenseDetails?.expense?.expenseDetails.length > 0 ? (
                         expenseDetails?.expense?.expenseDetails.map((detail:any) => (
                            <tr key={detail._id}>
                              <td className="py-3">{new Date(detail.workedDate).toLocaleDateString()}</td>
                              <td className="text-end py-3">{detail.dailyPerDiem}</td>
                              <td className="text-end py-3">{detail.travelDayPerDiem}</td>
                              <td className="text-end py-3">{detail.hotelAllowance}</td>
                              <td className="text-end py-3">{detail.mileage}</td>
                              <td className="text-end py-3">{detail.rentalCarAllowance}</td>
                              <td className="text-end py-3">{detail.fuel}</td>
                              <td className="text-end py-3">{detail.airFareAllowance}</td>
                              <td className="text-end py-3">{detail.luggage}</td>
                              <td className="text-end py-3 fw-semibold">${detail.dailyTotal.toFixed(2)}</td>
                              <td className="text-end py-3 fw-semibold">${detail.dailyCoveredTotal.toFixed(2)}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="py-3 text-center" colSpan={11}>
                              <span className="fw-normal">No expense details available</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    
                    )}

                  </div>
                  <div className="offset-md-8 col-4">
                    <div className={`table-responsive ${styles.tablelesswidth} ${styles.tabletimesheet}`}>
                      <table className="table align-middle mb-0">
                        <tr>
                          <th className="py-2">Sub Total</th>
                          <th className="text-end py-2">${Number(expenseDetails?.totalTimesheetValue || 0).toFixed(2)}</th>
                        </tr>
                        <tr>
                          <th className="py-2">Timesheet Late Fees</th>
                          <th className="py-2 text-end">${Number(expenseDetails?.jobApplyAgreement?.timesheetlateFees || 0).toFixed(2)}</th>
                        </tr>
                        <tr>
                          <th className="py-2">Liability Insurance Fees</th>
                          <th className="py-2 text-end">${Number(expenseDetails?.liabilityInsuranceAmount || 0).toFixed(2)}</th>
                        </tr>
                        <tr>
                          <th className="py-2">Total Expense</th>
                          <th className="py-2 text-end">${Number(expenseDetails?.totalExpense || 0).toFixed(2)}</th>
                        </tr>
                        <tr>
                          <th className="py-2">Total Invoice</th>
                          <th className="py-2 text-end">
                            ${(
                              (Number(expenseDetails?.totalTimesheetValue) || 0) +
                              (Number(expenseDetails?.jobApplyAgreement?.timesheetlateFees) || 0) +
                              (Number(expenseDetails?.liabilityInsuranceAmount) || 0) +
                              (Number(expenseDetails?.totalExpense) || 0)
                            ).toFixed(2)}
                          </th>
                        </tr>
                      </table>

                    </div>
                  </div>
                  <div className=''>
                    <div className={styles.addComment}>
                      <h3 className='text-20'><b>Approved By : </b> </h3>
                      <p className='mb-1'><b>Name:</b> {expenseDetails?.customer?.name}</p>
                      <p className='mb-1'><b>Email:</b> {expenseDetails?.customer?.email}</p>
                      <p className='mb-1'><b>Phone Number:</b>{expenseDetails?.customer?.phoneNumber}</p>
                      {/* <p className='mb-1'><b>IP Address:</b> 73.65.58.2</p> */}
                      {/* <p className='mb-1'><b>Approved Date:</b> 07-15-2004</p> */}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* <TimesheetDetails/>
               <ExpenseDetails/> */}
      </div>
    </>
  );
}

export default InvoiceProfessional;
